import { Checkbox, HStack, VStack } from "@chakra-ui/react"
import { Badge } from "flowbite-react"

interface SelectedItemProps {
    end?: boolean,
    item: any,
    selected?: any,
    onClick?: any
}

const SelectedItem = ({ end, item, selected = [], onClick }: SelectedItemProps) => {
    const isChecked = selected.findIndex((i: any) => i.id == item.id)

    return (
        <VStack className="hover:bg-slate-50 transition-all" px={3} onClick={() => onClick(item)} cursor={"pointer"} borderBottom={end ? 'unset' : '1px'} borderColor={"gray.300"} alignItems={"flex-start"}>
            <HStack gap={3} py={2} justifyContent={"space-between"} w={'full'}>
                <div className="flex items-center gap-3">
                    <Checkbox onChange={() => onClick(item)} className="outline-none " colorScheme="teal" isChecked={isChecked != -1} size={"md"} />
                    <VStack spacing={0} alignItems={"flex-start"}>
                        <p className="select-none text-gray-600 text-sm">{item.full_name}</p>
                        <p className="text-gray-500 select-none text-xs">{item.__id}</p>
                    </VStack>
                </div>
                <Badge color={"purple"} className="select-none">{item.__type}</Badge>
            </HStack>
        </VStack>
    )
}

export default SelectedItem;