import { SimpleGrid } from "@chakra-ui/layout";
import { useFormik } from "formik";
import * as Yup from "yup";
import { InnerLoading, PageHeader } from "../..";
import { FormDropdown, FormDropdownOptions } from "../../common/form-controllers/FormDropdown";
import { FormInput } from "../../common/form-controllers/FormInput";
import FormSelect from "../../common/form-controllers/FormSelect";
import { PageHeaderActions } from "../../common/page-header/PageHeader";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { useToast } from "@chakra-ui/react";
import _ from "lodash";
import { NavigateFunction, useNavigate } from "react-router-dom";
import { SCHOOL, STUDENT, VALIDATION } from "../../../consts/methods.consts";
import { FILTER_TAGS, STUDENT_TAGS } from "../../../consts/tags.consts";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import { useEffect, useState } from "react";
import { PHONE_REG_EXP } from "../../../consts/app.consts";

const gender: FormDropdownOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" }
]

const yesNo: FormDropdownOptions = [
    { label: "Yes", value: 1 },
    { label: "No", value: 0 }
]

const status: FormDropdownOptions = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
    { label: "Future", value: "Future" },
    { label: "Left", value: "Left" },
    {label: "Leaving", value: "Leaving"}
]

const titles: FormDropdownOptions = [
    {label: "Mr", value: "Mr"},
    {label: "Ms", value: "Ms"},
    {label: "Miss", value: "Miss"},
    {label: "Mrs", value: "Mrs"},
    {label: "Mx", value: "Mx"}
]

const NewStudentPage = () => {
    const navigate: NavigateFunction = useNavigate();
    const [create, { isLoading }] = usePostMutation();
    const toast = new ToastService(useToast());
    const [studentIdQ, setStudentIdQ] = useState(null)

    const { data: _validationData } = useGetQuery({
        method: VALIDATION.DUPLICATE_STUDENT_ID_CREATE,
        body: {
            student_id: studentIdQ
        },
        providesTags: [STUDENT_TAGS.VIEW]
    })

    const formik = useFormik({
        initialValues: {
            class: '',
            status: "Active"
        },
        validationSchema: Yup.object({
            student_id: Yup.string().required("Student ID is required"),
            title: Yup.string().required("Title is required"),
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            class: Yup.string().required("Class is required"),
            gender: Yup.string().required("Gender is required"),
            email: Yup.string().email("Invalid email address").nullable(),
            birthday: Yup.string().required("Birth day is required"),
            mobile: Yup.string().matches(PHONE_REG_EXP, 'Mobile number is not valid').nullable(),
            phone: Yup.string().matches(PHONE_REG_EXP, 'Phone number is not valid').nullable()
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: STUDENT.ADD,
                    body: values,
                    invalidatesTags: [STUDENT_TAGS.LIST, FILTER_TAGS.STUDENT_SELECTOR]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Unable to register student")
                }

                toast.setTitle("Success").setDescription("Student registered to the system").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/students/${newId}`, { replace: true });
                }, 750)

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to register student. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    useEffect(() => {
        studentIdQHandler(_.get(formik.values, "student_id"))
    }, [_.get(formik.values, "student_id")])

    const studentIdQHandler = _.debounce((value: any) => {
        setStudentIdQ(value)
    }, 200)


    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            isDisabled: isLoading,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isLoading
        },
    ]
    const studentIDCustomError = (_validationData?.message > 0) && (
        <p className=" text-sm text-red-500">Student ID already taken.</p>
    )

    return (
        <>
            <PageHeader isLoading={isLoading} enableBackButton actions={actions} title="Create New Student"
                subtitle="Student" />

            {
                isLoading ? <InnerLoading /> :
                    <>
                        <div className="border rounded p-3">
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={3}>
                                <FormInput customMessage={studentIDCustomError} isRequired label="Student Id"
                                    formik={formik} name="student_id" />
                                <FormDropdown options={titles} isRequired label="Title" formik={formik} name="title"/>
                                <FormInput isRequired label="First Name" formik={formik} name="first_name" />
                                <FormInput label="Middle Name" formik={formik} name="middle_name" />
                                <FormInput isRequired label="Last Name" formik={formik} name="last_name" />
                                {/* <FormSelect method={SCHOOL.GET_CLASS_LIST_FOR_DROPDOWN} isRequired label="Class" formik={formik} name="class" /> */}
                                <FormDropdown options={gender} isRequired label="Gender" formik={formik} name="gender" />
                                <FormInput isRequired type="date" label="Birth Day" formik={formik} name="birthday" />
                                <FormInput type="email" label="Email" formik={formik} name="email" />
                                <FormInput placeholder="0XXXXXXXXX" type="tel" label="Mobile" formik={formik} name="mobile" />
                                <FormInput placeholder="0XXXXXXXXX" type="tel" label="Phone" formik={formik} name="phone" />
                                <FormTextArea label="Residential Address" formik={formik} name="address" />
                                <FormDropdown options={status} label="Status" formik={formik} name="status" />
                            </SimpleGrid>
                        </div>
                        <div className="animation-form border rounded p-3 mt-3">
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 3 }} spacing={3}>
                                <FormSelect method={SCHOOL.GET_CLASS_LIST_FOR_DROPDOWN} isRequired label="Class"
                                    formik={formik}
                                    name="class" />
                            </SimpleGrid>
                        </div>
                        <div className="animation-form border rounded p-3 mt-3">
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 3 }} spacing={3}>
                                <FormTextArea label="Activity Restrictions" formik={formik}
                                    name="activity_restrictions" />
                                <FormTextArea label="Medical Condition" formik={formik} name="medical_condition" />
                                <FormTextArea label="Legal Restrictions" formik={formik} name="legal_restrictions" />
                                <FormDropdown options={yesNo} isRequired label="Disabled Student" formik={formik}
                                    name="disabled_student" />
                            </SimpleGrid>
                        </div>
                    </>
            }
        </>
    )
}

export default NewStudentPage