import { Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import PageHeader from "../../common/page-header/PageHeader";
import AllList from "./components/AllList";
import StudentsList from "./components/StudentsList";
import StaffList from "./components/StaffList";
import VisitorsList from "./components/VisitorsList";
import StudentsEarlyDepartureList from "./components/StudentEarlyDepartures";

const EmergencyPage = () => {

    return (
        <>
            <PageHeader subtitle="Report to manage emergency situations at the school" title={`School Emergency Report`} />
            <Tabs className="mt-3">
                <TabList>
                    <Tab>All</Tab>
                    <Tab>Student Late Arrivals</Tab>
                    <Tab>Student Early Departures</Tab>
                    <Tab>Staff</Tab>
                    <Tab>Visitors</Tab>
                </TabList>

                <TabPanels className="animation-form">
                    <TabPanel className="!p-0 !pt-3">
                        <AllList id="1" />
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <StudentsList id="1" />
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <StudentsEarlyDepartureList id="1" />
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <StaffList id="1" />
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <VisitorsList id="1" />
                    </TabPanel>
                </TabPanels>

            </Tabs>

        </>
    )
}

export default EmergencyPage