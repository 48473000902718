import ChakraTable, {ChakraTableColumns} from "../../../common/chakra-table/ChakraTable";
import _ from "lodash";
import {useSearchParams} from "react-router-dom";
import {useGetQuery} from "../../../../services/api.service";
import {GUEST} from "../../../../consts/methods.consts";
import {GUEST_TAGS} from "../../../../consts/tags.consts";
import {useTableDataProvider} from "../../../hooks";
import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router";
import {useToast} from "@chakra-ui/react";
import {ToastService} from "../../../../services/toast.service";
import {IOpenPdf, openPdf} from "../../../../utils/utils";

const TourListDataTable = () => {
    const toast = new ToastService(useToast());
    const navigate: NavigateFunction = useNavigate()
    const [searchParams, setSearchParams] = useSearchParams()
    const [filters, setFilters] = useState({})
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)


    const {data, isLoading, isFetching} = useGetQuery({
        method: GUEST.TOUR_LIST,
        body: {
            page,
            length: Number(length),
            ...filters
        },
        providesTags: [GUEST_TAGS.LIST]
    })

    const {dataList, pageCount, totalResult} = useTableDataProvider(data);
    const tableLineClickHandler = (line: any) => {
        navigate(`${line._id}`)
    }

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setSearchParams({...searchParams, ...value})
        setFilters({...searchParams, ...value})
    }, 200)

    const onPrint = async (columns: any, data: any) => {
        const config: IOpenPdf = {
            tableData: {
                columns,
                data,
                filters,
                info: {
                    title: "Tour List"
                }
            },
            toast
        }

        openPdf(config);
    }

    return (
        <>
            <ChakraTable onPrint={onPrint} onSearch={onSearchHandler} pageCount={pageCount} isLoading={isLoading}
                         isFetching={isFetching} pagination size={"sm"} lengthPaginations totalResult={totalResult}
                         header title="School Tours" onSelected={tableLineClickHandler} columns={TOUR_COLUMNS}
                         onPage={setPage} onLength={setLength} data={dataList}/>
        </>
    )
}

export const TOUR_COLUMNS: ChakraTableColumns = [
    {
        header: "Name",
        accessor: "full_name"
    },
    {
        header: "Email",
        accessor: "email",
    },
    {
        header: "Mobile",
        accessor: "mobile",
    },
    {
        header: "Gender",
        accessor: "gender",
    },
    {
        header: "Signed Date",
        accessor: "signed_date",
    }
]
export default TourListDataTable