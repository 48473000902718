import { Card, useToast } from "@chakra-ui/react";
import { ChakraModal } from "../..";
import { usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { FormInput } from "../form-controllers/FormInput";
import {
    Table,
    Thead,
    Tbody,
    Tfoot,
    Tr,
    Th,
    Td,
    TableCaption,
    TableContainer,
} from '@chakra-ui/react'

interface IViewCalendarEventModel {
    title: string
    data: any
    disclosure: any
}

const ViewCalendarEventModel = ({ disclosure, title, data }: IViewCalendarEventModel) => {

    data = data?.json

    return (
        <ChakraModal
            hideCancelButton={true}
            hidePrimaryButton={true}
            modalTitle={title}
            {...disclosure}
        >
            <Card variant={'outline'}>
                <Table size='sm'>
                    <Thead>
                        <Tr>
                            <Th>Title</Th>
                            <Th isNumeric>Value</Th>
                        </Tr>
                    </Thead>
                    <Tbody>
                        <Tr>
                            <Td>Title</Td>
                            <Td isNumeric>{data?.title}</Td>
                        </Tr>
                        <Tr>
                            <Td>Description</Td>
                            <Td isNumeric>{data?.description}</Td>
                        </Tr>
                        <Tr>
                            <Td>Start Date</Td>
                            <Td isNumeric>{data?.start_time}</Td>
                        </Tr>
                        <Tr>
                            <Td>End Date</Td>
                            <Td isNumeric>{data?.end_time}</Td>
                        </Tr>
                    </Tbody>
                </Table>
            </Card>
        </ChakraModal>

    )
}

export default ViewCalendarEventModel