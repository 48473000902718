import { Badge, useToast } from "@chakra-ui/react";
import { chakraComponents } from "chakra-react-select";
import { useFormik } from "formik";
import _ from "lodash";
import moment from "moment";
import { useState } from "react";
import * as Yup from "yup";
import { ChakraModal } from "../..";
import { ACTIVITY, RESOURCE } from "../../../consts/methods.consts";
import { ACTIVITY_TAGS, RESOURCE_TAGS } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { FormInput } from "../form-controllers/FormInput";
import FormLiveSelect from "../form-controllers/FormLiveSelect";

interface IAllocateResourceModal {
    id: string | undefined
    title: string
    type: "student" | "staff"
    disclosure: any
}

const profileMap: any = {
    "student": "Student",
    "staff": "Staff"
}

const CustomOption = ({ children, ...props }: any) => {
    let resource_id = props?.data?.resource_id
    let type = props?.data?.type
    let department = props?.data?.department

    return (
        <chakraComponents.Option {...props}>
            <div className="flex flex-col gap-1">
                <div>{props?.label}</div>
                <div className="flex flex-row gap-1">
                    {resource_id ? <Badge colorScheme={'yellow'}>{resource_id}</Badge> : null}
                    {type ? <Badge colorScheme={'purple'}>{type}</Badge> : null}
                    {department ? <Badge colorScheme={'orange'}>{department}</Badge> : null}
                </div>
            </div>
        </chakraComponents.Option>
    )
}

const resourceSearchComponents = {
    Option: CustomOption
}

const AllocateResourceModal = ({ id, type, disclosure, title }: IAllocateResourceModal) => {
    const [query, setQuery] = useState("")
    const toast = new ToastService(useToast())
    const [create] = usePostMutation();

    const { data, isFetching } = useGetQuery({
        method: RESOURCE.SEARCH,
        body: { q: query },
        providesTags: [ACTIVITY_TAGS.LIST]
    })

    const formik = useFormik({
        initialValues: {
        },
        validationSchema: Yup.object({
            resource: Yup.object().shape({
                value: Yup.string().required("Resource is required")
            }),
            due_date: Yup.string().required("Handover date is required")
        }),
        onSubmit: async (values: any) => {
            try {
                await create({
                    method: ACTIVITY.ADD,
                    body: {
                        title: "Allocate Resource",
                        type: "RESOURCE",
                        start_time: moment().format("YYYY-MM-DD H:mm"),
                        participants: [
                            {
                                profile_type: profileMap[type],
                                profile: id
                            },
                        ],
                        resources: [
                            {
                                resource: values?.resource?.value,
                                due_date: values?.due_date,
                                sign_out: 1,
                                sign_out_time: moment().format("YYYY-MM-DD H:mm")
                            }
                        ]
                    },
                    invalidatesTags: [ACTIVITY_TAGS.LIST, RESOURCE_TAGS.LIST]
                }).unwrap();
                formik.resetForm()
                toast.setTitle("Success").setDescription("Resource Allocated").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable allocate resource").showErrorToast();
                console.error(error);
            } finally {
                disclosure.onClose();
            }
        }
    })

    const resourceSearchHandler = _.debounce((text: string) => {
        setQuery(text)
    }, 250)

    return (
        <ChakraModal
            primaryAction={formik.submitForm}
            primaryButtonText={"Allocate"}
            modalTitle={title}
            {...disclosure}
        >
            <FormLiveSelect label="Resource" formik={formik} name={"resource"} options={data?.message?.map((line: any) => ({ label: line?.title, value: line?.name, resource_id: line?.resource_id, type: line?.type, department: line?.department }))} isLoading={isFetching} onInputChange={resourceSearchHandler} components={resourceSearchComponents} isRequired />
            <FormInput type={"date"} label="Handover date" name="due_date" formik={formik} isRequired />
        </ChakraModal>

    )
}

export default AllocateResourceModal