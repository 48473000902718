import { SimpleGrid } from "@chakra-ui/layout";
import { Select, useDisclosure, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { ChakraTable, InnerLoading, SectionCard } from "../../..";
import { GUARDIAN, VALIDATION } from "../../../../consts/methods.consts";
import { FILTER_TAGS, GUARDIAN_TAGS } from "../../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../../services/api.service";
import { ToastService } from "../../../../services/toast.service";
import { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable";
import { FormDropdown, FormDropdownOptions } from "../../../common/form-controllers/FormDropdown";
import { FormInput } from "../../../common/form-controllers/FormInput";
import PageHeader, { PageHeaderActions } from "../../../common/page-header/PageHeader";
import { SectionCardCreatable } from "../../../common/section-card/SectionCard";
import StudentSelector from "../../../common/student-selector/StudentSelector";
import DisclaimerList from "../components/DisclaimerList";
import { FormCheckBox } from "../../../common/form-controllers/FormCheckBox";
import { PHONE_REG_EXP } from "../../../../consts/app.consts";

const gender: FormDropdownOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" }
]

export const relationship: FormDropdownOptions = [
    { label: "Mother", value: "Mother" },
    { label: "Father", value: "Father" },
    { label: "Guardian", value: "Guardian" },
]

const titles: FormDropdownOptions = [
    { label: "Mr", value: "Mr" },
    { label: "Mis", value: "Mis" },
    { label: "Miss", value: "Miss" },
    { label: "Mrs", value: "Mrs" },
    { label: "Mx", value: "Mx" }
]

const NewGuardianPage = () => {
    const navigate: NavigateFunction = useNavigate();
    const [create, { isLoading }] = usePostMutation();
    const toast = new ToastService(useToast());
    const childrenDisclosure = useDisclosure()
    const [guardianEmailQ, setGuardianEmailQ] = useState(null)
    const [children, setChildren] = useState<any>([])
    const [isError, SetIsError] = useState<boolean>(false)

    const { data: _validationData } = useGetQuery({
        method: VALIDATION.DUPLICATE_GUARDIAN_EMAIL_CREATE,
        body: {
            email: guardianEmailQ,
        },
        providesTags: [GUARDIAN_TAGS.VIEW]
    })

    const formik = useFormik({
        initialValues: {
            active: true
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            gender: Yup.string().required("Gender is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            mobile: Yup.string().matches(PHONE_REG_EXP, 'Mobile number is not valid')
                .required('Mobile number is required')
        }),
        onSubmit: async (values: any) => {
            try {
                SetIsError(false);

                if (_.isEmpty(children)) {
                    SetIsError(true);
                    throw new Error("Please add a child");
                }

                const res = await create({
                    method: GUARDIAN.ADD,
                    body: {
                        ...values,
                        students: children.map((child: any) => {
                            return { student: child.id, relationship: child?.relationship || 'Guardian' }
                        })
                    },
                    invalidatesTags: [GUARDIAN_TAGS.LIST, FILTER_TAGS.GUARDIAN_SELECTOR]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Unable to register guardian")
                }

                toast.setTitle("Success").setDescription("Guardian registered to the system").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/guardians/${newId}`, { replace: true });
                }, 750)

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to register guardian. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isLoading
        },
    ]

    const creatable: SectionCardCreatable = {
        text: "Browse",
        onClick: () => {
            childrenDisclosure.onOpen();
        }
    }

    const STUDENT_LIST_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "Student Id",
            accessor: "student_id",
        },
        {
            header: "First Name",
            accessor: "first_name",
        },
        {
            header: "Last Name",
            accessor: "last_name",
        },
        {
            header: "Gender",
            accessor: "gender",
        },
        {
            header: "Class",
            accessor: "class",
        },
        {
            header: 'Relationship',
            accessor: '',
            customRenderer(value, item: any) {
                const onChangeHandler = (event: any) => {
                    const index = children.findIndex((i: any) => i.id == item.id)

                    setChildren((prev: any) => {
                        let arr = _.cloneDeep(prev);
                        arr[index]['relationship'] = event.target.value

                        return arr;
                    });
                }

                return (
                    <Select value={item?.relationship || 'Guardian'} onChange={onChangeHandler}
                        placeholder="Select relationship" shadow={"sm"} rounded={"md"} w={32} size={"sm"}
                        borderColor={'gray.300'} className={`!pb-[9px] !pt-[5px]`}>
                        {relationship.map((el: any, key: any) => <option key={key}
                            value={el.value}>{el.label}</option>)}
                    </Select>
                )
            },
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            options: [
                {
                    label: "Remove",
                    onClick(item: any) {
                        const filteredData = children.filter((i: any) => i.id != item.id);
                        setChildren(filteredData);
                    },
                }
            ]
        }
    ]

    const onChildrenTableClickHandler = (row: any) => {
        window.open(`${window.location.origin}/app/students/${row.id}`)
    }

    useEffect(() => {
        if (!_.isEmpty(children)) {
            SetIsError(false)
        }
    }, [JSON.stringify(children)])

    useEffect(() => {
        guardianEmailQHandler(_.get(formik.values, "email"))
    }, [_.get(formik.values, "email")])

    const guardianEmailQHandler = _.debounce((value: any) => {
        setGuardianEmailQ(value)
    }, 200)

    const guardianEmailCustomError = (_validationData?.message > 0) && (
        <p className="mt-1 text-sm text-red-500">Email already taken.</p>
    )

    return (
        <>
            <PageHeader isLoading={isLoading} enableBackButton actions={actions} title="Create New Guardian"
                subtitle="Guardian" />
            {
                isLoading ? <InnerLoading /> :
                    <>
                        <StudentSelector selectedItems={children}
                            setSelectedItems={setChildren} {...childrenDisclosure} />
                        <div className=" border rounded p-3">
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={3}>
                                <FormDropdown options={titles} isRequired label="Title" formik={formik} name="title" />
                                <FormInput autoComplete="off" isRequired label="First Name" formik={formik}
                                    name="first_name" />
                                <FormInput label="Middle Name" formik={formik} name="middle_name" />
                                <FormInput autoComplete="off" isRequired label="Last Name" formik={formik}
                                    name="last_name" />
                                <FormDropdown options={gender} isRequired label="Gender" formik={formik} name="gender" />
                                <FormInput isRequired type="email" customMessage={guardianEmailCustomError}
                                    label="Email" formik={formik} name="email" />
                                <FormInput placeholder="0XXXXXXXXX" isRequired type="tel" label="Mobile" formik={formik}
                                    name="mobile" />
                                <FormCheckBox label="Active" formik={formik} name="active" />
                            </SimpleGrid>
                        </div>

                        <SectionCard isError={isError} errorMessage="Please add a child" creatable={creatable}
                            subTitle="Select children related to the guardian" title="Children" p={3}>
                            <ChakraTable pageCount={10} isLoading={false}
                                size={"sm"} title="Children" columns={STUDENT_LIST_TAB_COLUMNS}
                                data={children || []} />
                        </SectionCard>

                        <DisclaimerList type="Guardian" />
                    </>
            }
        </>
    )
}

export default NewGuardianPage