import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useState } from "react";
import * as Yup from "yup";
import { COMPLIANCE, EXTERNAL } from "../../../../consts/methods.consts";
import { COMPLIANCE_TAGS, STAFF_TAGS } from "../../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../../services/api.service";
import { ToastService } from "../../../../services/toast.service";
import { FormDropdown, FormDropdownOptions } from "../../../common/form-controllers/FormDropdown";
import { FormInput } from "../../../common/form-controllers/FormInput";
import FormLiveSelect from "../../../common/form-controllers/FormLiveSelect";
import { ChakraModal } from "../../../index";


interface INewComplianceModal {
    disclosure: any
}

const compliance_types: FormDropdownOptions = [
    { label: "WWCC", value: "WWCC" },
    { label: "VIT", value: "VIT" }
]

const NewComplianceModal = ({ disclosure }: INewComplianceModal) => {
    const toast = new ToastService(useToast())
    const [create, {isLoading: isMutating}] = usePostMutation();
    const [query, setQuery] = useState("")
    const [isLoading, setIsLoading] = useState(false)


    const { data, isFetching } = useGetQuery({
        method: EXTERNAL.SEARCH_THERAPY_CONTRACTOR,
        body: { q: query },
        providesTags: [STAFF_TAGS.DROPDOWN]
    })

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            type: Yup.string().required("Compliant type is required"),
            visitor: Yup.object().required("Visitor is required"),
        }),
        onSubmit: async (values) => {
            try {
                setIsLoading(true)
                await create({
                    method: COMPLIANCE.ADD,
                    body: {
                        type: _.get(values, 'type'),
                        profile_type: _.get(values, ['visitor', 'profile_type']),
                        profile: _.get(values, ['visitor', 'profile']),
                        card_number: _.get(values, 'card_number'),
                        expire_date: _.get(values, 'expire_date')
                    },
                    invalidatesTags: [COMPLIANCE_TAGS.LIST]
                }).unwrap();
                formik.resetForm()
                toast.setTitle("Success").setDescription("Compliance creation successful").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to create compliance. Please try again").showErrorToast();
                console.error(error);
            } finally {
                setIsLoading(false)
                disclosure.onClose();
            }
        }
    })
    const searchHandler = _.debounce((text: string) => {
        setQuery(text)
    }, 250)

    return (
        <ChakraModal
            primaryAction={formik.submitForm}
            primaryButtonText={"Create"}
            isLoading={isLoading || isMutating}
            modalTitle={"New Compliance"}
            {...disclosure}
        >
            <FormDropdown options={compliance_types} isRequired label="Type" formik={formik} name="type" />
            <FormLiveSelect label="Visitor" formik={formik} name={"visitor"} options={data?.message?.map((line: any) => ({ label: line?.full_name, value: line?.name, ...line }))} isLoading={isFetching} onInputChange={searchHandler} isRequired />
            <FormInput label="Card Number" formik={formik} name="card_number" />
            <FormInput type={'date'} label="Expire Date" formik={formik} name="expire_date" />
        </ChakraModal>
    )
}

export default NewComplianceModal