import { SimpleGrid } from "@chakra-ui/layout";
import { HStack, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { NavigateFunction, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { InnerLoading, InstructionBox, PageHeader } from "../..";
import { DISCLAIMER, SCHOOL } from "../../../consts/methods.consts";
import { usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { FormCheckBox } from "../../common/form-controllers/FormCheckBox";
import { FormInput } from "../../common/form-controllers/FormInput";
import FormSelect from "../../common/form-controllers/FormSelect";
import { PageHeaderActions } from "../../common/page-header/PageHeader";
import { DISCLAIMER_TAGS } from "../../../consts/tags.consts";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";


const NewDisclaimerPage = () => {
    const navigate: NavigateFunction = useNavigate();
    const [create, { isLoading }] = usePostMutation();
    const toast = new ToastService(useToast());

    const formik = useFormik({
        initialValues: {
            active: false
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Policy title is required"),
            user_types: Yup.array().min(1).required("User type is required")
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: DISCLAIMER.ADD,
                    body: values,
                    invalidatesTags: [DISCLAIMER_TAGS.LIST]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Unable to add policy")
                }

                toast.setTitle("Success").setDescription("Policy added to the system").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/disclaimers/${newId}`, { replace: true });
                }, 750)

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to add policy. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isLoading
        },
    ]

    return (
        <>
            <PageHeader isLoading={isLoading} enableBackButton actions={actions} title="Create New Policy"
                subtitle="Policies" />

            {
                isLoading ? <InnerLoading /> :
                    <>
                        <InstructionBox>
                            <li>Please note that the policy you are trying to add will not be editable if it receives more than one consent in the future.
                            </li>
                            {_.get(formik, ['values', 'user_types'], []).includes("Guest") && (
                                <>
                                    <li>This policy is affecting to Guests, Volunteers, Gardeners, Tour, Banks, Analysts and Support Workers</li>
                                </>
                            )}
                        </InstructionBox>

                        <div className="animation-form border rounded p-3">
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={3}>
                                <FormInput isRequired label="Title" formik={formik} name="title" />
                                <FormInput label="Sub Title" formik={formik} name="sub_title" />
                                <FormInput label="Validity" type="number" formik={formik} name="validity" />
                                <FormInput label="Link" formik={formik} name="link" />
                                <FormSelect isMulti method={SCHOOL.GET_EXTERNAL_USER_TYPES} isRequired
                                    label="User Types"
                                    formik={formik} name="user_types" />
                                <HStack alignItems={"end"} gap={3}>
                                    <FormCheckBox label="Is Required" formik={formik} name="required_in_sign_in" />
                                    <FormCheckBox label="Active" formik={formik} name="active" />
                                </HStack>
                            </SimpleGrid>
                        </div>

                        <div className="animation-form border rounded p-3 mt-3">
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 2 }} spacing={3}>
                                <FormTextArea helperText="This will appear on KIOSK" rows={10} label="Description" formik={formik} name="description" />
                            </SimpleGrid>
                        </div>
                    </>
            }
        </>
    )
}

export default NewDisclaimerPage;