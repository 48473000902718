import {SimpleGrid} from "@chakra-ui/layout";
import {useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import _ from "lodash";
import {NavigateFunction, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {GUEST, SCHOOL, VALIDATION} from "../../../../consts/methods.consts";
import {FILTER_TAGS, GUEST_TAGS} from "../../../../consts/tags.consts";
import {useGetQuery, usePostMutation} from "../../../../services/api.service";
import {ToastService} from "../../../../services/toast.service";
import {FormDropdown, FormDropdownOptions} from "../../../common/form-controllers/FormDropdown";
import {FormInput} from "../../../common/form-controllers/FormInput";
import FormSelect from "../../../common/form-controllers/FormSelect";
import PageHeader, {PageHeaderActions} from "../../../common/page-header/PageHeader";
import DisclaimerList from "../components/DisclaimerList";
import {FormCheckBox} from "../../../common/form-controllers/FormCheckBox";
import {InnerLoading} from "../../../index";
import {PHONE_REG_EXP} from "../../../../consts/app.consts";
import {useEffect, useState} from "react";

const gender: FormDropdownOptions = [
    {label: "Male", value: "Male"},
    {label: "Female", value: "Female"}
]

const titles: FormDropdownOptions = [
    {label: "Mr", value: "Mr"},
    {label: "Ms", value: "Ms"},
    {label: "Miss", value: "Miss"},
    {label: "Mrs", value: "Mrs"},
    {label: "Mx", value: "Mx"}
]

const NewGuestPage = () => {
    const navigate: NavigateFunction = useNavigate();
    const [create, {isLoading}] = usePostMutation();
    const toast = new ToastService(useToast());
    const [guestEmailQ, setGuestEmailQ] = useState(null)
    const [guestMobileQ, setGuestMobileQ] = useState(null)

    const {data: _emailValidationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_GUEST_EMAIL_CREATE,
        body: {
            email: guestEmailQ
        },
        providesTags: [GUEST_TAGS.VIEW]
    })

    const {data: _mobileValidationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_GUEST_MOBILE_CREATE,
        body: {
            mobile: guestMobileQ
        },
        providesTags: [GUEST_TAGS.VIEW]
    })

    const formik = useFormik({
        initialValues: {
            active: true
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            gender: Yup.string().required("Gender is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            mobile: Yup.string().matches(PHONE_REG_EXP, 'Mobile number is not valid')
                .required('Mobile number is required')
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: GUEST.ADD,
                    body: {
                        ...values,
                        type: values?.type ? values.type : 'Guest'
                    },
                    invalidatesTags: [GUEST_TAGS.LIST, FILTER_TAGS.EXTERNAL_USER_SELECTOR]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Unable to register guest")
                }

                toast.setTitle("Success").setDescription("Guest registered to the system").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/guests/${newId}`, {replace: true});
                }, 750)

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to register guest. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isLoading
        },
    ]

    const typeGenerator = () => {
        const type = _.get(formik, ['values', 'type'], '')

        if (type === 'Volunteer') {
            return ['Guest', 'Volunteer']
        }

        if (type === 'Tour') {
            return ['Guest', 'Tour']
        }

        if (type === 'Bank') {
            return ['Guest', 'Bank']
        }

        if (type === 'Analyst') {
            return ['Guest', 'Analyst']
        }

        if (type === 'Support Workers') {
            return ['Guest', 'Support Workers']
        }

        if (type === 'Gardeners') {
            return ['Guest', 'Gardeners']
        }

        return ['Guest', 'Volunteer', 'Tour', 'Bank', 'Analyst', 'Support Workers', 'Gardeners']
    }

    useEffect(() => {
        guestEmailQHandler(_.get(formik.values, "email"))
        guestMobileQHandler(_.get(formik.values, "mobile"))
    }, [_.get(formik.values, "email"), _.get(formik.values, "mobile")])
    
    const guestEmailQHandler = _.debounce((value: any) => {
        setGuestEmailQ(value)
    }, 200)

    const guestMobileQHandler = _.debounce((value: any) => {
        setGuestMobileQ(value)
    }, 200)

    const guestEmailCustomError = (_emailValidationData?.message > 0) && (
        <p className="mt-1 text-sm text-red-500">Email already taken.</p>
    )

    const guestMobileCustomError = (_mobileValidationData?.message > 0) && (
        <p className="mt-1 text-sm text-red-500">Mobile number already taken.</p>
    )

    return (
        <>
            <PageHeader isLoading={isLoading} enableBackButton actions={actions} title="Create New Guest"
                        subtitle="Guest"/>
            {
                isLoading ? <InnerLoading/> :
                    <>
                        <div className="border rounded p-3">
                            <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 4}} spacing={3}>
                                <FormDropdown options={titles} isRequired label="Title" formik={formik} name="title"/>
                                <FormInput autoComplete="off" isRequired label="First Name" formik={formik}
                                           name="first_name"/>
                                <FormInput label="Middle Name" formik={formik} name="middle_name" />
                                <FormInput autoComplete="off" isRequired label="Last Name" formik={formik}
                                           name="last_name"/>
                                <FormSelect method={SCHOOL.GET_GUEST_TYPES} label="Guest Type" formik={formik}
                                            name="type"/>
                                <FormDropdown options={gender} isRequired label="Gender" formik={formik} name="gender"/>
                                <FormInput isRequired type="email" customMessage={guestEmailCustomError} label="Email"
                                           formik={formik} name="email"/>
                                <FormInput placeholder="0XXXXXXXXX" customMessage={guestMobileCustomError} isRequired
                                           type="tel" label="Mobile" formik={formik} name="mobile"/>
                                <FormCheckBox label="Active" formik={formik} name="active"/>
                            </SimpleGrid>
                        </div>

                        <DisclaimerList type={typeGenerator()}/>
                    </>
            }
        </>
    )
}

export default NewGuestPage;