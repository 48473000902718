import { BASE_URL } from '../consts/app.consts'
import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const apiService = createApi({
    reducerPath: 'apiService',
    baseQuery: fetchBaseQuery({
        baseUrl: `${BASE_URL}/method`,
        credentials: 'include',
        headers: {
            tz: Intl.DateTimeFormat().resolvedOptions().timeZone
        }
    }),
    tagTypes: ["list"],
    endpoints: build => ({
        login: build.mutation<any, any>({
            query: (payload: any) => ({
                url: `login`,
                method: 'POST',
                body: payload,
            }),
        }),
        post: build.mutation<any, any>({
            query: ({ method, body, invalidatesTags = [] }: any) => ({
                url: method,
                method: 'POST',
                body
            }),
            invalidatesTags: (result, error, arg) => arg?.invalidatesTags
        }),
        get: build.query<any, any>({
            query: ({ method, body = {}, providesTags = [] }: any) => ({
                url: method,
                method: 'POST',
                body
            }),
            providesTags: (result, error, arg) => arg?.providesTags
        }),
        isLogin: build.query<any, any>({
            query: () => ({
                url: `frappe.realtime.get_user_info`,
                method: 'GET',
            }),
            forceRefetch: () => {
                return true
            }
        }),
        logout: build.mutation<any, any>({
            query: () => ({
                url: `logout`,
                method: 'POST',
            }),
        }),
        download: build.mutation<any, any>({
            query: ({ method }) => ({
                url: method,
                method: 'GET'
            })
        })
    })
})

export const { useLoginMutation, useIsLoginQuery, usePostMutation, useLogoutMutation, useGetQuery, useDownloadMutation } = apiService
export default apiService