import {PageHeader} from "../../index";
import StudentDataImport from "./components/StudentDataImport";
import StaffDataImport from "./components/StaffDataImport";
import {IMPORT_DATA} from "../../../consts/methods.consts";

const DataImportPage = () => {

    return (
        <>
            <PageHeader title="Data Import" subtitle="Seamlessly upload your CSV or Excel files."/>

            <StaffDataImport method={IMPORT_DATA.STAFF}/>
            <StudentDataImport method={IMPORT_DATA.STUDENT}/>
        </>
    )
}

export default DataImportPage