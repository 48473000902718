import {useDisclosure, useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import {useState} from "react";
import * as Yup from "yup";
import {ChakraModal, PageHeader} from "../..";
import {STATION} from "../../../consts/methods.consts";
import {STATION_TAGS} from "../../../consts/tags.consts";
import {usePostMutation} from "../../../services/api.service";
import {ToastService} from "../../../services/toast.service";
import {FormInput} from "../../common/form-controllers/FormInput";
import {PageHeaderActions} from "../../common/page-header/PageHeader";
import StationListDataTable from "./components/StationListDataTable";

const StationListPage = () => {
    const disclosure = useDisclosure()
    const toast = new ToastService(useToast())
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [create, {isLoading}] = usePostMutation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)


    const formik = useFormik({
        initialValues: {
            station: ''
        },
        validationSchema: Yup.object({
            station: Yup.string().required("Station is required"),
        }),
        onSubmit: async (values) => {
            try {
                setIsSubmitting(true)
                await create({
                    method: isSelected ? STATION.UPDATE : STATION.ADD,
                    body: values,
                    invalidatesTags: [STATION_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription("Station registered to the system").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to register Station. Please try again").showErrorToast();
                console.error(error);
            } finally {
                setIsSubmitting(false)
                disclosure.onClose();
                setIsSelected(false);
            }
        }
    })


    const actions: PageHeaderActions = [
        {
            text: "Create New",
            onClick: () => {
                formik.resetForm();
                disclosure.onOpen()
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isSubmitting
        }
    ]


    return (
        <div>
            <PageHeader subtitle="View and Manage registered stations" title={`Stations`} actions={actions}/>
            <div className="grid gap-3 grid-cols-1">
                <StationListDataTable setIsSelected={setIsSelected} disclosure={disclosure} formik={formik}/>
            </div>

            <ChakraModal primaryAction={formik.submitForm} primaryButtonText={"Save"}
                         modalTitle="New Station" {...disclosure} isLoading={isLoading}>
                <FormInput isRequired formik={formik} name="station" autoFocus label="Station Name"/>
            </ChakraModal>
        </div>
    )
}

export default StationListPage;