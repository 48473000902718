import {TabPanel, useDisclosure, useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import _ from "lodash";
import {useState} from "react";
import {useSearchParams} from "react-router-dom";
import * as Yup from "yup";
import {RESOURCE_TYPE} from "../../../../consts/methods.consts";
import {DROPDOWN_TAGS, RESOURCE_TYPE_TAGS} from "../../../../consts/tags.consts";
import {useGetQuery, usePostMutation} from "../../../../services/api.service";
import {ToastService} from "../../../../services/toast.service";
import ChakraModal from "../../../common/chakra-modal/ChakraModal";
import ChakraTable, {ChakraTableColumns, ChakraTableCreatable} from "../../../common/chakra-table/ChakraTable";
import {FormInput} from "../../../common/form-controllers/FormInput";
import {useTableDataProvider} from "../../../hooks";

const ResourceTypeTab = () => {
    const disclosure = useDisclosure()
    const [searchParams] = useSearchParams()
    const toast = new ToastService(useToast())
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [create] = usePostMutation();
    const [searchQuery, setSearchQuery] = useState<string>("")
    const [page, setPage] = useState(1)

    const { data, isLoading } = useGetQuery({
        method: RESOURCE_TYPE.LIST,
        body: {
            page,
            q: searchQuery
        },
        providesTags: [RESOURCE_TYPE_TAGS.LIST, DROPDOWN_TAGS.LIST]
    })

    const { dataList, pageCount } = useTableDataProvider(data);

    const formik = useFormik({
        initialValues: {
            type: ''
        },
        validationSchema: Yup.object({
            type: Yup.string().required("Resource type is required"),
        }),
        onSubmit: async (values) => {
            try {
                await create({
                    method: isSelected ? RESOURCE_TYPE.UPDATE : RESOURCE_TYPE.ADD,
                    body: values,
                    invalidatesTags: [RESOURCE_TYPE_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription("Resource type registered to the system").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to register resource type. Please try again").showErrorToast();
                console.error(error);
            } finally {
                disclosure.onClose();
                setIsSelected(false);
            }
        }
    })

    const DEPARTMENT_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "Resource Type",
            accessor: "type",
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            options: [
                {
                    label: "Edit",
                    onClick(item: any) {
                        formik.setFieldValue("type", _.get(item, "type"))
                        formik.setFieldValue("_id", _.get(item, "_id"))
                        setIsSelected(true)
                        disclosure.onOpen()
                    },
                }
            ]
        }
    ]

    const creatable: ChakraTableCreatable = {
        onClick: () => {
            formik.resetForm();
            disclosure.onOpen();
        },
        text: "New"
    }

    const onSearchHandler = _.debounce((q: string) => {
        setSearchQuery(q)
    }, 200)

    return (
        <TabPanel className="!p-0 !pt-3">
            <ChakraTable onSearch={onSearchHandler} creatable={creatable} pageCount={pageCount} isLoading={isLoading}
                         pagination size={"sm"} header title="Resource Types" columns={DEPARTMENT_TAB_COLUMNS}
                         onPage={setPage} data={dataList}/>

            <ChakraModal primaryAction={formik.submitForm} primaryButtonText={"Save"} modalTitle="New Resource Type" {...disclosure}>
                <FormInput isRequired formik={formik} name="type" autoFocus label="Resource Type" />
            </ChakraModal>
        </TabPanel>
    )
}

export default ResourceTypeTab;