import {Link, useDisclosure, useToast} from "@chakra-ui/react"
import _ from "lodash"
import {PARTICIPANT} from "../../../../../consts/methods.consts"
import {ACTIVITY_TAGS} from "../../../../../consts/tags.consts"
import {usePostMutation} from "../../../../../services/api.service"
import {ToastService} from "../../../../../services/toast.service"
import ChakraTable, {ChakraTableColumns} from "../../../../common/chakra-table/ChakraTable"
import {SectionCard, SectionCardCreatable} from "../../../../common/section-card/SectionCard"
import SelectStudentModal from "./SelectStudentModal"
import React from "react";


interface IStudentListTable {
    activityId: string
    data: any[]
}

const StudentListTable = ({data, activityId}: IStudentListTable) => {
    const disclosure = useDisclosure()

    const [create] = usePostMutation();
    const toast = new ToastService(useToast());

    const LIST_TAB_COLUMNS: ChakraTableColumns = [
        {
            accessor: "student_id",
            header: "Student Id",
            customRenderer: (value, item) => {
                return tblUserIdCellMarkup(value, item)
            }
        },
        {
            accessor: "first_name",
            header: "First Name"
        },
        {
            accessor: "last_name",
            header: "Last Name"
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            options: [
                {
                    label: "Remove",
                    onClick(item) {
                        removeStudent(item);
                    },
                }
            ]
        }
    ]

    const removeStudent = async (model: any) => {
        try {
            const res = await create({
                method: PARTICIPANT.DELETE,
                body: {"_id": _.get(model, "name")},
                invalidatesTags: [ACTIVITY_TAGS.LIST, ACTIVITY_TAGS.VIEW]
            }).unwrap();

            toast.setTitle("Success").setDescription("Student has been removed from activity").showSuccessToast();
        } catch (error) {
            toast.setTitle("Error").setDescription("Cannot remove student").showErrorToast();
        }
    }

    const _onSelected = async (line: any) => {

    }

    const creatable: SectionCardCreatable = {
        text: "Browse",
        onClick: () => {
            disclosure.onOpen();
        }
    }

    const addParticipant = async (model: any) => {
        try {
            const res = await create({
                method: PARTICIPANT.ADD,
                body: {"profile": _.get(model, "id"), "profile_type": "Student", "activity": activityId},
                invalidatesTags: [ACTIVITY_TAGS.LIST, ACTIVITY_TAGS.VIEW]
            }).unwrap();

            toast.setTitle("Success").setDescription("Student added to the activity").showSuccessToast();
        } catch (error) {
            toast.setTitle("Error").setDescription("Cannot add student").showErrorToast();
        }
    }

    return (
        <>
            <SelectStudentModal selectedItems={[]} setSelectedItem={addParticipant} {...disclosure} />
            <SectionCard isError={false} errorMessage="Please add a child" creatable={creatable}
                         subTitle="Manage students related to the activity" title="Students" p={3}>
                <ChakraTable
                    size={"sm"}
                    title="Students"
                    onSelected={_onSelected}
                    columns={LIST_TAB_COLUMNS}
                    data={data}/>
            </SectionCard>
        </>
    )
}

const tblUserIdCellMarkup = (value: any, item: any) => {
    return (
        <Link color="#0073E5" href={`/app/students/${item?.profile_id}`} isExternal>
            {value}
        </Link>
    )
}

export default StudentListTable