import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import * as Yup from "yup";
import { ChakraModal } from "../../..";
import { PARCEL } from "../../../../consts/methods.consts";
import { PARCEL_TAGS } from "../../../../consts/tags.consts";
import { usePostMutation } from "../../../../services/api.service";
import { ToastService } from "../../../../services/toast.service";
import { FormTextArea } from "../../../common/form-controllers/FormTextArea";
import { useEffect } from "react";

interface ISignInModal {
    id: string | undefined
    note: string
    disclosure: any
}


const DeliverNotificationModal = ({ id, disclosure, note }: ISignInModal) => {
    const toast = new ToastService(useToast())
    const [create, {isLoading}] = usePostMutation();

    const formik = useFormik({
        initialValues: {
            note: note
        },
        validationSchema: Yup.object({
        }),
        onSubmit: async (values) => {
            try {
                await create({
                    method: PARCEL.NOTIFY,
                    body: {
                        id,
                        ...values,
                    },
                    invalidatesTags: [PARCEL_TAGS.LIST, PARCEL_TAGS.VIEW]
                }).unwrap();
                formik.resetForm()
                toast.setTitle("Success").setDescription("Notification sent.").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to send notification. Please try again").showErrorToast();
                console.error(error);
            } finally {
                disclosure.onClose();
            }
        }
    })

    useEffect(() => {
        formik.setFieldValue("note", note)
    }, [note])

    return (
        <ChakraModal
            primaryAction={formik.submitForm}
            primaryButtonText={"Notify"}
            modalTitle={"Notify to receiver"}
            {...disclosure}
            isLoading={isLoading}
        >
            <FormTextArea formik={formik} name={"note"} label={"Message"} helperText="This will be displayed to the receiver." />
        </ChakraModal>
    )
}

export default DeliverNotificationModal