import { DROPDOWN_TAGS } from "../../../../consts/tags.consts"
import apiService from "../../../../services/api.service"

export const getFormOptions = (method: string) => {
    return async (dispatch: any) => {
        const res = await dispatch(apiService.endpoints.get.initiate({
            method,
            body: {},
            providesTags: [DROPDOWN_TAGS.LIST]

        }))

        return res;
    }
}