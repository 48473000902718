import ChakraTable, {ChakraTableColumns} from "../../../common/chakra-table/ChakraTable";
import _ from "lodash";
import {useSearchParams} from "react-router-dom";
import {useGetQuery} from "../../../../services/api.service";
import {PARCEL} from "../../../../consts/methods.consts";
import {PARCEL_TAGS} from "../../../../consts/tags.consts";
import {useTableDataProvider} from "../../../hooks";
import {useFormik} from "formik";
import React, {useEffect, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router";
import {useToast} from "@chakra-ui/react";
import {ToastService} from "../../../../services/toast.service";
import {IOpenPdf, openPdf} from "../../../../utils/utils";

const DeliveriesListDataTable = () => {
    const toast = new ToastService(useToast());
    const navigate: NavigateFunction = useNavigate()
    const [filters, setFilters] = useState({})
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)

    const {data, isLoading, isFetching} = useGetQuery({
        method: PARCEL.LIST,
        body: {
            page,
            length: Number(length),
            ...filters
        },
        providesTags: [PARCEL_TAGS.LIST]
    })

    const {dataList, pageCount} = useTableDataProvider(data);
    const tableLineClickHandler = (line: any) => {
        navigate(`${line._id}`)
    }

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({...filters, ...value})
    }, 200)

    const onPrint = async (columns: any, data: any) => {
        const config: IOpenPdf = {
            tableData: {
                columns,
                data,
                filters,
                info: {
                    title: "Delivery List"
                }
            },
            toast
        }

        openPdf(config);
    }

    return (
        <>
            <ChakraTable onPrint={onPrint} onSearch={onSearchHandler} pageCount={pageCount} isLoading={isLoading}
                         isFetching={isFetching} pagination lengthPaginations size={"sm"} onPage={setPage}
                         onLength={setLength} header title="Deliveries" onSelected={tableLineClickHandler}
                         columns={TAB_COLUMNS} data={dataList}/>
        </>
    )
}

const TAB_COLUMNS: ChakraTableColumns = [
    {
        header: "Parcel",
        accessor: "item_name"
    },
    {
        header: "Owner",
        accessor: "receiver"
    },
    {
        header: "Sender",
        accessor: "sender"
    },
    {
        header: "Sender Contact",
        accessor: "sender_contact"
    },
    {
        header: "Station",
        accessor: "station"
    },
    {
        header: "Received Date",
        accessor: "received_date",
    },
    {
        header: "Pickup",
        accessor: "is_pickup",
        conditions: {
            'No': 'red',
            'Yes': 'green'
        }

    },
    {
        header: "Pickup Date",
        accessor: "pickup_date"
    },
    {
        header: "Note",
        accessor: "note"
    }
]
export default DeliveriesListDataTable