import _ from "lodash";

export const itemSelector = (item: any, setSelected: any) => {
    setSelected((prev: any) => {
        const index = prev.findIndex((i: any) => i.id == item.id)

        if (index == -1) {
            return [item, ...prev];
        }

        return prev.filter((i: any) => i.id != item.id)
    })
}

export const onIsAllCheckChanges = (event: any, data: any, selected: any, setSelected: any, type: string) => {
    if (event.target.checked) {
        setSelected((prev: any) => _.uniq([...data, ...prev]));
        return;
    }

    const filteredMembers = (selected || []).filter((i: any) => i.__type != type)
    setSelected(filteredMembers)
}

export const isAllChecked = (selected: any, data: any, type: any) => {
    const filteredCount: any = (selected || []).filter((i: any) => i.__type == type).length
    const dataCount = (data || []).length

    if (dataCount == 0) {
        return false;
    }

    return (dataCount == filteredCount)
}
