import moment from "moment";
import {useToast} from "@chakra-ui/react";
import {ToastService} from "../../../services/toast.service";
import {useFormik} from "formik";
import {ChakraModal} from "../..";
import {FormInput} from "../form-controllers/FormInput";
import {BASE_URL} from "../../../consts/app.consts";
import {FormDropdown} from "../form-controllers/FormDropdown";
import {useEffect, useState} from "react";
import _ from "lodash";
import axios from "axios";

interface IExportModal {
    data?: any
    method: string | undefined
    disclosure: any,
    period?: boolean
}

const periodOptions = [
    {
        label: "Week",
        value: "week"
    },
    {
        label: "Fortnight",
        value: "fortnight"
    },
    {
        label: "Month",
        value: "month"
    }
]

const ExportModal = ({disclosure, method, data, period}: IExportModal) => {
    const toast = new ToastService(useToast())
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const formik = useFormik({
        initialValues: {
            from: moment().format("YYYY-MM-DD"),
            to: moment().format("YYYY-MM-DD")
        },
        onSubmit: async (values) => {
            setIsLoading(true)
            axios.get(`${BASE_URL}/method/${method}?from=${values['from']}&to=${values.to}`, {
                withCredentials: true,
                responseType: "blob"
            })
                .then((response) => {
                    console.log(response)
                    const href = URL.createObjectURL(response.data);

                    const link = document.createElement('a');
                    link.href = href;
                    link.setAttribute('download', `export.xlsx`);
                    document.body.appendChild(link);
                    link.click();
                    document.body.removeChild(link);
                    URL.revokeObjectURL(href);
                    toast.setTitle("Success").setDescription("Successfully Exported").showSuccessToast();
                })
                .catch((e) => {
                    toast.setTitle("Error").setDescription("Unable to export. Please try again").showErrorToast();
                })
                .finally(() => {
                    setIsLoading(false)
                    disclosure.onClose()
                })
        }
    })

    useEffect(() => {
        let period = _.get(formik.values, "period")

        if (period === "week") {
            let currentDate = new Date()
            currentDate.setDate(currentDate.getDate() - 7)
            formik.setFieldValue("from", moment(currentDate).format("YYYY-MM-DD"))
            formik.setFieldValue("to", moment().format("YYYY-MM-DD"))
        }

        if (period === "fortnight") {
            let currentDate = new Date()
            currentDate.setDate(currentDate.getDate() - 14)
            formik.setFieldValue("from", moment(currentDate).format("YYYY-MM-DD"))
            formik.setFieldValue("to", moment().format("YYYY-MM-DD"))
        }

        if (period === "month") {
            let currentDate = new Date()
            currentDate.setDate(currentDate.getDate() - 30)
            formik.setFieldValue("from", moment(currentDate).format("YYYY-MM-DD"))
            formik.setFieldValue("to", moment().format("YYYY-MM-DD"))
        }

    }, [_.get(formik.values, 'period')])

    return (
        <ChakraModal
            primaryAction={() => {
                formik.submitForm()
            }}
            primaryButtonText={"Download"}
            modalTitle="Export data"
            {...disclosure}
            isLoading={isLoading}
        >
            {period && <FormDropdown options={periodOptions} label="Period" formik={formik} name="period"/>}
            <FormInput type={'date'} formik={formik} name={"from"} label="From"/>
            <FormInput type={'date'} formik={formik} name={"to"} label="To"/>
        </ChakraModal>
    )
}

export default ExportModal