import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import TimeSheetDataTable from "./components/TimeSheetDataTable";
import ExportModal from "../../common/export-modal/ExportModal";
import { EXPORT_DATA } from "../../../consts/methods.consts";
import { useDisclosure } from "@chakra-ui/react";

const TimeSheetPage = () => {

    const exportDisclosure = useDisclosure()


    const actions: PageHeaderActions = [

        {
            text: "Export as Excel",
            isDisabled: false,
            onClick: exportDisclosure.onOpen,
            buttonColorScheme: "messenger"
        }
    ]

    return (
        <>
            <PageHeader subtitle="View staff time sheets" title={`Staff Time Sheets`} actions={actions} />
            <ExportModal method={EXPORT_DATA.TIME_SHEET} disclosure={exportDisclosure} period/>
            <TimeSheetDataTable />
        </>
    )
}

export default TimeSheetPage