import {NavigateFunction, useNavigate} from "react-router";
import {PageHeader} from "../..";
import {PageHeaderActions} from "../../common/page-header/PageHeader";
import DisclaimerListDataTable from "./components/DisclaimerListDataTable";

const DisclaimerListPage = () => {
    const navigate: NavigateFunction = useNavigate()

    const actions: PageHeaderActions = [
        {
            text: "Create New",
            onClick: () => navigate("new"),
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    return (
        <div>
            <PageHeader subtitle="View and Manage Policies" title={`Policies`} actions={actions}/>
            <DisclaimerListDataTable/>
        </div>
    )
}

export default DisclaimerListPage;