import {NavigateFunction, useNavigate} from "react-router";
import {PageHeader} from "../../..";
import {PageHeaderActions} from "../../../common/page-header/PageHeader";
import ContractorListDataTable from "./components/ContractorListDataTable";


const ContractorListPage = () => {
    const navigate: NavigateFunction = useNavigate()

    const actions: PageHeaderActions = [

        {
            text: "Create New",
            onClick: () => navigate("new"),
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    return (
        <div>
            <PageHeader subtitle="View and Manage registered contractors" title={`Contractors`} actions={actions}/>
            <ContractorListDataTable/>
        </div>
    )
}

export default ContractorListPage;