import {FormDropdown, FormDropdownOptions} from "../../../common/form-controllers/FormDropdown";
import React, {useEffect, useState} from "react";
import {ChakraTable} from "../../../index";
import {useSearchParams} from "react-router-dom";
import {useGetQuery} from "../../../../services/api.service";
import {RESOURCE} from "../../../../consts/methods.consts";
import {RESOURCE_TAGS} from "../../../../consts/tags.consts";
import {useObjChange, useTableDataProvider} from "../../../hooks";
import _ from "lodash";
import {ChakraTableColumns} from "../../../common/chakra-table/ChakraTable";
import {useFormik} from "formik";
import {Link} from "@chakra-ui/react";

interface IProps {
    id: string
}

const sortOptions: FormDropdownOptions = [
    {label: "ASC", value: "ASC"},
    {label: "DESC", value: "DESC"}
]

const profileTypeMapping: any = {
    "Student": "students",
    "Staff": "staff"
}

const RESOURCE_LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        header: "USER",
        accessor: "full_name",
        customRenderer: (value, item) => {
            return tblNameCellMarkup(value, item)
        }
    },
    {
        header: "PROFILE TYPE",
        accessor: "profile_type",
        conditions: {
            'Staff': 'cyan',
            'Student': 'blue'
        }
    },
    {
        header: "ALLOCATED DATE",
        accessor: "sign_out_time"
    },

    {
        header: "HANDOVER DATE",
        accessor: "sign_in_time",
    }
]

const tblNameCellMarkup = (value: any, item: any) => {
    return (
        <Link color="#0073E5" href={`/app/${profileTypeMapping[item?.profile_type]}/${item?.profile}`} isExternal>
            {value}
        </Link>
    )
}

const AssignmentHistoryDataTable: React.FC<IProps> = ({id}) => {
    const [searchParams, setSearchParams] = useSearchParams()
    const [filters, setFilters] = useState({})

    const {data, isLoading, isFetching} = useGetQuery({
        method: RESOURCE.ASSIGNMENT_HISTORY,
        body: {
            id: id,
            page: searchParams.get("page") ?? 1,
            ...filters
        },
        providesTags: [RESOURCE_TAGS.ASSIGNMENT_LIST]
    })

    const {dataList, pageCount} = useTableDataProvider(data);

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    const {isChanged} = useObjChange(formik.values)

    useEffect(() => {
        if (isChanged) {
            onFilterHandler(formik.values)
        }
    }, [JSON.stringify(formik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setSearchParams({...searchParams, ...value})
        setFilters({...searchParams, ...value})
    }, 200)

    const customFilters = (
        <div className=" flex flex-row justify-center items-center gap-1">
            <FormDropdown minW="40" size={"sm"} options={sortOptions} isRequired formik={formik} name="sort"
                          placeholder="Sort"/>
        </div>
    )

    return (
        <>
            <ChakraTable onSearch={onSearchHandler} pageCount={pageCount} isLoading={isLoading} isFetching={isFetching}
                         pagination size={"sm"} header title="History" customFilterRenderer={customFilters}
                         columns={RESOURCE_LIST_TAB_COLUMNS} data={dataList}/>
        </>
    )
}

export default AssignmentHistoryDataTable