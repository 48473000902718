import { useState } from "react"
import { STUDENT, THERAPIST } from "../../../../consts/methods.consts"
import { STAFF_TAGS } from "../../../../consts/tags.consts"
import { useGetQuery } from "../../../../services/api.service"
import FormLiveSelect from "../../../common/form-controllers/FormLiveSelect"
import _ from "lodash"
import { useParams } from "react-router-dom"

interface ISelectGuardian {
    formik: any,
    isRequired?: boolean
    initQuery?: string
    isMulti?: boolean
}

const SelectGuardian = ({ formik, isRequired = false, initQuery = "", isMulti = false }: ISelectGuardian) => {
    const [query, setQuery] = useState(initQuery)
    const { id } = useParams()

    const { data, isFetching } = useGetQuery({
        method: STUDENT.GUARDIAN_LIST_DROPDOWN,
        body: { q: query, id },
        providesTags: [STAFF_TAGS.DROPDOWN]
    })

    const searchHandler = _.debounce((text: string) => {
        setQuery(text)
    }, 250)

    return (
        <FormLiveSelect label="Medical Emergency Contact" formik={formik} name={"medical_emergency_contact"} options={data?.message ?? []} isLoading={isFetching} onInputChange={searchHandler} isRequired={isRequired} isMulti={isMulti} />
    )
}

export default SelectGuardian