import React, { useEffect, useState } from "react"
import { Link, useDisclosure, useToast } from "@chakra-ui/react"
import ChakraTable, { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable"
import { useTableDataProvider } from "../../../hooks"
import { useGetQuery } from "../../../../services/api.service"
import { REPORTS } from "../../../../consts/methods.consts"
import { ACTIVITY_TAGS } from "../../../../consts/tags.consts"
import _ from "lodash";
import { FormDropdown, FormDropdownOptions } from "../../../common/form-controllers/FormDropdown"
import { useFormik } from "formik"
import { FormInput } from "../../../common/form-controllers/FormInput"
import { ToastService } from "../../../../services/toast.service"
import { filteredTableData, IOpenPdf, openPdf } from "../../../../utils/utils"
import SignatureCell from "../../../common/signature-cell/SignatureCell";

const profileOptions: FormDropdownOptions = [
    { label: "Student", value: "Student" },
    { label: "Staff", value: "Staff" },
    { label: "Guest", value: "Guest" },
    { label: "Contractor", value: "Contractor" },
    { label: "Therapist", value: "Therapist" },
    { label: "Guardian", value: "Guardian" }
]

const profileTypeMapping: any = {
    "Student": "students",
    "Staff": "staff",
    "Guardian": "guardians",
    "Therapist": "therapists",
    "Contractor": "contractors",
    "Guest": "guests"
}

const LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        accessor: "full_name",
        header: "Name",
        customRenderer: (value, item) => {
            return tblNameCellMarkup(value, item)
        }
    },
    {
        accessor: "profile_type",
        header: "Type",
        conditions: {
            'Staff': 'cyan',
            'Student': 'blue',
            'Guest': 'linkedin',
            'Contractor': 'linkedin',
            'Guardian': 'linkedin',
            'Therapist': 'linkedin'
        }
    },
    {
        accessor: "description",
        header: "Description"
    },
    {
        accessor: "sign_in_station",
        header: "Sign In Station"
    },
    {
        accessor: "sign_in",
        header: "Sign In",
        conditions: {
            'No': 'red',
            'Yes': 'green'
        }
    },
    {
        accessor: "sign_in_signature",
        header: "Sign In Signature",
        customRenderer: (value, item) => {
            return <SignatureCell value={value} />
        }
    },
    {
        accessor: "sign_in_time",
        header: "Sign In Time"
    },
    {
        accessor: "sign_out_station",
        header: "Sign Out Station"
    },
    {
        accessor: "sign_out",
        header: "Sign Out",
        conditions: {
            'No': 'red',
            'Yes': 'green'
        }
    },
    {
        accessor: "sign_out_signature",
        header: "Sign Out Signature",
        customRenderer: (value, item) => {
            return <SignatureCell value={value} />
        }
    },
    {
        accessor: "sign_out_time",
        header: "Sign Out Time"
    }
]


const tblNameCellMarkup = (value: any, item: any) => {
    const profileId = _.get(item, 'profile_id')
    return (
        <Link color="#0073E5" href={profileId ? `/app/${profileTypeMapping[item?.profile_type]}/${profileId}` : "#"}
            isExternal={!!profileId}>
            {value}
        </Link>
    )
}

const groupOptions: FormDropdownOptions = [
    { label: "Without Group", value: 0 }
]

const VisitLogDataTable = () => {
    const _disclosure = useDisclosure();
    const [filters, setFilters] = useState({})
    const toast = new ToastService(useToast());
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)

    const { data, isLoading } = useGetQuery({
        method: REPORTS.VISIT_LOG,
        body: {
            page,
            length: Number(length),
            ...filters
        },
        providesTags: [ACTIVITY_TAGS.LIST]
    })

    const { pageCount, dataList, totalResult } = useTableDataProvider(data)

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })


    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const _onSelected = (line: any) => {
        _disclosure.onOpen()
    }

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({ ...filters, ...value })
    }, 200)

    const customFilters = (
        <div className=" flex flex-row justify-center items-center gap-2">
            <FormInput w={180} onFocus={(e) => (e.currentTarget.type = "date")}
                onBlur={(e) => (e.currentTarget.type = "text")}
                size={"sm"} type="text" isRequired formik={formik} name="from" placeholder="From" />
            <FormInput w={180} onFocus={(e) => (e.currentTarget.type = "date")}
                onBlur={(e) => (e.currentTarget.type = "text")}
                size={"sm"} type="text" isRequired formik={formik} name="to" placeholder="To" />
            <FormDropdown minW={40} size={"sm"} options={groupOptions} isRequired formik={formik} name="group"
                placeholder="With Group" />
            <FormDropdown minW={40} size={"sm"} options={profileOptions} isRequired formik={formik} name="type"
                placeholder="Type" />
        </div>
    )

    const onPrint = async (columns: any, data: any) => {

        const columnsAndDataToRemove = ["sign_out_signature", "sign_in_signature"];
        const { filteredColumns, filteredData } = filteredTableData(columns, data, columnsAndDataToRemove)

        const config: IOpenPdf = {
            tableData: {
                columns: filteredColumns,
                data: filteredData,
                filters,
                info: {
                    title: "Visit Log"
                }
            },
            toast
        }

        openPdf(config);
    }

    return (
        <ChakraTable
            customFilterRenderer={customFilters}
            onSearch={onSearchHandler}
            size={"sm"}
            header title="Visit Log"
            onSelected={_onSelected}
            isLoading={isLoading}
            columns={LIST_TAB_COLUMNS}
            pageCount={pageCount}
            pagination
            lengthPaginations
            onPrint={onPrint}
            onPage={setPage}
            onLength={setLength}
            totalResult={totalResult}
            data={dataList} />
    )
}

export default VisitLogDataTable
