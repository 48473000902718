import { Button, Checkbox, HStack, Input, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Stack, UseDisclosureProps, VStack } from "@chakra-ui/react"
import { Badge } from "flowbite-react"
import { useFormik } from "formik"
import _ from "lodash"
import { useEffect, useState } from "react"
import { useDispatch } from "react-redux"
import { FILTERS } from "../../../consts/methods.consts"
import { getStaff } from "./actions/staff-selector.action"

interface StaffSelectorProps {
    isOpen: UseDisclosureProps["isOpen"],
    onClose: UseDisclosureProps["onClose"] | undefined,
    setSelectedItems: any
    selectedItems: any
}

const StaffSelectorModal = ({ isOpen = false, onClose = () => { }, setSelectedItems = () => { }, selectedItems = [] }: StaffSelectorProps) => {
    const dispatch = useDispatch()
    const [data, setData] = useState([]);
    const [selected, setSelected] = useState<any>([]);
    const [isLoading, setIsLoading] = useState<boolean>(false);

    const formik = useFormik({
        initialValues: {
            name: '',
            class: ''
        },
        onSubmit: () => { }
    })

    useEffect(() => {
        formik.resetForm();
    }, [isOpen])

    useEffect(() => {
        setSelected(selectedItems);
    }, [selectedItems])

    useEffect(() => {
        fetchData().then()
    }, [JSON.stringify(formik.values)])

    const fetchData = async () => {
        try {
            setIsLoading(true);

            const method = FILTERS.STAFF_SELECTOR;
            const body = {
                name: _.get(formik, ['values', 'name'], ''),
                class: _.get(formik, ['values', 'class'], '')
            };

            const res = await dispatch(getStaff(method, body) as any)
            const data = _.get(res, ['data', 'message'], [])

            setData(data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    const isAllChecked = ((data || []).length > 0 && (data || []).length == (selected || []).length)
    const clearSelection = () => setSelected([]);

    const itemSelector = (item: any) => {
        setSelected((prev: any) => {
            const index = prev.findIndex((i: any) => i.id == item.id)

            if (index == -1) {
                return [item, ...prev];
            }

            return prev.filter((i: any) => i.id != item.id)
        })
    }

    const onIsAllCheckChanges = (event: any) => {
        if (event.target.checked) {
            setSelected(data);
            return;
        }

        clearSelection();
    }

    const onSaveHandler = () => {
        setSelectedItems((prev: any) => [...selected]);
        onClose();
    }

    const searchInputOnChange = (event: any) => {
        _.debounce(() => {
            formik.setFieldValue("name", (event.target.value || '').trim())
        }, 1000)()
    }

    const itemMarkup = data.map((line: any, index: number) => <Item key={index} onClick={itemSelector} end={(data?.length || 0) == (index + 1)} selected={selected} item={line} />)

    const selectedItemMarkup = selected.map((line: any, index: number) => <Item key={index} onClick={itemSelector} end={(data?.length || 0) == (index + 1)} selected={selected} item={line} />)

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            motionPreset='slideInBottom'
            onClose={onClose}
            size={"3xl"}>
            <ModalOverlay />
            <ModalContent >
                <ModalHeader>Select Staff</ModalHeader>
                <ModalCloseButton autoFocus={false} _focus={{ border: 'none' }} />
                <ModalBody pb={0}>
                    <HStack>
                        <Input onChange={searchInputOnChange} placeholder="Search by name" size={"sm"} rounded={"md"} shadow={"sm"} />
                    </HStack>

                    {/* <VStack pt={2}>
                        <HStack className="w-full" justifyContent={"space-between"}>
                            <div className="flex items-center gap-2">
                                <FormSelect size="sm" placeholder={"Select a class"} method={SCHOOL.GET_CLASS_LIST_FOR_STUDENT_SELECTOR} formik={formik} name="class" />
                            </div>
                            <Button onClick={formik.resetForm as any} leftIcon={<FaTimes />} iconSpacing={0} size={"sm"}></Button>
                        </HStack>
                    </VStack> */}

                    <div className="grid grid-cols-2 gap-4 pt-2">
                        <div className="border rounded shadow-sm">
                            <HStack my={0} py={1} px={3} className="border-b mt-2 border-gray-300 bg-slate-100">
                                <Checkbox borderColor={'gray.300'} colorScheme="teal" onChange={onIsAllCheckChanges} isChecked={isAllChecked} size={"md"} />
                                <div className="flex justify-between w-full items-center">
                                    <p className="ml-1 select-none font-semibold text-gray-600 text-sm">Staff</p>
                                    {/* <p className="ml-1 select-none font-semibold text-gray-600 text-sm">Class</p> */}
                                </div>
                            </HStack>
                            <Stack gap={0} className="max-h-[35vh] !min-h-[35vh] overflow-y-auto">
                                {!isLoading && itemMarkup}
                                {!isLoading && _.isEmpty(data) && <p className="text-sm text-center mt-3">No staff found!</p>}
                            </Stack>
                        </div>

                        <div className="border rounded shadow-sm">
                            <HStack my={0} py={1} px={3} className="border-b mt-2 border-gray-300 bg-slate-100">
                                <div className="flex justify-between w-full items-center">
                                    <p className="select-none font-semibold text-gray-600 text-sm">Selected Staff({selected?.length || 0})</p>
                                    <p className="ml-1 select-none font-semibold text-gray-600 text-sm">Class</p>
                                </div>
                            </HStack>
                            <Stack gap={0} className="max-h-[35vh] min-h-[35vh] overflow-y-auto">
                                {selectedItemMarkup}
                            </Stack>
                        </div>
                    </div>
                </ModalBody>

                <ModalFooter>
                    <HStack spacing={3}>
                        <Button variant={"solid"} size={"sm"} colorScheme="teal" onClick={onSaveHandler}>Apply</Button>
                        <Button variant={"solid"} size={"sm"} colorScheme="red" onClick={onClose}>Cancel</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent >
        </Modal >
    )
}

interface ItemProps {
    end?: boolean,
    item: any,
    selected?: any,
    onClick?: any
}

const Item = ({ end, item, selected = [], onClick }: ItemProps) => {
    const isChecked = selected.findIndex((i: any) => i.id == item.id)

    return (
        <VStack className="hover:bg-slate-50 transition-all" px={3} onClick={() => onClick(item)} cursor={"pointer"} borderBottom={end ? 'unset' : '1px'} borderColor={"gray.300"} alignItems={"flex-start"}>
            <HStack gap={3} py={2} justifyContent={"space-between"} w={'full'}>
                <div className="flex items-center gap-3">
                    <Checkbox onChange={() => onClick(item)} className="outline-none " colorScheme="teal" isChecked={isChecked != -1} size={"md"} />
                    <VStack spacing={0} alignItems={"flex-start"}>
                        <p className="select-none text-gray-600 text-sm">{item.full_name}</p>
                        <p className="text-gray-500 select-none text-xs">{item.student_id}</p>
                    </VStack>
                </div>
                <Badge color={"purple"} className="select-none">{item._type}</Badge>
            </HStack>
        </VStack>
    )
}

export default StaffSelectorModal