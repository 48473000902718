import { SimpleGrid } from "@chakra-ui/layout";
import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useState } from "react";
import { NavigateFunction, useNavigate } from "react-router-dom";
import * as Yup from "yup";
import { InnerLoading, PageHeader } from "../..";
import { ACTIVITY, PARCEL, SCHOOL, STAFF } from "../../../consts/methods.consts";
import { PARCEL_TAGS, STAFF_TAGS } from "../../../consts/tags.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { FormInput } from "../../common/form-controllers/FormInput";
import FormLiveSelect from "../../common/form-controllers/FormLiveSelect";
import FormSelect from "../../common/form-controllers/FormSelect";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import { PageHeaderActions } from "../../common/page-header/PageHeader";
import { ACTIVITY_TYPES } from "../../../consts/types.consts";
import moment from "moment";
import { PHONE_REG_EXP } from "../../../consts/app.consts";

const NewParcelPage = () => {
    const [query, setQuery] = useState("")
    const navigate: NavigateFunction = useNavigate();
    const [create, { isLoading }] = usePostMutation();
    const toast = new ToastService(useToast());

    const { data, isFetching } = useGetQuery({
        method: STAFF.SEARCH,
        body: { q: query },
        providesTags: [STAFF_TAGS.DROPDOWN]
    })

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            sender: Yup.string().required("Sender is required"),
            station: Yup.string().required("Station is required"),
            receiver: Yup.string().required("Receiver is required"),
            item_name: Yup.string().required("Item name is required"),
            sender_contact: Yup.string().matches(PHONE_REG_EXP, 'Contact number is not valid').nullable()
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: ACTIVITY.ADD,
                    body: {
                        title: "Sign in a delivery",
                        type: ACTIVITY_TYPES.PARCEL,
                        station: _.get(values, "station"),
                        start_time: moment().format("YYYY-MM-DD H:mm"),
                        parcels: [{
                            received_date: moment().format("YYYY-MM-DD H:mm"),
                            ...values,
                            verified_receiver: _.get(values, ["verified_receiver", "value"]) ?? ""
                        }]
                    },
                    invalidatesTags: [PARCEL_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription("Delivery added to the system").showSuccessToast();

                setTimeout(() => {
                    navigate(-1);
                }, 750)

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to add delivery. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const staffSearchHandler = _.debounce((text: string) => {
        setQuery(text)
    }, 250)

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isLoading
        },
    ]

    return (
        <>
            <PageHeader isLoading={isLoading} enableBackButton actions={actions} title="Create New Delivery"
                subtitle="Delivery" />

            {
                isLoading ? <InnerLoading /> :
                    <>
                        <div className="border rounded p-3">
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={3}>
                                <FormSelect method={PARCEL.LIST_FOR_DROPDOWN} isRequired label="Sender" formik={formik}
                                    name="sender" />
                                <FormSelect
                                    method={SCHOOL.GET_STATION_LIST_FOR_DROPDOWN}
                                    isRequired label="Station"
                                    formik={formik}
                                    name="station" />
                                <FormLiveSelect label="Verified Receiver" formik={formik} name={"verified_receiver"}
                                    options={data?.message?.map((line: any) => ({
                                        label: line?.full_name,
                                        value: line?.name
                                    }))} isLoading={isFetching} onInputChange={staffSearchHandler} />
                                <FormInput placeholder="0XXXXXXXXX" label="Contact Number" formik={formik} name="sender_contact" />
                                <FormInput isRequired label="Receiver" formik={formik} name="receiver" />
                                <FormInput isRequired label="Item Name" formik={formik} name="item_name" />
                                <FormTextArea label="Note" formik={formik} name="note" />
                            </SimpleGrid>
                        </div>
                    </>
            }
        </>
    )
}

export default NewParcelPage