import {FormDropdown, FormDropdownOptions} from "../../../common/form-controllers/FormDropdown";
import React, {useEffect, useState} from "react";
import {ChakraTable} from "../../../index";
import {NavigateFunction, useNavigate} from "react-router";
import {useGetQuery} from "../../../../services/api.service";
import {RESOURCE} from "../../../../consts/methods.consts";
import {RESOURCE_TAGS} from "../../../../consts/tags.consts";
import {useTableDataProvider} from "../../../hooks";
import _ from "lodash";
import {ChakraTableColumns} from "../../../common/chakra-table/ChakraTable";
import {useFormik} from "formik";

const availabilityOptions: FormDropdownOptions = [
    {label: "Available", value: "1"},
    {label: "Not Available", value: "0"}
]

const RESOURCE_LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        header: "Resource Id",
        accessor: "resource_id",
    },
    {
        header: "Title",
        accessor: "title",
    },
    {
        header: "Availability",
        accessor: "available",
        conditions: {
            'Available': 'green',
            'Not Available': 'red'
        }
    },
    {
        header: "Type",
        accessor: "type",
    },
    {
        header: "Department",
        accessor: "department",
    },

]

const ResourceListDataTable = () => {
    const navigate: NavigateFunction = useNavigate()
    const [filters, setFilters] = useState({})
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)

    const {data, isLoading, isFetching} = useGetQuery({
        method: RESOURCE.LIST,
        body: {
            page,
            length: Number(length),
            ...filters
        },
        providesTags: [RESOURCE_TAGS.LIST]
    })

    const {dataList, pageCount} = useTableDataProvider(data);

    const tableLineClickHandler = (line: any) => {
        navigate(`${line.name}`)
    }

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({...filters, ...value})
    }, 200)

    const customFilters = (
        <div className=" flex flex-row justify-center items-center gap-1">
            <FormDropdown minW="40" size={"sm"} options={availabilityOptions} isRequired formik={formik}
                          name="availability" placeholder="Availability"/>
        </div>
    )

    return (
        <>
            <ChakraTable onSearch={onSearchHandler} pageCount={pageCount} isLoading={isLoading} isFetching={isFetching}
                         onPage={setPage} onLength={setLength} pagination lengthPaginations size={"sm"} header
                         title="Resources" customFilterRenderer={customFilters}
                         columns={RESOURCE_LIST_TAB_COLUMNS} data={dataList} onSelected={tableLineClickHandler}/>
        </>
    )
}

export default ResourceListDataTable