import * as Yup from "yup";
import React, {useState} from 'react';
import {FormInput} from '../../../common/form-controllers/FormInput';
import {useFormik} from 'formik';
import {ToastService} from '../../../../services/toast.service';
import {usePostMutation} from '../../../../services/api.service';
import {Button, Icon, Text, useToast} from '@chakra-ui/react';
import {RiShieldKeyholeLine} from "react-icons/ri";
import {AUTH} from "../../../../consts/methods.consts";

interface IProps {
    isResetDisclosure: any
}

export const ForgotPassword: React.FC<IProps> = ({isResetDisclosure}) => {
    const toast = new ToastService(useToast())

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [create] = usePostMutation();

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            user: Yup.string().required("Email is required")
        }),
        onSubmit: async (values: any) => {
            try {
                setIsLoading(true);
                await create({
                    method: AUTH.FORGOT_PASSWORD,
                    body: values,
                    invalidatesTags: []
                }).unwrap()
                toast.setTitle("Success").setTitle("Password reset link sent")
                    .setDescription("We have sent a password reset link to your email address. Please click on the link in the email to reset your password.")
                    .setDuration(4500).showSuccessToast();
                isResetDisclosure.onClose()
            } catch (error) {
                toast.setTitle("Error").setDescription("Can't Reset password. Try again").showErrorToast()
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        }
    })

    return (
        <div
            className="animation-form-l3 flex flex-col bg-[#084880] p-8 gap-6 rounded-[2.5rem] mt-8 w-[300px] md:w-[350px]">
            <div className="flex my-2 gap-3">
                <Text className="text-2xl text-white font-bold">Forgot Password?</Text>
                <Icon className="text-2xl !text-white" as={RiShieldKeyholeLine}/>
            </div>
            <form onSubmit={formik.handleSubmit}>
                <div className="flex flex-col gap-6">
                    <div className="flex flex-col gap-4">
                        <FormInput rounded="3xl" backgroundColor="white" isRequired formik={formik} name='user'
                                   placeholder="Email"/>
                    </div>
                    <div className="flex flex-col  gap-4">
                        <Button borderRadius="3xl" isLoading={isLoading} type={'submit'} size="sm"
                                className='!bg-gradient-to-r from-[#0F8DFB] via-[#0B98F8] to-[#07A6F5]
                                    !text-white shadow-lg'>
                            Submit
                        </Button>
                        <Button variant='link' size="sm" maxW="32" className="!text-white mb-2 self-center"
                                onClick={isResetDisclosure.onClose}
                        >
                            Back to edPass Sign In
                        </Button>
                    </div>
                </div>
            </form>
        </div>
    )
}
