import {SimpleGrid} from "@chakra-ui/layout";
import {useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import _ from "lodash";
import {useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {InnerLoading, PageHeader} from "../..";
import {ACTIVITY} from "../../../consts/methods.consts";
import {ACTIVITY_TAGS} from "../../../consts/tags.consts";
import {usePostMutation} from "../../../services/api.service";
import {ToastService} from "../../../services/toast.service";
import {FormDropdown, FormDropdownOptions} from "../../common/form-controllers/FormDropdown";
import {FormInput} from "../../common/form-controllers/FormInput";
import {FormTextArea} from "../../common/form-controllers/FormTextArea";
import {PageHeaderActions} from "../../common/page-header/PageHeader";
import SelectConstructor from "./components/SelectConstructor";
import SelectTherapist from "./components/SelectTherapist";
import SelectStaff from "./components/select-participants/SelecetStaff";
import SelectStudent from "./components/select-participants/SelectStudent";
import moment from "moment";

const subtypes: FormDropdownOptions = [
    {label: "Therapy", value: "Therapy"},
    {label: "Contractor", value: "Contractor"}
]

const consents: FormDropdownOptions = [
    {label: "Not Required", value: 0},
    {label: "Required", value: 1}
]

const NewActivityPage = () => {
    const navigate: NavigateFunction = useNavigate();
    const [create, {isLoading}] = usePostMutation();
    const toast = new ToastService(useToast());
    const [staff, setStaff] = useState<any[]>([]);
    const [students, setStudents] = useState<any[]>([]);
    const [guardians, setGuardians] = useState<any[]>([]);


    const formik = useFormik({
        initialValues: {
            type: "EVENT",
            guardian_consent: 0
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Activity title is required"),
            subtype: Yup.string().required("Please choose a valid type"),
            start_time: Yup.string().required("Start time is required"),
            end_time: Yup.string().required("End time is required"),
            guardian_consent: Yup.string().required("Guardian consent is required")
        }),
        onSubmit: async (values: any) => {
            try {
                let participants = []
                for (let student of students) participants.push({profile_type: "Student", profile: student["id"]})
                for (let stfMember of staff) participants.push({profile_type: "Staff", profile: stfMember["id"]})
                for (let guardian of guardians) participants.push({profile_type: "Guardian", profile: guardian["id"]})

                if (Array.isArray(_.get(values, "therapist")))
                    for (let therapist of _.get(values, "therapist")) participants.push({
                        profile_type: "Therapist",
                        profile: therapist["value"]
                    })

                if (Array.isArray(_.get(values, "contractor")))
                    for (let constructor of _.get(values, "contractor")) participants.push({
                        profile_type: "Contractor",
                        profile: constructor["value"]
                    })

                let model = {
                    ...values,
                    participants,
                    start_time: moment(_.get(values, 'start_time')).format("YYYY-MM-DD H:mm"),
                    end_time: moment(_.get(values, 'end_time')).format("YYYY-MM-DD H:mm")
                }
                //TODO: need a validation of activity type before the submission
                const res = await create({
                    method: ACTIVITY.ADD,
                    body: model,
                    invalidatesTags: [ACTIVITY_TAGS.LIST]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Unable to create activity")
                }

                toast.setTitle("Success").setDescription("Activity created successfully").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/activity/${newId}`, {replace: true});
                }, 750)

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to create activity. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })
    console.log(formik.values, students, staff, guardians);

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isLoading
        },
    ]

    return (
        <>
            <PageHeader enableBackButton isLoading={isLoading} actions={actions} title="Create New Activity"
                        subtitle="Activity"/>

            {
                isLoading ? <InnerLoading/> :
                    <>
                        <div className="border rounded p-3">
                            <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 4}} spacing={3}>
                                <FormInput isRequired label="Title" formik={formik} name="title"/>
                                <FormDropdown options={subtypes} isRequired label="Type" formik={formik}
                                              name="subtype"/>
                                <FormInput isRequired type="datetime-local" label="Start Time" formik={formik}
                                           name="start_time"/>
                                <FormInput isRequired type="datetime-local" label="End Time" formik={formik}
                                           name="end_time"/>
                                <FormTextArea label="Description" formik={formik} name="description"/>
                                {_.get(formik.values, "subtype") === "Therapy" ?
                                    <SelectTherapist isRequired isMulti formik={formik}/> : null}
                                {_.get(formik.values, "subtype") === "Contractor" ?
                                    <SelectConstructor isRequired isMulti formik={formik}/> : null}
                                {_.get(formik.values, "subtype") !== "Contractor" ? <FormDropdown
                                    helperText="If you select 'Consent Required', the system will automatically notify the appropriate people."
                                    options={consents} isRequired label="Guardian Consent?" formik={formik}
                                    name="guardian_consent"/> : null}
                            </SimpleGrid>
                        </div>

                        <SelectStudent onSelect={setStudents}/>

                        <SelectStaff onSelect={setStaff}/>
                    </>
            }

            {/* <SelectGuardian onSelect={setGuardians}/> */}
        </>
    )
}

export default NewActivityPage