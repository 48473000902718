import { Button, Stack, StackProps, ThemeTypings, Text, HStack } from "@chakra-ui/react"
import { FaPlus } from "react-icons/fa"


interface SectionCardProps extends StackProps {
    children: any
    title?: string,
    subTitle?: string,
    creatable?: SectionCardCreatable,
    isError?: boolean,
    errorMessage?: string
}

export type SectionCardCreatable = {
    text?: string | undefined,
    onClick: any,
    isLoading?: boolean,
    isDisabled?: boolean,
    buttonColorScheme?: ThemeTypings["colorSchemes"]
}

const EMPTY_FUNC = () => { }

export const SectionCard = ({ title, children, subTitle, className, isError, errorMessage, creatable, ...rest }: SectionCardProps) => {
    return (
        <Stack borderWidth={isError ? 2 : 1} borderColor={isError ? 'red.400' : undefined} gap={0} className={`border rounded mt-3 bg-slate-50 p-3 transition-all cursor-pointer ${className}`} {...rest}>
            <div className="flex justify-between items-center">
                <div className="flex flex-col">
                    {title && <p className="font-semibold !p-0 !m-0">{title}</p>}
                    {subTitle && <p className="font-normal text-[14px] !p-0 !m-0 mb-2">{subTitle}</p>}
                </div>

                <Button size={'sm'} aria-label="Add new" isDisabled={creatable?.isLoading || creatable?.isDisabled} onClick={creatable?.onClick || EMPTY_FUNC} colorScheme={creatable?.buttonColorScheme || 'teal'} leftIcon={<FaPlus />}>
                    <HStack alignItems={"center"}>
                        <Text>{creatable?.text || 'New'}</Text>
                    </HStack>
                </Button>
            </div>
            <div className="mt-2">
                {children}
            </div>
            {isError && errorMessage && <p className="text-sm mt-2 text-red-600">{errorMessage}</p>}
        </Stack>
    )
}