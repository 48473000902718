import { SimpleGrid } from "@chakra-ui/react";
import { StatCard } from "../..";
import { REPORTS } from "../../../consts/methods.consts";
import { ACTIVITY_TAGS } from "../../../consts/tags.consts";
import AttendanceToday from "./components/AttendanceToday";
import MonthOverview from "./components/MonthOverview";
import SignedOutResources from "./components/SignedOutResources";

const HomePage = () => {
	return (
		<div>
			<SimpleGrid columns={{ base: 1, md: 3 }} gap={{ base: '4', md: '4' }}>
				<StatCard method={REPORTS.TODAY_VISITOR_COUNT} providesTags={[ACTIVITY_TAGS.LIST]} label="Visitors"
					className="bg-[#063c6a] text-white" textStyle="text-white" />
				<StatCard method={REPORTS.TODAY_STUDENT_COUNT} providesTags={[ACTIVITY_TAGS.LIST]} label="Students"
					className="bg-[#008aff] text-white" textStyle="text-white" />
				<StatCard method={REPORTS.TODAY_STAFF_COUNT} providesTags={[ACTIVITY_TAGS.LIST]} label="Staff"
					className="bg-[#31b8fc] text-white" textStyle="text-white" />
			</SimpleGrid>

			<MonthOverview />

			<SimpleGrid columns={{ base: 1, md: 2 }} gap={{ base: '4', md: '4' }} className="min-h-[32vh]">
				<AttendanceToday />
				<SignedOutResources />
			</SimpleGrid>
		</div>
	)
}

export default HomePage;