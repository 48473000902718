import { useToast } from "@chakra-ui/react"
import { useFormik } from "formik"
import * as Yup from 'yup'
import { ACTIVITY } from "../../../../consts/methods.consts"
import { ACTIVITY_TAGS } from "../../../../consts/tags.consts"
import { usePostMutation } from "../../../../services/api.service"
import { ToastService } from "../../../../services/toast.service"
import ChakraModal from "../../../common/chakra-modal/ChakraModal"
import { FormTextArea } from "../../../common/form-controllers/FormTextArea"


interface IActivityCloseModal {
    id: string | undefined
    disclosure: any
}

const ActivityCloseModal = ({ id, disclosure }: IActivityCloseModal) => {
    const toast = new ToastService(useToast())
    const [create, {isLoading}] = usePostMutation();

    const formik = useFormik({
        initialValues: {
            _id: id,
            status: "Canceled"
        },
        validationSchema: Yup.object({
        }),
        onSubmit: async (values) => {
            try {
                await create({
                    method: ACTIVITY.UPDATE,
                    body: {
                        ...values
                    },
                    invalidatesTags: [ACTIVITY_TAGS.VIEW, ACTIVITY_TAGS.LIST]
                }).unwrap();
                formik.resetForm()
                toast.setTitle("Success").setDescription("Activity has been cancel").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to cancel. Please try again").showErrorToast();
                console.error(error);
            } finally {
                disclosure.onClose();
            }
        }
    })

    return (
        <ChakraModal
            primaryAction={formik.submitForm}
            primaryButtonText={"Submit"}
            modalTitle={"Cancel Activity"}
            {...disclosure}
            isLoading={isLoading}
        >
            <FormTextArea formik={formik} name={"remarks"} label={"Remarks"} />
        </ChakraModal>
    )
}

export default ActivityCloseModal