import {SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import _ from "lodash";
import {useEffect, useRef, useState} from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {useParams, useSearchParams} from "react-router-dom";
import * as Yup from "yup";
import {InnerLoading, ProfileAvatar} from "../..";
import {SCHOOL, STUDENT, VALIDATION} from "../../../consts/methods.consts";
import {FILTER_TAGS, STUDENT_TAGS} from "../../../consts/tags.consts";
import {useGetQuery, usePostMutation} from "../../../services/api.service";
import {ToastService} from "../../../services/toast.service";
import ActivityTab from "../../common/activity-tab/ActivityTab";
import ActivityCalendarTab from "../../common/calendar-tab/ActivityCalendarTab";
import ContactsTab from "../../common/contacts-tab/ContactsTab";
import {FormDropdown, FormDropdownOptions} from "../../common/form-controllers/FormDropdown";
import {FormInput} from "../../common/form-controllers/FormInput";
import FormSelect from "../../common/form-controllers/FormSelect";
import {FormTextArea} from "../../common/form-controllers/FormTextArea";
import PageHeader, {PageHeaderActions} from "../../common/page-header/PageHeader";
import ResourceTab from "../../common/resource-tab/ResourceTab";
import StationSignInModal from "../../common/station-signin-modal/StationSignInModal";
import StationSignOutModal from "../../common/station-signout-modal/StationSignOutModal";
import {useObjChange} from "../../hooks";
import SelectGuardian from "./components/SelectGuardian";
import axios from "axios";
import {BASE_URL, PHONE_REG_EXP} from "../../../consts/app.consts";

const gender: FormDropdownOptions = [
    {label: "Male", value: "Male"},
    {label: "Female", value: "Female"}
]

const titles: FormDropdownOptions = [
    {label: "Mr", value: "Mr"},
    {label: "Ms", value: "Ms"},
    {label: "Miss", value: "Miss"},
    {label: "Mrs", value: "Mrs"},
    {label: "Mx", value: "Mx"}
]

const yesNo: FormDropdownOptions = [
    {label: "Yes", value: 1},
    {label: "No", value: 0}
]

const statusOptions: FormDropdownOptions = [
    {label: "Active", value: "Active"},
    {label: "Inactive", value: "Inactive"},
    {label: "Future", value: "Future"},
    {label: "Left", value: "Left"},
    {label: "Leaving", value: "Leaving"}
]

const colorSchemaMapping: any = {
    'Inactive': 'red',
    'Active': 'green',
    'Future': "cyan",
    'Left': "orange",
    'Leaving': "yellow"
}

const ViewStudentPage = () => {
    const [searchParams] = useSearchParams();
    const toast = new ToastService(useToast());
    const signInDisclosure = useDisclosure();
    const signOutDisclosure = useDisclosure();
    const [studentIdQ, setStudentIdQ] = useState(null)
    const [isDownloading, setIsDownloading] = useState<boolean>(false)
    const tabIndexRef = useRef<number>(0)
    const [tabIndex, setTabIndex] = useState<number>(tabIndexRef.current)
    const {id} = useParams()
    const [create, {isLoading: isMutating}] = usePostMutation();

    const {data: _data, isLoading, isFetching} = useGetQuery({
        method: STUDENT.GET,
        body: {
            id,
            page: Number(searchParams.get("page"))
        },
        providesTags: [STUDENT_TAGS.VIEW]
    })

    const {data: _validationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_STUDENT_ID_UPDATE,
        body: {
            _id: id,
            student_id: studentIdQ
        },
        providesTags: [STUDENT_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {})
    const studentId = _.get(data, 'student_id')
    const studentName = `${_.get(data, "first_name")} ${_.get(data, "middle_name") ?? ''} ${_.get(data, 'last_name')}`
    const profileId = _.get(data, 'profile_id')
    const status = _.get(data, 'status')

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            student_id: Yup.string().required("Student ID is required"),
            title: Yup.string().required("Title is required"),
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            class: Yup.string().required("Class is required"),
            gender: Yup.string().required("Gender is required"),
            email: Yup.string().email("Invalid email address").nullable(),
            birthday: Yup.string().required("Birth day is required"),
            disabled_student: Yup.number().required("Please select an option for 'Disabled Student'."),
            mobile: Yup.string().matches(PHONE_REG_EXP, 'Mobile number is not valid').nullable(),
            phone: Yup.string().matches(PHONE_REG_EXP, 'Phone number is not valid').nullable()
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: STUDENT.UPDATE,
                    body: {
                        ...values,
                        medical_emergency_contact: _.get(values, ['medical_emergency_contact', 'value'])
                    },
                    invalidatesTags: [STUDENT_TAGS.VIEW, STUDENT_TAGS.LIST, FILTER_TAGS.STUDENT_SELECTOR]
                }).unwrap();

                toast.setTitle("Success").setDescription("Student data updated").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to update student data. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const {isChanged, setInitialObj} = useObjChange(formik.values)

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [JSON.stringify(data)])

    useEffect(() => {
        studentIdQHandler(_.get(formik.values, "student_id"))
    }, [_.get(formik.values, "student_id")])

    const studentIdQHandler = _.debounce((value: any) => {
        setStudentIdQ(value)
    }, 200)

    const downloadQRCode = async () => {
        setIsDownloading(true)
        axios.post(`${BASE_URL}/method/${STUDENT.DOWNLOAD_QR_CODE}`, {_id: id}, {
            withCredentials: true,
            responseType: "blob"
        })
            .then((response) => {
                console.log(response)
                const href = URL.createObjectURL(response.data);

                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', `${studentId}.png`);
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
                URL.revokeObjectURL(href);
                toast.setTitle("Success").setDescription("QR Download Successful").showSuccessToast();
            })
            .catch((e) => {
                toast.setTitle("Error").setDescription("Unable to download QR. Please try again").showErrorToast();
            }).finally(() => {
            setIsDownloading(false)
        })
    }

    const handleTabsChange = (index: number) => {
        tabIndexRef.current = index
        setTabIndex(index)
    }

    const actions: PageHeaderActions = [
        {
            text: "Download QR",
            onClick: downloadQRCode,
            buttonVariant: "outline",
            buttonColorScheme: "messenger",
            isLoading: isDownloading
        },
        {
            text: "Sign in",
            onClick: () => signInDisclosure.onOpen(),
            buttonVariant: "outline",
            buttonColorScheme: "red"
        },
        {
            text: "Sign out",
            onClick: () => signOutDisclosure.onOpen(),
            buttonVariant: "outline",
            buttonColorScheme: "whatsapp"
        },
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "messenger",
            isLoading: isMutating
        }
    ]

    if (isLoading || isFetching) {
        return (
            <>
                <PageHeader
                    isLoading={true}
                    enableBackButton
                    actions={actions}
                    title={studentName}
                    subtitle={studentId}/>
                <InnerLoading/>
            </>
        )
    }

    const studentIDCustomError = (_validationData?.message > 0) && (
        <p className=" text-sm text-red-500">Student ID already taken.</p>
    )

    return (
        <div>
            <PageHeader
                status={status}
                statusColorSchemes={colorSchemaMapping[status]}
                isLoading={isLoading}
                enableBackButton
                actions={actions}
                title={studentName}
                subtitle={studentId}/>
            <StationSignInModal id={id} title={"Sign in student"} type={"student"} disclosure={signInDisclosure}/>
            <StationSignOutModal id={id} title={"Sign out student"} type={"student"} disclosure={signOutDisclosure}/>
            <Tabs className="mt-3" index={tabIndex} onChange={handleTabsChange}>
                <TabList>
                    <Tab>General Information</Tab>
                    <Tab>Contacts</Tab>
                    <Tab>Activities</Tab>
                    <Tab>Resources</Tab>
                    <Tab>Calendar</Tab>
                </TabList>

                <TabPanels className="animation-form">
                    <TabPanel className="!p-0 !pt-3">
                        <div className="info-container">
                            <div className="form-wrapper">
                                {/* <LiveFilterInput isRequired label="Select User" data={[{"text": "Rajika"}]} accessor={"text"} isLoading={false} onSelect={console.log} /> */}
                                <FormInput customMessage={studentIDCustomError} isRequired label="Student Id"
                                           formik={formik} name="student_id"/>
                                <FormDropdown options={titles} isRequired label="Title" formik={formik} name="title"/>
                                <FormInput isRequired label="First Name" formik={formik} name="first_name"/>
                                <FormInput label="Middle Name" formik={formik} name="middle_name"/>
                                <FormInput isRequired label="Last Name" formik={formik} name="last_name"/>

                                <FormDropdown options={gender} isRequired label="Gender" formik={formik} name="gender"/>
                                <FormInput isRequired type="date" label="Date of Birth" formik={formik}
                                           name="birthday"/>
                                <FormInput type="tel" label="Email" formik={formik} name="email"/>
                                <FormInput placeholder="0XXXXXXXXX" type="tel" label="Mobile" formik={formik}
                                           name="mobile"/>
                                <FormInput placeholder="0XXXXXXXXX" type="tel" label="Phone" formik={formik}
                                           name="phone"/>

                                <FormTextArea label="Residential Address" formik={formik} name="address"/>

                                <FormDropdown options={statusOptions} label="Status" formik={formik} name="status"/>
                            </div>
                            <div className="profile-wrapper">
                                <ProfileAvatar placeholder="Student Name" doctype="Internal Profile"
                                               docname={profileId}/>
                            </div>
                        </div>
                        <div className="animation-form border rounded p-3 mt-3">
                            <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 3}} spacing={3}>
                                <FormSelect method={SCHOOL.GET_CLASS_LIST_FOR_DROPDOWN} isRequired label="Class"
                                            formik={formik} name="class"/>
                            </SimpleGrid>
                        </div>
                        <div className="animation-form border rounded p-3 mt-3">
                            <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 3}} spacing={3}>
                                <FormTextArea label="Activity Restrictions" formik={formik}
                                              name="activity_restrictions"/>
                                <FormTextArea label="Medical Condition" formik={formik} name="medical_condition"/>
                                <FormTextArea label="Legal Restrictions" formik={formik} name="legal_restrictions"/>
                                <SelectGuardian formik={formik}/>
                                <FormDropdown options={yesNo} isRequired label="Disabled Student" formik={formik}
                                              name="disabled_student"/>
                            </SimpleGrid>
                        </div>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ContactsTab id={id}/>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ActivityTab id={id} type={"Student"} customFilter={true}/>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ResourceTab id={id} type={"student"}/>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ActivityCalendarTab id={id} type="Student"/>
                    </TabPanel>
                </TabPanels>
            </Tabs>

            {/* <FormTester formik={formik} /> */}
        </div>
    )
}

export default ViewStudentPage

function setState(arg0: null): [any, any] {
    throw new Error("Function not implemented.");
}
