import _ from "lodash";
import moment from "moment";
import * as Yup from "yup";
import FormSelect from "../form-controllers/FormSelect";
import {useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import {ChakraModal} from "../..";
import {ToastService} from "../../../services/toast.service";
import {FormTextArea} from "../form-controllers/FormTextArea";
import {ACTIVITY_TAGS} from "../../../consts/tags.consts";
import {ACTIVITY_TYPES} from "../../../consts/types.consts";
import {usePostMutation} from "../../../services/api.service";
import {ACTIVITY, SCHOOL} from "../../../consts/methods.consts";

interface ISignInModal {
    id: string | undefined
    title: string
    type: "student" | "staff"
    disclosure: any
    toastDescription?: string
}

const profileMap: any = {
    "student": "Student",
    "staff": "Staff"
}

const StationSignOutModal = ({id, type, disclosure, title, toastDescription}: ISignInModal) => {
    const toast = new ToastService(useToast())
    const [create, {isLoading}] = usePostMutation();

    const formik = useFormik({
        initialValues: {
            title,
            type: ACTIVITY_TYPES.STATION,
            station: ''
        },
        validationSchema: Yup.object({
            station: Yup.string().required("Station is required"),
            description: Yup.string()
        }),
        onSubmit: async (values) => {
            try {
                await create({
                    method: ACTIVITY.ADD,
                    body: {
                        ...values,
                        start_time: moment().format("YYYY-MM-DD H:mm"),
                        participants: [
                            {
                                profile_type: profileMap[type],
                                profile: id,
                                sign_out: 1,
                                sign_out_station: _.get(values, "station"),
                                sign_out_time: moment().format("YYYY-MM-DD H:mm")
                            }
                        ]
                    },
                    invalidatesTags: [ACTIVITY_TAGS.LIST]
                }).unwrap();
                formik.resetForm()
                toast.setTitle("Success").setDescription(toastDescription ?? "Sign out to the Station").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to sign out. Please try again").showErrorToast();
                console.error(error);
            } finally {
                disclosure.onClose();
            }
        }
    })

    return (
        <ChakraModal
            primaryAction={formik.submitForm}
            primaryButtonText={"Sign out"}
            modalTitle={title}
            {...disclosure}
            isLoading={isLoading}
        >
            <FormSelect
                method={SCHOOL.GET_STATION_LIST_FOR_DROPDOWN}
                isRequired label="Station"
                formik={formik}
                name="station"/>
            <FormTextArea formik={formik} name={"description"} label={"Reason"}/>
        </ChakraModal>
    )
}

export default StationSignOutModal