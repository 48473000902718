import { useFormik } from "formik"
import _ from "lodash"
import { useEffect, useState } from "react"
import { REPORTS } from "../../../../consts/methods.consts"
import { ACTIVITY_TAGS } from "../../../../consts/tags.consts"
import { useGetQuery } from "../../../../services/api.service"
import ChakraTable, { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable"
import { useTableDataProvider } from "../../../hooks"
import { useToast } from "@chakra-ui/react"
import { ToastService } from "../../../../services/toast.service"
import { IOpenPdf, openPdf } from "../../../../utils/utils"

const TimeSheetDataTable = () => {
    const toast = new ToastService(useToast());
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)
    const [length, setLength] = useState(15)



    const { data, isLoading } = useGetQuery({
        method: REPORTS.STAFF_TIME_SHEET,
        body: {
            page,
            length,
            ...filters
        },
        providesTags: [ACTIVITY_TAGS.LIST]
    })

    const { pageCount, dataList, totalResult } = useTableDataProvider(data)


    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({ ...filters, ...value })
    }, 200)

    const onPrint = async (columns: any, data: any) => {
        const config: IOpenPdf = {
            tableData: {
                columns,
                data,
                filters,
                info: {
                    title: "Time sheet"
                }
            },
            toast
        }

        openPdf(config);
    }

    return (
        <>
            <ChakraTable
                size={"sm"}
                header title="Time Sheet Data"
                onSearch={onSearchHandler}
                isLoading={isLoading}
                columns={LIST_TAB_COLUMNS}
                pageCount={pageCount}
                pagination
                lengthPaginations
                onLength={setLength}
                onPrint={onPrint}
                onPage={setPage}
                totalResult={totalResult}
                data={dataList} />
        </>
    )
}

const LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        accessor: "full_name",
        header: "Name"
    },
    {
        accessor: "mon",
        header: "MON",
        customRenderer(value, item) {
            return cellMarkup(value)
        },
        headerOptions: {
            className: "justify-center"
        }
    },
    {
        accessor: "tus",
        header: "TUS",
        customRenderer(value, item) {
            return cellMarkup(value)
        },
        headerOptions: {
            className: "justify-center"
        }
    },
    {
        accessor: "wed",
        header: "WED",
        customRenderer(value, item) {
            return cellMarkup(value)
        },
        headerOptions: {
            className: "justify-center"
        }
    },
    {
        accessor: "tur",
        header: "TUR",
        customRenderer(value, item) {
            return cellMarkup(value)
        },
        headerOptions: {
            className: "justify-center"
        }
    },
    {
        accessor: "fri",
        header: "FRI",
        customRenderer(value, item) {
            return cellMarkup(value)
        },
        headerOptions: {
            className: "justify-center"
        }
    },
    {
        accessor: "sat",
        header: "SAT",
        customRenderer(value, item) {
            return cellMarkup(value)
        },
        headerOptions: {
            className: "justify-center"
        }
    },
    {
        accessor: "sun",
        header: "SUN",
        customRenderer(value, item) {
            return cellMarkup(value)
        },
        headerOptions: {
            className: "justify-center"
        }
    },
    {
        accessor: "total",
        header: "Total"
    }
]

const colorGenerator = (value: any) => {
    if (value >= 0 && value <= 1) {
        return "timesheet-1h";
    }

    if (value > 1 && value <= 2) {
        return "timesheet-2h";
    }

    if (value > 2 && value <= 3) {
        return "timesheet-3h";
    }

    if (value > 3 && value <= 4) {
        return "timesheet-4h";
    }

    if (value > 4 && value <= 5) {
        return "timesheet-5h text-white";
    }

    if (value > 5 && value <= 6) {
        return "timesheet-6h text-white";
    }

    if (value > 6 && value <= 7) {
        return "timesheet-7h text-white";
    }

    if (value > 7 && value <= 8) {
        return "timesheet-8h text-white";
    }
}

const cellMarkup = (value: any) => {
    return <div
        className={`aspect-{1/2} h-14 flex items-center justify-center text-gray-700 rounded text-md ${colorGenerator(value)}`}>{value}</div>
}

export default TimeSheetDataTable