import {SimpleGrid} from "@chakra-ui/layout";
import {Link, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import * as Yup from "yup";
import {GROUP} from "../../../consts/methods.consts";
import {GROUP_TAGS} from "../../../consts/tags.consts";
import {useGetQuery, usePostMutation} from "../../../services/api.service";
import {ToastService} from "../../../services/toast.service";
import {frappeExceptionFormatter} from "../../../utils/utils";
import ChakraTable, {ChakraTableColumns} from "../../common/chakra-table/ChakraTable";
import {FormCheckBox} from "../../common/form-controllers/FormCheckBox";
import {FormInput} from "../../common/form-controllers/FormInput";
import InnerLoading from "../../common/inner-loading/InnterLoading";
import PageHeader, {PageHeaderActions} from "../../common/page-header/PageHeader";
import {SectionCard, SectionCardCreatable} from "../../common/section-card/SectionCard";
import UserSelector from "../../common/user-selector/UserSelector";
import {useObjChange, useStatusProvider} from "../../hooks";
import GroupSingInModal from "./components/GourpSignIn";
import GroupSingOutModal from "./components/GourpSignOut";
import SignInOut from "../../common/activity-tab/components/SignInOut";
import GroupActivityTab from "./components/GroupActivityTab";


const ViewGroupPage = () => {
    const {id} = useParams()
    const [create, {isLoading: isMutating}] = usePostMutation();
    const toast = new ToastService(useToast());
    const [participant, setParticipant] = useState<any>()

    const _disclosure = useDisclosure();
    const memberDisclosure = useDisclosure()
    const signInDisclosure = useDisclosure()
    const signOutDisclosure = useDisclosure()

    const {data: _data, isLoading, isFetching} = useGetQuery({
        method: GROUP.GET,
        body: {id},
        providesTags: [GROUP_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {});
    const memberList = _.get(data, ['members'], [])
    const description = _.get(data, "description")

    const [members, setMembers] = useState<any>([])
    const [isError, SetIsError] = useState<boolean>(false)

    const formik = useFormik({
        initialValues: {
            active: true
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Group title required")
        }),
        onSubmit: async (values: any) => {
            try {
                const {members, ...body} = values

                await create({
                    method: GROUP.UPDATE,
                    body,
                    invalidatesTags: [GROUP_TAGS.LIST, GROUP_TAGS.VIEW]
                }).unwrap();

                toast.setTitle("Success").setDescription("Group updated").showSuccessToast();

            } catch (error: any) {
                toast.setTitle("Error").setDescription(frappeExceptionFormatter(error || {})).showErrorToast();
                console.error(error);
            }
        }
    })

    const {isChanged, setInitialObj} = useObjChange(formik.values)
    const statusConfig = useStatusProvider(data);

    useEffect(() => {
        if (isLoading) return;

        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
            setMembers(memberList)
        }
    }, [JSON.stringify(data)])

    const actions: PageHeaderActions = [
        {
            text: "Sign in",
            onClick: signInDisclosure.onOpen,
            buttonVariant: "outline",
            buttonColorScheme: "red"
        },
        {
            text: "Sign out",
            onClick: signOutDisclosure.onOpen,
            buttonVariant: "outline",
            buttonColorScheme: "whatsapp"
        },
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isDisabled: !isChanged,
            isLoading: isMutating
        },
    ]

    const creatable: SectionCardCreatable = {
        text: "Browse",
        onClick: () => {
            memberDisclosure.onOpen();
        }
    }

    const removeMember = async (item: object) => {
        try {
            await create({
                method: GROUP.REMOVE_MEMBER,
                body: item,
                invalidatesTags: [GROUP_TAGS.VIEW]
            }).unwrap();

            toast.setTitle("Success").setDescription("Member removed").showSuccessToast();

        } catch (error: any) {
            toast.setTitle("Error").setDescription('Unable to remove member').showErrorToast();
            console.error(error);
        }
    }

    const addMembers = async (newMembers: Array<any>, title: string) => {
        try {
            await create({
                method: GROUP.ADD_MEMBER,
                body: {
                    members: newMembers,
                    title,
                },
                invalidatesTags: [GROUP_TAGS.VIEW]
            }).unwrap();

            toast.setTitle("Success").setDescription("Group members updated").showSuccessToast();

        } catch (error: any) {
            toast.setTitle("Error").setDescription('Unable to update group member').showErrorToast();
            console.error(error);
        }
    }

    const resendQr = async (id: string, email: string, full_name: string = '') => {
        try {
            await create({
                method: GROUP.RESEND_QR_FOR_STAFF,
                body: {
                    profile: id,
                    email: email,
                    full_name: full_name
                },
                invalidatesTags: []
            }).unwrap();

            toast.setTitle("Success").setDescription("The group access QR code has been successfully resent").showSuccessToast();

        } catch (error: any) {
            toast.setTitle("Error").setDescription('Unable to resend group access QR').showErrorToast();
            console.error(error);
        }
    }

    const MEMBER_COLUMNS: ChakraTableColumns = [
        {
            header: "User Id",
            accessor: "__id",
            customRenderer: (value, item) => {
                return tblUserIdCellMarkup(value, item)
            }
        },
        {
            header: "First Name",
            accessor: "first_name",
        },
        {
            header: "Last Name",
            accessor: "last_name",
        },
        {
            header: "Gender",
            accessor: "gender",
        },
        {
            header: "User Type",
            accessor: "__type",
            conditions: {
                'Non Academic Staff': 'purple',
                'Academic Staff': 'messenger',
                'Student': 'pink',
                'Guest': 'red',
                'Guardian': 'whatsapp',
                'Contractor': 'yellow',
                'Plumber': 'linkedin',
                'Therapist': 'cyan',
                'Volunteer': 'blue',
                'Tour': 'green',
                'Support Workers': 'facebook',
                'Bank': 'orange',
                'Analyst': 'facebook',
            }
        },
        {
            accessor: "sign_in",
            header: "Sign In",
            conditions: {
                'No': 'red',
                'Yes': 'green'
            }
        },
        {
            accessor: "sign_in_time",
            header: "Sign In Time"
        },
        {
            accessor: "sign_out",
            header: "Sign Out",
            conditions: {
                'No': 'red',
                'Yes': 'green'
            }
        },
        {
            accessor: "sign_out_time",
            header: "Sign Out Time"
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            options: [
                {
                    label: "Sign in/out",
                    onClick(item) {
                        setParticipant({...item, description})
                        _disclosure.onOpen()
                    },
                },
                {
                    label: "Remove",
                    onClick(item: any) {
                        removeMember({id: item.id, title: _.get(data, 'title')});
                    },
                },
                {
                    label: "Resend QR",
                    conditionalDisable(item: any) {
                        const type = _.get(item, '__type', '')

                        if (type == "Academic Staff" || type == "Non Academic Staff") {
                            return true;
                        }

                        return false;
                    },
                    onClick(item: any) {
                        resendQr(id as string, item?.email, item?.full_name)
                    }
                }
            ]
        }
    ]

    useEffect(() => {
        if (!_.isEmpty(members)) {
            SetIsError(false)

            // Check new members => __synced key with falsy values or without __synced keys
            const newMembers = members.filter((member: any) => !member?.__synced)

            if (!_.isEmpty(newMembers)) {
                addMembers(newMembers, _.get(data, 'title'));
            }
        }
    }, [JSON.stringify(members)])

    if (isLoading || isFetching) {
        return (
            <>
                <PageHeader isLoading={true} enableBackButton actions={actions} title={""} subtitle={""}/>
                <InnerLoading/>
            </>
        )
    }

    return (
        <>
            <PageHeader {...statusConfig} isLoading={isLoading} enableBackButton actions={actions}
                        title={_.get(data, 'title')} subtitle="Group"/>
            <GroupSingInModal id={id} disclosure={signInDisclosure} description={description}/>
            <GroupSingOutModal id={id} disclosure={signOutDisclosure} description={description}/>
            <SignInOut disclosure={_disclosure} data={participant}/>
            <UserSelector selectedItems={members} setSelectedItems={setMembers} {...memberDisclosure} />
            <Tabs className="mt-3">
                <TabList>
                    <Tab>General Information</Tab>
                    <Tab>Activities</Tab>
                </TabList>

                <TabPanels className="animation-form">
                    <TabPanel className="!p-0 !pt-3">
                        <div className="border rounded p-3 animation-form">
                            <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 4}} spacing={3}>
                                <FormInput autoComplete="off" isRequired label="Group Name" formik={formik}
                                           name="title"/>
                                <FormCheckBox label="Active" formik={formik} name="active"/>
                            </SimpleGrid>
                        </div>

                        <SectionCard className="animation-form-l2" isError={isError}
                                     errorMessage="Please add group members" creatable={creatable}
                                     subTitle="Select group members related to the group" title="Members" p={3}>
                            <ChakraTable isLoading={false} size={"sm"}
                                         title="Members" columns={MEMBER_COLUMNS} data={memberList || []}/>
                        </SectionCard>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <GroupActivityTab id={id}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}

const tblUserIdCellMarkup = (value: any, item: any) => {
    let path = ''

    if (item.__type === 'Contractor') path = 'contractors'
    if (item.__type === 'Plumber') path = 'contractors'
    if (item.__type === 'Mechanical') path = 'contractors'
    if (item.__type === 'Therapist') path = 'Therapists'
    if (item.__type === 'Guardian') path = 'guardians'
    if (item.__type === 'Analyst') path = 'guests'
    if (item.__type === 'Volunteer') path = 'guests'
    if (item.__type === 'Tour') path = 'guests'
    if (item.__type === 'Bank') path = 'guests'
    if (item.__type === 'Support Workers') path = 'guests'
    if (item.__type === 'Gardeners') path = 'guests'
    if (item.__type === 'Non Academic Staff') path = 'staff'
    if (item.__type === 'Academic Staff') path = 'staff'
    if (item.__type === 'Student') path = 'students'

    return (
        <Link color="#0073E5" href={`/app/${path}/${item.id}`}
              isExternal={true}>
            {value}
        </Link>
    )
}

export default ViewGroupPage;