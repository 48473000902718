import { Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure } from "@chakra-ui/react"
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader"
import WWCCReportTab from "./components/WwccTab"
import VITReportTab from "./components/VitTab"
import NewComplianceModal from "./components/NewComplianceModal"

const VisitorCompliancePage = () => {

    const _disclosure = useDisclosure();

    const actions: PageHeaderActions = [
        {
            text: "Create New",
            isDisabled: false,
            onClick: _disclosure.onOpen,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
    ]

    return (
        <>
            <PageHeader actions={actions} subtitle="View visitor compliance" title={`Visitor Compliance Report`} />
            <NewComplianceModal disclosure={_disclosure} />
            <Tabs className="mt-3">
                <TabList>
                    <Tab>WWCC</Tab>
                    <Tab>VIT</Tab>
                </TabList>

                <TabPanels className="animation-form">
                    <TabPanel className="!p-0 !pt-3">
                        <WWCCReportTab />
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <VITReportTab />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}

export default VisitorCompliancePage