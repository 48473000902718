import {SCHOOL} from "../../../../consts/methods.consts";
import {DISCLAIMER_TAGS} from "../../../../consts/tags.consts";
import {useGetQuery} from "../../../../services/api.service";
import ChakraTable from "../../../common/chakra-table/ChakraTable";
import {useTableDataProvider} from "../../../hooks";
import {DISCLAIMER_LIST_TAB_COLUMNS} from "../../disclaimer/components/DisclaimerListDataTable";


interface DisclaimerListProps {
    type: string | Array<String>
}

const DisclaimerList = ({ type }: DisclaimerListProps) => {
    const { data, isLoading } = useGetQuery({
        method: SCHOOL.GET_DISCLAIMER_LIST_BY_USER_TYPE,
        body: { type },
        providesTags: [DISCLAIMER_TAGS.LIST]
    })

    const { dataList } = useTableDataProvider(data, { withoutPagination: true });

    return (
        <div className="mt-3 animation-form-l3">
            <ChakraTable header isLoading={isLoading} size={"sm"} title="Policies" columns={DISCLAIMER_LIST_TAB_COLUMNS} data={dataList} />
        </div>
    )
}

export default DisclaimerList;