import {SimpleGrid} from "@chakra-ui/layout";
import {Link, Select, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import _ from "lodash";
import React, {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import * as Yup from "yup";
import {ChakraTable, InnerLoading, InstructionBox, SectionCard} from "../../..";
import {GUARDIAN, VALIDATION} from "../../../../consts/methods.consts";
import {FILTER_TAGS, GUARDIAN_TAGS} from "../../../../consts/tags.consts";
import {useGetQuery, usePostMutation} from "../../../../services/api.service";
import {ToastService} from "../../../../services/toast.service";
import {ChakraTableColumns} from "../../../common/chakra-table/ChakraTable";
import {FormCheckBox} from "../../../common/form-controllers/FormCheckBox";
import {FormDropdown, FormDropdownOptions} from "../../../common/form-controllers/FormDropdown";
import {FormInput} from "../../../common/form-controllers/FormInput";
import PageHeader, {PageHeaderActions} from "../../../common/page-header/PageHeader";
import {SectionCardCreatable} from "../../../common/section-card/SectionCard";
import StudentSelector from "../../../common/student-selector/StudentSelector";
import {useObjChange, useStatusProvider} from "../../../hooks";
import DisclaimerListWithConcent from "../components/DisclaimerListWithConcent";
import {FormTextArea} from "../../../common/form-controllers/FormTextArea";
import ExtendUserModal from "../components/ExtendUserModal";
import ActivityTab from "../../../common/activity-tab/ActivityTab";
import {PHONE_REG_EXP} from "../../../../consts/app.consts";

const gender: FormDropdownOptions = [
    {label: "Male", value: "Male"},
    {label: "Female", value: "Female"}
]
// test build

export const relationship: FormDropdownOptions = [
    {label: "Parent", value: "Parent"},
    {label: "Adoptive Parent", value: "Adoptive Parent"},
    {label: "Friend", value: "Friend"},
    {label: "Step Parent", value: "Step-parent"},
    {label: "Self", value: "Self"},
    {label: "Relative", value: "Relative"},
    {label: "Grandparent", value: "Grandparent"},
    {label: "Foster parent", value: "Foster parent"},
    {label: "Guardian", value: "Guardian"},
    {label: "Case Worker", value: "Case Worker"},
    {label: "Host Family", value: "Host Family"}
]

const extend_types: FormDropdownOptions = [
    {label: "Contractor", value: "Contractor"},
    {label: "Therapist", value: "Therapist"}
]

const titles: FormDropdownOptions = [
    {label: "Mr", value: "Mr"},
    {label: "Ms", value: "Ms"},
    {label: "Miss", value: "Miss"},
    {label: "Mrs", value: "Mrs"},
    {label: "Mx", value: "Mx"}
]

const ViewGuardianPage = () => {
    const toast = new ToastService(useToast());
    const {id} = useParams()
    const [create, {isLoading: isMutating}] = usePostMutation();
    const [guardianEmailQ, setGuardianEmailQ] = useState(null)

    const {data: _data, isLoading, isFetching} = useGetQuery({
        method: GUARDIAN.GET,
        body: {id},
        providesTags: [GUARDIAN_TAGS.VIEW]
    })

    const {data: _validationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_GUARDIAN_EMAIL_UPDATE,
        body: {
            _id: id,
            email: guardianEmailQ,
        },
        providesTags: [GUARDIAN_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {});
    const studentList = _.get(data, ['students'], [])
    const guardianName = `${data?.first_name || ''} ${data?.last_name || ''}`
    const mobile_registered = _.get(data, "mobile_registered")

    const childrenDisclosure = useDisclosure()
    const extendDisclosure = useDisclosure()

    const [children, setChildren] = useState<any>([])
    const [isError, SetIsError] = useState<boolean>(false)

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            gender: Yup.string().required("Gender is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            mobile: Yup.string().matches(PHONE_REG_EXP, 'Mobile number is not valid')
                .required('Mobile number is required')
        }),
        onSubmit: async (values: any) => {
            try {
                SetIsError(false);

                if (_.isEmpty(children)) {
                    SetIsError(true);
                    throw new Error("Please add a child");
                }

                const res = await create({
                    method: GUARDIAN.UPDATE,
                    body: {
                        _id: id,
                        ...values,
                        students: children.map((child: any) => {
                            return {student: child.id, relationship: child?.relationship || 'Guardian'}
                        })
                    },
                    invalidatesTags: [GUARDIAN_TAGS.VIEW, GUARDIAN_TAGS.LIST, FILTER_TAGS.GUARDIAN_SELECTOR]
                }).unwrap();

                const newId = _.get(res, 'message')
                if (!newId) {
                    throw new Error("Unable to register guardian")
                }

                toast.setTitle("Success").setDescription("Guardian updated").showSuccessToast();
            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to update guardian. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const {isChanged, setInitialObj} = useObjChange({0: formik.values, 1: children})
    const statusConfig = useStatusProvider(data);
    const isDisabled = statusConfig.status == 'InActive'

    useEffect(() => {
        if (isLoading) return;

        setChildren(studentList)

        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj({0: data, 1: studentList})
        }
    }, [JSON.stringify(data), JSON.stringify(studentList)])

    const actions: PageHeaderActions = [
        {
            text: "Extend User",
            onClick: extendDisclosure.onOpen,
            buttonColorScheme: "messenger"
        },
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isMutating
        },
    ]

    const creatable: SectionCardCreatable = {
        isDisabled: isDisabled,
        text: "Browse",
        onClick: () => {
            childrenDisclosure.onOpen();
        }
    }

    const STUDENT_LIST_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "Student Id",
            accessor: "student_id",
            customRenderer: (value, item) => {
                return tblStudentIDCellMarkup(value, item)
            }
        },
        {
            header: "First Name",
            accessor: "first_name",
        },
        {
            header: "Last Name",
            accessor: "last_name",
        },
        {
            header: "Gender",
            accessor: "gender",
        },
        {
            header: "Class",
            accessor: "class",
        },
        {
            header: 'Relationship',
            accessor: '',
            customRenderer(value, item: any) {
                const onChangeHandler = (event: any) => {
                    const index = children.findIndex((i: any) => i.id == item.id)

                    setChildren((prev: any) => {
                        let arr = _.cloneDeep(prev);
                        arr[index]['relationship'] = event.target.value

                        return arr;
                    });
                }

                return (
                    <Select isDisabled={isDisabled} value={item?.relationship || 'Guardian'} onChange={onChangeHandler}
                            placeholder="Select relationship" shadow={"sm"} rounded={"md"} w={32} size={"sm"}
                            borderColor={'gray.300'} className={`!pb-[9px] !pt-[5px]`}>
                        {relationship.map((el: any, key: any) => <option key={key}
                                                                         value={el.value}>{el.label}</option>)}
                    </Select>
                )
            },
        },
        {
            header: "Actions",
            accessor: '',
            disabled: isDisabled,
            options: [
                {
                    label: "Remove",
                    onClick(item: any) {
                        const filteredData = children.filter((i: any) => i.id != item.id);
                        setChildren(filteredData);
                    },
                }
            ]
        }
    ]

    const onChildrenTableClickHandler = (row: any) => {
        window.open(`${window.location.origin}/app/students/${row.id}`)
    }

    useEffect(() => {
        if (!_.isEmpty(children)) {
            SetIsError(false)
        }
    }, [JSON.stringify(children)])

    useEffect(() => {
        guardianEmailQHandler(_.get(formik.values, "email"))
    }, [_.get(formik.values, "email")])

    const guardianEmailQHandler = _.debounce((value: any) => {
        setGuardianEmailQ(value)
    }, 200)

    const guardianEmailCustomError = (_validationData?.message > 0) && (
        <p className="mt-1 text-sm text-red-500">Email already taken.</p>
    )

    if (isLoading || isFetching) {
        return (
            <>
                <PageHeader isLoading={true} enableBackButton actions={actions} title={guardianName}
                            subtitle={"Guardian"}/>
                <InnerLoading/>
            </>
        )
    }

    const mobileRegisteredNoticeMarkup = (mobile_registered === 0) && (
        <InstructionBox title="Notes" type={"warning"}>
            <li>This user still not connected to the Edpass Guardian mobile app.</li>
            <li>Invitation has been sent.</li>
        </InstructionBox>
    )

    return (
        <>
            <PageHeader {...statusConfig} isLoading={isLoading} enableBackButton actions={actions} title={guardianName}
                        subtitle={"Guardian"}/>
            <ExtendUserModal disclosure={extendDisclosure} data={data} types={extend_types}/>


            <Tabs>
                <TabList>
                    <Tab>General Information</Tab>
                    <Tab>Activities</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel className="!p-0 !pt-3">
                        <StudentSelector selectedItems={children}
                                         setSelectedItems={setChildren} {...childrenDisclosure} />
                        {mobileRegisteredNoticeMarkup}
                        <div className="animation-form border rounded p-3">
                            <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 4}} spacing={3}>
                                <FormDropdown options={titles} isRequired label="Title" formik={formik} name="title"/>
                                <FormInput autoComplete="off" isRequired label="First Name" formik={formik}
                                           name="first_name"/>
                                <FormInput label="Middle Name" formik={formik} name="middle_name"/>
                                <FormInput autoComplete="off" isRequired label="Last Name" formik={formik}
                                           name="last_name"/>
                                <FormDropdown options={gender} isRequired label="Gender" formik={formik} name="gender"/>
                                <FormInput isRequired type="email" customMessage={guardianEmailCustomError}
                                           label="Email" formik={formik} name="email"/>
                                <FormInput placeholder="0XXXXXXXXX" isRequired type="tel" label="Mobile" formik={formik}
                                           name="mobile"/>
                                <FormCheckBox label="Active" formik={formik} name="active"/>
                            </SimpleGrid>
                        </div>

                        <div className="animation-form border rounded p-3 mt-3">
                            <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 4}} spacing={3}>
                                <FormTextArea label="Notes" formik={formik} name="notes"/>
                            </SimpleGrid>
                        </div>

                        <SectionCard className="animation-form-l2" isError={isError} errorMessage="Please add a child"
                                     creatable={creatable} subTitle="Select children related to the guardian"
                                     title="Children"
                                     p={3}>
                            <ChakraTable pageCount={10} isLoading={false}
                                         size={"sm"}
                                         title="Children" columns={STUDENT_LIST_TAB_COLUMNS} data={children || []}/>
                        </SectionCard>

                        <DisclaimerListWithConcent doc_name="Guardian" type={"Guardian"}/>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ActivityTab id={id} type={"Guardian"} customFilter={true}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>

        </>
    )
}

const tblStudentIDCellMarkup = (value: any, item: any) => {
    return (
        <Link color="#0073E5" href={`/app/students/${item.id}`}
              isExternal={true}>
            {value}
        </Link>
    )
}

export default ViewGuardianPage