import {NavigateFunction, useNavigate} from "react-router";
import {PageHeader} from "../../..";
import {PageHeaderActions} from "../../../common/page-header/PageHeader";
import GuardianListDataTable from "./components/GuardianListDataTable";


const GuardianListPage = () => {
    const navigate: NavigateFunction = useNavigate()

    const actions: PageHeaderActions = [
        {
            text: "Create New",
            onClick: () => navigate("new"),
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]


    return (
        <div>
            <PageHeader subtitle="View and Manage registered guardians" title={`Guardians`} actions={actions}/>
            <GuardianListDataTable/>
        </div>
    )
}

export default GuardianListPage;