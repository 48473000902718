import ChakraTable, {ChakraTableColumns} from "../../../common/chakra-table/ChakraTable";
import _ from "lodash";
import {useGetQuery} from "../../../../services/api.service";
import {GROUP} from "../../../../consts/methods.consts";
import {GROUP_TAGS} from "../../../../consts/tags.consts";
import {useTableDataProvider} from "../../../hooks";
import {NavigateFunction, useNavigate} from "react-router";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {FormDropdown, FormDropdownOptions} from "../../../common/form-controllers/FormDropdown";

const GroupListDataTable = () => {
    const navigate: NavigateFunction = useNavigate()
    const [filters, setFilters] = useState<object>({})
    const [page, setPage] = useState(1)

    const {data, isLoading, isFetching} = useGetQuery({
        method: GROUP.LIST,
        body: {
            page,
            ...filters
        },
        providesTags: [GROUP_TAGS.LIST]
    })

    const {dataList} = useTableDataProvider(data, {withoutPagination: true});
    const tableLineClickHandler = (line: any) => {
        navigate(`${line._id}`)
    }

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({...filters, ...value})
    }, 200)

    const customFiltersMarkup = (
        <div className="flex gap-2 items-center">
            <FormDropdown minW={40} size={"sm"} options={isActiveOptions} isRequired formik={formik} name="is_active"
                          placeholder="Status"/>
        </div>
    )

    return (
            <ChakraTable onSearch={onSearchHandler} isLoading={isLoading} isFetching={isFetching} size={"sm"} header
                         title="Groups" customFilterRenderer={customFiltersMarkup} onPage={setPage}
                         onSelected={tableLineClickHandler} columns={TAB_COLUMNS} data={dataList}/>
    )
}

const isActiveOptions: FormDropdownOptions = [
    {label: "Active", value: 1},
    {label: "Inactive", value: 0}
]
export const TAB_COLUMNS: ChakraTableColumns = [
    {
        header: "Name",
        accessor: "title",
    },
    {
        header: "Is Active",
        accessor: "active",
        conditions: {
            'INACTIVE': 'red',
            'ACTIVE': 'green'
        },
    },
]

export default GroupListDataTable