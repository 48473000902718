import React, { useEffect, useState } from "react"
import { Box, Link, useDisclosure, useToast } from "@chakra-ui/react"
import ChakraTable, { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable"
import { useTableDataProvider } from "../../../hooks"
import { useGetQuery } from "../../../../services/api.service"
import { GROUP, REPORTS } from "../../../../consts/methods.consts"
import { ACTIVITY_TAGS } from "../../../../consts/tags.consts"
import _ from "lodash";
import { useFormik } from "formik"
import { FormInput } from "../../../common/form-controllers/FormInput"
import { ToastService } from "../../../../services/toast.service"
import { filteredTableData, IOpenPdf, openPdf } from "../../../../utils/utils"
import { FormDropdown, FormDropdownOptions } from "../../../common/form-controllers/FormDropdown";
import SignatureCell from "../../../common/signature-cell/SignatureCell";
import FormLiveSelect from "../../../common/form-controllers/FormLiveSelect"

const LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        accessor: "full_name",
        header: "Name",
        customRenderer: (value, item) => {
            return tblNameCellMarkup(value, item)
        }
    },
    {
        accessor: "description",
        header: "Description"
    },
    {
        accessor: "grade",
        header: "Grade"
    },
    {
        accessor: "sign_in_station",
        header: "Sign In Station"
    },
    {
        accessor: "sign_in",
        header: "Sign In",
        conditions: {
            'No': 'red',
            'Yes': 'green'
        }
    },
    {
        accessor: "sign_in_signature",
        header: "Sign In Signature",
        customRenderer: (value, item) => {
            return <SignatureCell value={value} />
        }
    },
    {
        accessor: "sign_in_time",
        header: "Sign In Time"
    },
    {
        accessor: "sign_out_station",
        header: "Sign Out Station"
    },
    {
        accessor: "sign_out",
        header: "Sign Out",
        conditions: {
            'No': 'red',
            'Yes': 'green'
        }
    },
    {
        accessor: "sign_out_signature",
        header: "Sign Out Signature",
        customRenderer: (value, item) => {
            return <SignatureCell value={value} />
        }
    },
    {
        accessor: "sign_out_time",
        header: "Sign Out Time"
    }
]

const signInOptions: FormDropdownOptions = [
    { label: "Signed In Yes", value: 1 },
    { label: "Signed In No", value: 0 }
]

const signOutOptions: FormDropdownOptions = [
    { label: "Signed Out Yes", value: 1 },
    { label: "Signed Out No", value: 0 }
]

const groupOptions: FormDropdownOptions = [
    { label: "Without Group", value: 0 }
]

const tblNameCellMarkup = (value: any, item: any) => {
    return (
        <Link color="#0073E5" href={`/app/students/${item?.profile_id}`} isExternal>
            {value}
        </Link>
    )
}

const StudentVisitLogDataTable = () => {
    const toast = new ToastService(useToast());
    const _disclosure = useDisclosure();
    const [filters, setFilters] = useState({})
    const [groupQuery, setGroupQuery] = useState("")
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)

    const { data, isLoading } = useGetQuery({
        method: REPORTS.STUDENT_VISIT_LOG,
        body: {
            page,
            length: Number(length),
            ...filters
        },
        providesTags: [ACTIVITY_TAGS.LIST]
    })

    const { data: groupData, isFetching: groupFetching } = useGetQuery({
        method: GROUP.SEARCH,
        body: { q: groupQuery },
        providesTags: [ACTIVITY_TAGS.LIST]
    })

    const { pageCount, dataList, totalResult } = useTableDataProvider(data)

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const _onSelected = (line: any) => {
        _disclosure.onOpen()
    }

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({ ...filters, ...value })
    }, 200)

    const groupSearchHandler = _.debounce((text: string) => {
        setGroupQuery(text)
    }, 250)

    const customFilters = (
        <div className=" flex flex-row justify-center items-center gap-2">
            <FormInput w={180} onFocus={(e) => (e.currentTarget.type = "date")}
                onBlur={(e) => (e.currentTarget.type = "text")}
                size={"sm"} type="text" isRequired formik={formik} name="from" placeholder="From" />
            <FormInput w={180} onFocus={(e) => (e.currentTarget.type = "date")}
                onBlur={(e) => (e.currentTarget.type = "text")}
                size={"sm"} type="text" isRequired formik={formik} name="to" placeholder="To" />

            {/* <FormLiveSelect placeholder={"Group"} w={"200px"} formik={formik} name={"group"} options={groupData?.message?.map((line: any) => ({ label: line?.label, value: line?.name }))} isLoading={groupFetching} onInputChange={groupSearchHandler} isRequired /> */}
            <FormDropdown minW={40} size={"sm"} options={groupOptions} isRequired formik={formik} name="group"
                placeholder="With Group" />
            <FormDropdown minW={40} size={"sm"} options={signInOptions} isRequired formik={formik} name="sign_in"
                placeholder="Sign-in" />
            <FormDropdown minW={40} size={"sm"} options={signOutOptions} isRequired formik={formik} name="sign_out"
                placeholder="Sign-out" />
        </div>
    )

    const onPrint = async (columns: any, data: any) => {

        const columnsAndDataToRemove = ["sign_out_signature", "sign_in_signature"];
        const { filteredColumns, filteredData } = filteredTableData(columns, data, columnsAndDataToRemove)

        const config: IOpenPdf = {
            tableData: {
                columns: filteredColumns,
                data: filteredData,
                filters,
                info: {
                    title: "Student Visit Log"
                }
            },
            toast
        }

        openPdf(config);
    }

    return (
        <>
            <ChakraTable
                onPrint={onPrint}
                customFilterRenderer={customFilters}
                onSearch={onSearchHandler}
                size={"sm"}
                header title="Student Visit Log"
                onSelected={_onSelected}
                isLoading={isLoading}
                columns={LIST_TAB_COLUMNS}
                pageCount={pageCount}
                pagination
                lengthPaginations
                onPage={setPage}
                onLength={setLength}
                totalResult={totalResult}
                data={dataList} />
        </>
    )
}
export default StudentVisitLogDataTable
