import ChakraTable, {ChakraTableColumns} from "../../../common/chakra-table/ChakraTable";
import _ from "lodash";
import {Link, useToast} from "@chakra-ui/react";
import {NavigateFunction, useNavigate} from "react-router";
import {useGetQuery} from "../../../../services/api.service";
import {REPORTS} from "../../../../consts/methods.consts";
import {RESOURCE_TAGS} from "../../../../consts/tags.consts";
import {useTableDataProvider} from "../../../hooks";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {ToastService} from "../../../../services/toast.service";
import {IOpenPdf, openPdf} from "../../../../utils/utils";

const ResourceAllocationsListDataTable = () => {
    const toast = new ToastService(useToast());
    const navigate: NavigateFunction = useNavigate()
    const [filters, setFilters] = useState({})
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)

    const {data, isLoading, isFetching} = useGetQuery({
        method: REPORTS.RESOURCES_ALLOCATIONS,
        body: {
            page,
            length: Number(length),
            ...filters
        },
        providesTags: [RESOURCE_TAGS.LIST]
    })

    const {dataList, pageCount} = useTableDataProvider(data);

    const STUDENT_LIST_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "Resource Id",
            accessor: "resource_id"
        },
        {
            header: "Resource",
            accessor: "resource_title",
        },
        {
            header: "Name",
            accessor: "full_name",
            customRenderer: (value, item) => {
                return tblNameCellMarkup(value, item)
            },
            className: ""
        },
        {
            header: "User Type",
            accessor: "profile_type",
        },
        {
            header: "Allocated Date",
            accessor: "sign_out_time",
        },
        {
            header: "Due Date",
            accessor: "due_date",
        }
    ]

    const tblNameCellMarkup = (value: any, item: any) => {
        return (
            <>
                <Link color="#0073E5"
                      href={`/app/${profileTypeMapping[item?.profile_type]}/${_.get(item, 'profile')}`}
                      isExternal>{value}</Link>
            </>
        )
    }
    const tableLineClickHandler = (line: any) => {
        navigate(`/app/resources/${line?.resource_name}`)
    }

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({...filters, ...value})
    }, 200)

    const onPrint = async (columns: any, data: any) => {
        const config: IOpenPdf = {
            tableData: {
                columns,
                data,
                filters,
                info: {
                    title: "Resource Allocation List"
                }
            },
            toast
        }

        openPdf(config);
    }

    return (
        <>
            <ChakraTable onPrint={onPrint} onSearch={onSearchHandler} pageCount={pageCount} isLoading={isLoading}
                         isFetching={isFetching} pagination lengthPaginations size={"sm"} onPage={setPage}
                         onLength={setLength} header title="Allocations" onSelected={tableLineClickHandler}
                         columns={STUDENT_LIST_TAB_COLUMNS} data={dataList}/>
        </>
    )
}


const profileTypeMapping: any = {
    "Student": "students",
    "Staff": "staff"
}
export default ResourceAllocationsListDataTable