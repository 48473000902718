import {useDisclosure, useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import {useState} from "react";
import * as Yup from "yup";
import {ChakraModal, PageHeader} from "../..";
import {CLASS} from "../../../consts/methods.consts";
import {CLASS_TAGS} from "../../../consts/tags.consts";
import {usePostMutation} from "../../../services/api.service";
import {ToastService} from "../../../services/toast.service";
import {FormInput} from "../../common/form-controllers/FormInput";
import {PageHeaderActions} from "../../common/page-header/PageHeader";
import ClassListDataTable from "./components/ClassListDataTable";

const ClassListPage = () => {
    const disclosure = useDisclosure()
    const toast = new ToastService(useToast())
    const [isSelected, setIsSelected] = useState<boolean>(false);
    const [create, {isLoading}] = usePostMutation();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const formik = useFormik({
        initialValues: {
            class: ''
        },
        validationSchema: Yup.object({
            class: Yup.string().required("Class is required"),
        }),
        onSubmit: async (values) => {
            try {
                setIsSubmitting(true)
                await create({
                    method: isSelected ? CLASS.UPDATE : CLASS.ADD,
                    body: values,
                    invalidatesTags: [CLASS_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription("Class registered to the system").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to register class. Please try again").showErrorToast();
                console.error(error);
            } finally {
                setIsSubmitting(false)
                disclosure.onClose();
                setIsSelected(false);
            }
        }
    })


    const actions: PageHeaderActions = [
        {
            text: "Create New",
            onClick: () => {
                formik.resetForm();
                disclosure.onOpen()
            },
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isSubmitting
        }
    ]


    return (
        <div>
            <PageHeader subtitle="View and Manage registered classes" title={`Classes`} actions={actions}/>
            <div className="grid gap-3 grid-cols-1">
                <ClassListDataTable disclosure={disclosure} setIsSelected={setIsSelected} formik={formik}/>
            </div>

            <ChakraModal primaryAction={formik.submitForm} primaryButtonText={"Save"}
                         modalTitle={`${isSelected ? 'Update' : 'New'} class`} {...disclosure} isLoading={isLoading}>
                <FormInput isRequired formik={formik} name="class" autoFocus label="Class Name"/>
            </ChakraModal>
        </div>
    )
}

export default ClassListPage;