import {Box, Flex, Heading, HStack, Image, SimpleGrid, Stack, Text, useBreakpointValue} from "@chakra-ui/react";
import {edPassLogo} from "../../../assets";
import {ChangePasswordForm} from "./components/ChangePasswordForm";

const ResetPasswordPage = () => {
    return (
        <SimpleGrid columns={{base: 1, md: 2}}>
            <Box className="bg-[url('/src/assets/login.png')] bg-cover" minH={{base: 'auto', md: '100vh'}}
                 display={{base: 'none', md: 'block'}}>
                <Flex direction="column" px={{base: '4', md: '8'}} height="full" color="on-accent">
                    <Flex align="center" alignSelf="flex-start" h="24" paddingLeft="6">
                        <HStack spacing={0} alignItems={"start"} color={"white"}>
                            <Image src={edPassLogo} className="h-20 mt-16" alt="ed pass logo"/>
                        </HStack>
                    </Flex>
                    <Flex flex="1" align="center" color={'white'} paddingLeft="6">
                        <Stack spacing="8">
                            <Stack spacing="6">
                                <Heading className="text-white" size={useBreakpointValue({md: 'lg', xl: 'xl'})}>
                                    Best way to manage your visitors
                                </Heading>
                                <Text className="text-white" fontSize="lg" maxW="md" fontWeight="medium">
                                    edPass provides a seamless solution for managing visitors, contractors,
                                    staff and school resources.
                                </Text>
                            </Stack>
                        </Stack>
                    </Flex>
                </Flex>
            </Box>
            <div
                className="bg-[url('/src/assets/login_background_right.png')] bg-cover flex flex-col h-screen
                            items-center justify-center px-2">
                <ChangePasswordForm
                    px={{base: '4', md: '8'}}
                    py={{base: '12', md: '12'}}
                    width="full"
                    maxW="md"
                />
            </div>
        </SimpleGrid>
    )
}

export default ResetPasswordPage;