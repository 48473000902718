import * as Yup from "yup";
import {useState} from 'react';
import {FormInput} from '../../../common/form-controllers/FormInput';
import {useFormik} from 'formik';
import {ToastService} from '../../../../services/toast.service';
import {usePostMutation} from '../../../../services/api.service';
import {NavigateFunction, useNavigate, useParams} from 'react-router-dom';
import {Box, Button, Icon, StackProps, Text, useToast} from '@chakra-ui/react';
import {AUTH} from "../../../../consts/methods.consts";
import {BsEye, BsEyeSlash} from "react-icons/bs";

export const ChangePasswordForm = (props: StackProps) => {
    const navigator: NavigateFunction = useNavigate()
    const toast = new ToastService(useToast())
    const {key} = useParams()
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [isShowPwd, setIsShowPwd] = useState(false)
    const [isShowConfirmPwd, setIsShowConfirmPwd] = useState(false)
    const [create] = usePostMutation();

    const onPwdVisibleHandler = () => setIsShowPwd(!isShowPwd)
    const onConfirmPwdVisibleHandler = () => setIsShowConfirmPwd(!isShowConfirmPwd)

    const formik = useFormik({
        initialValues: {
            pwd: "",
            confirm_pwd: "",
        },
        validationSchema: Yup.object({
            pwd: Yup.string().required("Email is required"),
            confirm_pwd: Yup.string().required("Password is required")
                .oneOf([Yup.ref("pwd"), ""], "Passwords must match")
        }),
        onSubmit: async (values: any) => {
            try {
                setIsLoading(true);
                await create({
                    method: AUTH.CHANGE_PASSWORD,
                    body: {
                        new_pwd: values.confirm_pwd,
                        key: key
                    },
                    invalidatesTags: []
                }).unwrap()
                navigator("/")
                toast.setTitle("Success").setDescription("Your password has been successfully changed.").showSuccessToast();
            } catch (error) {
                toast.setTitle("Error").setDescription("Password can't be changed. Try again").showErrorToast()
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        }
    })

    return (
        <Box h={'100vh'} className='flex flex-col justify-center items-center mx-8' {...props}>
            <div className="flex flex-col gap-6 pb-6">
                <div className="flex flex-col">
                    <Text className="text-4xl font-bold text-[#084880]">Reset your password</Text>
                </div>
            </div>
            <div className="flex flex-col bg-[#084880] p-8 gap-6 rounded-[2.5rem] mt-8 w-[300px] md:w-[350px]">

                <form onSubmit={formik.handleSubmit}>
                    <div className="flex flex-col gap-6">
                        <div className="flex flex-col gap-4">
                            <div className="relative">
                                <Icon className="z-50 absolute right-0 bottom-0 mb-2 mr-4 cursor-pointer"
                                      as={isShowPwd ? BsEyeSlash : BsEye}
                                      onClick={onPwdVisibleHandler}/>
                                <FormInput rounded="3xl" backgroundColor="white"
                                           type={isShowPwd ? 'text' : 'password'}
                                           isRequired formik={formik} name='pwd'
                                           placeholder='Password'/>

                            </div>
                            <div className="relative">
                                <Icon className="z-50 absolute right-0 bottom-0 mb-2 mr-4 cursor-pointer"
                                      as={isShowConfirmPwd ? BsEyeSlash : BsEye}
                                      onClick={onConfirmPwdVisibleHandler}/>
                                <FormInput rounded="3xl" backgroundColor="white"
                                           type={isShowConfirmPwd ? 'text' : 'password'}
                                           isRequired formik={formik} name='confirm_pwd'
                                           placeholder='Confirm Password'/>

                            </div>
                        </div>
                        <div className="flex flex-col gap-4">
                            <Button borderRadius="3xl" isLoading={isLoading} type={'submit'} size="sm"
                                    className='!bg-gradient-to-r from-[#0F8DFB] via-[#0B98F8] to-[#07A6F5]
                                    !text-white shadow-lg'>
                                Change Password
                            </Button>
                        </div>
                    </div>
                </form>
            </div>
        </Box>
    )
}
