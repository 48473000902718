import {PageHeader} from "../..";
import ResourceAllocationsListDataTable from "./components/ResourceAllocationsListDataTable";


const ResourceAllocationsPage = () => {

    return (
        <div>
            <PageHeader subtitle="Manage allocations" title={`Resource Allocations`}/>
            <ResourceAllocationsListDataTable/>
        </div>
    )
}

export default ResourceAllocationsPage;