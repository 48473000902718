import * as Yup from "yup";
import moment from "moment";
import { useToast } from "@chakra-ui/react";
import FormSelect from "../../../common/form-controllers/FormSelect";
import { useFormik } from "formik";
import { ChakraModal } from "../../../index";
import { ToastService } from "../../../../services/toast.service";
import { FormTextArea } from "../../../common/form-controllers/FormTextArea";
import { ACTIVITY_TAGS, GROUP_TAGS, STUDENT_TAGS } from "../../../../consts/tags.consts";
import { ACTIVITY_TYPES } from "../../../../consts/types.consts";
import { usePostMutation } from "../../../../services/api.service";
import { ACTIVITY, GROUP, SCHOOL } from "../../../../consts/methods.consts";
import { useEffect } from "react";

interface IGroupSingInModal {
    id: string | undefined,
    disclosure: any
    description: string
}

const GroupSingInModal = ({ disclosure, id, description }: IGroupSingInModal) => {
    const toast = new ToastService(useToast())
    const [create, {isLoading}] = usePostMutation();

    const formik = useFormik({
        initialValues: {
            station: ''
        },
        validationSchema: Yup.object({
            station: Yup.string().required("Station is required"),
            description: Yup.string()
        }),
        onSubmit: async (values) => {
            try {
                await create({
                    method: GROUP.SIGN_IN,
                    body: {
                        ...values,
                        id,
                        sign_in_time: moment().format("YYYY-MM-DD H:mm"),
                    },
                    invalidatesTags: [ACTIVITY_TAGS.LIST, ACTIVITY_TAGS.VIEW, GROUP_TAGS.VIEW, GROUP_TAGS.LIST]
                }).unwrap();
                formik.resetForm()
                toast.setTitle("Success").setDescription("Sign in to the Station").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to sign in. Please try again").showErrorToast();
                console.error(error);
            } finally {
                disclosure.onClose();
            }
        }
    })

    useEffect(() => {
        formik.setFieldValue('description', description)
    }, [description])

    return (
        <ChakraModal
            primaryAction={formik.submitForm}
            primaryButtonText={"Sign in"}
            modalTitle="Sign in group"
            {...disclosure}
            isLoading={isLoading}
        >
            <FormSelect
                method={SCHOOL.GET_STATION_LIST_FOR_DROPDOWN}
                isRequired label="Station"
                formik={formik}
                name="station" />
            <FormTextArea isReadOnly={!!description} formik={formik} name={"description"} label={"Reason"} />
        </ChakraModal>
    )
}

export default GroupSingInModal