import {useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import _ from "lodash";
import {useEffect, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router";
import {SCHOOL, STUDENT} from "../../../../consts/methods.consts";
import {STUDENT_TAGS} from "../../../../consts/tags.consts";
import {useGetQuery} from "../../../../services/api.service";
import {ToastService} from "../../../../services/toast.service";
import {IOpenPdf, openPdf} from "../../../../utils/utils";
import ChakraTable, {ChakraTableColumns} from "../../../common/chakra-table/ChakraTable";
import {FormDropdown, FormDropdownOptions} from "../../../common/form-controllers/FormDropdown";
import FormSelect from "../../../common/form-controllers/FormSelect";
import {useTableDataProvider} from "../../../hooks";

const StudentListDataTable = () => {
    const navigate: NavigateFunction = useNavigate()
    const [filters, setFilters] = useState<object>({})
    const toast = new ToastService(useToast());
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)

    const {data, isLoading, isFetching} = useGetQuery({
        method: STUDENT.LIST,
        body: {
            page,
            length: Number(length),
            ...filters
        },
        providesTags: [STUDENT_TAGS.LIST]
    })

    const {dataList, pageCount, totalResult} = useTableDataProvider(data);

    const tableLineClickHandler = (line: any) => {
        navigate(`${line._id}`)
    }

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({...filters, ...value})
    }, 200)

    const customFilters = (
        <div className="flex gap-2 items-center">
            <FormSelect method={SCHOOL.GET_CLASS_LIST_FOR_DROPDOWN} isRequired formik={formik} name="class"
                        placeholder="Class"/>
            <FormDropdown minW={40} size={"sm"} options={statusOptions} isRequired formik={formik} name="status"
                          placeholder="Status"/>
            <FormDropdown minW={40} size={"sm"} options={genderOptions} isRequired formik={formik} name="gender"
                          placeholder="Gender"/>
        </div>
    )

    const onPrint = async (columns: any, data: any) => {
        const config: IOpenPdf = {
            tableData: {
                columns,
                data,
                filters,
                info: {
                    title: "Student List",
                }
            },
            toast
        }

        openPdf(config);
    }

    return (
        <ChakraTable onPage={setPage} onLength={setLength} onPrint={onPrint} customFilterRenderer={customFilters}
                     onSearch={onSearchHandler} pageCount={pageCount} isLoading={isLoading} isFetching={isFetching}
                     pagination size={"sm"} lengthPaginations totalResult={totalResult}
                     header title="Students" onSelected={tableLineClickHandler} columns={STUDENT_LIST_TAB_COLUMNS}
                     data={dataList}/>
    )

}

const genderOptions: FormDropdownOptions = [
    {label: "Male", value: "Male"},
    {label: "Female", value: "Female"}
]

const statusOptions: FormDropdownOptions = [
    {label: "Active", value: "Active"},
    {label: "Inactive", value: "Inactive"},
    {label: "Future", value: "Future"},
    {label: "Left", value: "Left"},
    {label: "Leaving", value: "Leaving"}
]

const STUDENT_LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        header: "Student Id",
        accessor: "student_id"
    },
    {
        header: "First Name",
        accessor: "first_name",
    },
    {
        header: "Last Name",
        accessor: "last_name",
    },
    {
        header: "Birth Day",
        accessor: "birthday",
    },
    {
        header: "Gender",
        accessor: "gender",
    },
    {
        header: "Class",
        accessor: "class",
    },
    {
        header: "Status",
        accessor: "status",
        conditions: {
            'Inactive': 'red',
            'Active': 'green',
            'Future': "cyan",
            'Left': "orange",
            'Leaving': 'yellow'
        },
    },
]

export default StudentListDataTable