import ReactEcharts from "echarts-for-react"
import { REPORTS } from "../../../../consts/methods.consts"
import { ACTIVITY_TAGS } from "../../../../consts/tags.consts"
import { useGetQuery } from "../../../../services/api.service"
import { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable"
import _ from "lodash"


const LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        accessor: "full_name",
        header: "Name"
    },
    {
        accessor: "sign_in_time",
        header: "Time"
    },
    {
        accessor: "sign_in_station",
        header: "Station"
    }
]

const MonthOverview = () => {

    const { data: _data, isLoading } = useGetQuery({
        method: REPORTS.MONTH_OVERVIEW,
        body: { id: 10 },
        providesTags: [ACTIVITY_TAGS.LIST]
    })

    const data = _.get(_data, "message")

    const source = Array.isArray(data) ? data.map((line: any) => ({
        product: line['day'],
        Visitors: line['visitor'],
        Students: line['student'],
        Staff: line['staff']
    })) : []

    const options = {
        legend: {},
        tooltip: {},
        dataset: {
            dimensions: ['product', 'Visitors', 'Students', 'Staff'],
            source
        },
        grid: {
            top: 50,
            bottom: 30,
            left: '5%',
            right: '5%',
        },
        xAxis: { type: 'category' },
        yAxis: {},
        series: [{ type: 'bar', itemStyle: { color: '#063c6a' } }, {
            type: 'bar',
            itemStyle: { color: '#008aff' }
        }, { type: 'bar', itemStyle: { color: '#31b8fc' } }]
    }

    return (
        <div className="border mt-4 pt-4 col-span-2 rounded-md shadow-sm relative">
            <ReactEcharts
                style={{ margin: 0, padding: 0 }}
                option={options}
            ></ReactEcharts>
            <p className="w-full text-center pb-2 font-medium text-sm">Monthly Check in Overview</p>
        </div>
    )
}

export default MonthOverview