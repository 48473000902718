import GroupActivityListDataTable from "./GroupActivityListDataTable";
import GroupActivityHistoryDataTable from "./GroupActivityHistoryDataTable";
import {useState} from "react";

interface IGroupActivityTab {
    id: string | undefined
}

const GroupActivityTab = ({id}: IGroupActivityTab) => {
    const [activityId, setActivityId] = useState<string>("")

    return (
        <div className="flex flex-col gap-3">
            <GroupActivityListDataTable id={id} onSelected={setActivityId}/>
            {activityId !== "" ? <GroupActivityHistoryDataTable id={activityId}/> : null}
        </div>
    )
}

export default GroupActivityTab