import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import * as Yup from "yup";
import { EXTERNAL } from "../../../../consts/methods.consts";
import { COMPLIANCE_TAGS } from "../../../../consts/tags.consts";
import { usePostMutation } from "../../../../services/api.service";
import { ToastService } from "../../../../services/toast.service";
import { FormDropdown, FormDropdownOptions } from "../../../common/form-controllers/FormDropdown";
import { ChakraModal } from "../../../index";


interface IExtendUserModal {
    disclosure: any,
    data: any,
    types: FormDropdownOptions
}

const ExtendUserModal = ({ disclosure, data, types }: IExtendUserModal) => {
    const toast = new ToastService(useToast())
    const [create, {isLoading}] = usePostMutation();

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            type: Yup.string().required("Type is required")
        }),
        onSubmit: async (values) => {
            try {

                await create({
                    method: EXTERNAL.EXTEND_USER,
                    body: {
                        _id: _.get(data, 'external_profile'),
                        type: _.get(values, 'type')
                    },
                    invalidatesTags: [COMPLIANCE_TAGS.LIST]
                }).unwrap();
                formik.resetForm()
                toast.setTitle("Success").setDescription("User extend successful").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to extend user. Please try again").showErrorToast();
                console.error(error);
            } finally {
                disclosure.onClose();
            }
        }
    })

    useEffect(() => {
        if (_.get(data, "expire_date")) {
            formik.setFieldValue("expire_date", _.get(data, "expire_date"));
        }
    }, [data])

    return (
        <ChakraModal
            primaryAction={formik.submitForm}
            primaryButtonText={"Save"}
            modalTitle={"Extend User"}
            {...disclosure}
            isLoading={isLoading}
        >
            <FormDropdown options={types} isRequired label="Type" formik={formik} name="type" />
        </ChakraModal>
    )
}

export default ExtendUserModal