import { SimpleGrid } from "@chakra-ui/layout";
import { useFormik } from "formik";
import { NavigateFunction, useNavigate, useParams } from "react-router-dom";
import * as Yup from "yup";
import { useEffect } from "react";
import moment from "moment";

import { STUDENT_DATA } from "../../../../assets/data/data";
import PageHeader, { PageHeaderActions } from "../../../common/page-header/PageHeader";
import FormSelect, { FormSelectOptions } from "../../../common/form-controllers/FormSelect";
import { FormDropdown, FormDropdownOptions } from "../../../common/form-controllers/FormDropdown";
import { FormInput } from "../../../common/form-controllers/FormInput";
import { FormTextArea } from "../../../common/form-controllers/FormTextArea";


const ViewPage = () => {
    const { id } = useParams()
    const navigate: NavigateFunction = useNavigate();

    const formik = useFormik({
        initialValues: { ...STUDENT_DATA.find((i: any) => i.id == id) },
        validationSchema: Yup.object({
            first_name: Yup.string().min(2).required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            tags: Yup.array().min(1).required("Tags are required"),
            city: Yup.string().required("City is required"),
            class: Yup.string().required("Class is required"),
            pin: Yup.string().min(4, "Minimum 4 characters").max(8, "Maximum 8 characters").required("Pin code is required")
        }),
        onSubmit() {

        }
    })

    useEffect(() => {
        formik.setFieldValue("time", moment().format('HH:mm'))
    }, [])

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    const cities: FormSelectOptions = [
        { label: "City A", value: "city a" },
        { label: "City B", value: "city b" },
        { label: "City C", value: "city c" },
        { label: "City D", value: "city d" }
    ]

    const classes: FormDropdownOptions = [
        { label: "Grade 1", value: "g1" },
        { label: "Grade 2", value: "g2" },
        { label: "Grade 3", value: "g3" },
        { label: "Grade 4", value: "g4" },
    ]

    const tags: FormSelectOptions = [
        { label: "Tag 1", value: "Tag 1" },
        { label: "Tag 2", value: "Tag 2" },
        { label: "Tag 3", value: "Tag 3" },
        { label: "Tag 4", value: "Tag 4" }
    ]

    return (
        <>
            <PageHeader enableBackButton actions={actions} title="Create New Student" subtitle="Student" />

            <div className=" border rounded p-3">
                <div className="grid lg:grid-cols-4 xl:grid-cols-4 gap-4">
                    <FormInput isRequired label="First Name" formik={formik} name="first_name" />
                    <FormInput isRequired label="Last Name" formik={formik} name="last_name" />
                    <FormSelect options={cities} isRequired label="City" formik={formik} name="city" />
                    <FormDropdown options={classes} isRequired label="Class" formik={formik} name="class" />
                    <FormInput isRequired type="date" label="Birth Day" formik={formik} name="birth_day" />
                    <FormSelect options={tags} isMulti={true} isRequired label="Tags" formik={formik} name="tags" />
                    <FormInput isDisabled type="password" isRequired label="Pin Code" formik={formik} name="pin" />
                    <FormInput type="time" isRequired label="Time" formik={formik} name="time" />
                    <FormTextArea isRequired label="Address" formik={formik} name="address" />
                </div>
            </div>

            <div className="border rounded mt-4 bg-white p-3 hover:bg-gray-100 transition-all cursor-pointer">
                <p className="font-semibold mb-3">For Testing</p>
                <p>{JSON.stringify(formik.values)}</p>
            </div>
        </>
    )
}

export default ViewPage