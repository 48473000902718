import {
    Accordion,
    AccordionButton,
    AccordionIcon,
    AccordionItem,
    AccordionPanel,
    As,
    Flex,
    FlexProps,
    HStack,
    Icon,
    Link,
    LinkProps,
    Stack,
    Text,
    TextProps,
    useColorModeValue as mode
} from '@chakra-ui/react'
import _ from 'lodash'
import {
    FaBuilding,
    FaInfo,
    FaLayerGroup,
    FaSchool,
    FaTasks,
    FaTools,
    FaUser,
    FaUserCog,
    FaUserFriends,
    FaUserMd,
    FaUsers,
    FaUserTie,
    FaFileImport
} from 'react-icons/fa'
import { FiArrowUpRight, FiBox, FiCalendar, FiHome, FiMic } from 'react-icons/fi'
import { IconType } from 'react-icons/lib'
import { NavigateFunction, useNavigate } from 'react-router-dom'
import { usePathMetaProvider } from '../../hooks'
import logo from './edpass-logo.png'

interface NavbarProps extends FlexProps {
    onClose?: () => void
}

type ILinks = {
    icon: IconType,
    label: string,
    onClick?: () => any,
    path?: string,
    disabled?: boolean,
    children?: Array<ILinks>
}
type INavElement = { heading: string, links: Array<ILinks> }

export const SideBar = (props: NavbarProps) => {
    const navigate: NavigateFunction = useNavigate();
    const activePath: string = usePathMetaProvider();

    const navConfig: Array<INavElement> = [
        {
            heading: "Category",
            links: [
                {
                    icon: FaUserFriends,
                    label: "Student",
                    path: "students",
                    disabled: false,
                    onClick: () => {
                        navigate("/app/students")
                    }
                },
                {
                    icon: FaUserTie,
                    label: "Staff",
                    path: "staff",
                    disabled: false,
                    onClick: () => {
                        navigate("/app/staff")
                    }
                },
                {
                    icon: FaUserTie,
                    label: "Visitor",
                    disabled: false,
                    children: [
                        {
                            icon: FaUsers,
                            label: "Guardian",
                            path: "guardians",
                            disabled: false,
                            onClick: () => {
                                navigate("/app/guardians")
                            }
                        },
                        {
                            icon: FaUserMd,
                            label: "Therapist",
                            path: "therapists",
                            disabled: false,
                            onClick: () => {
                                navigate("/app/therapists")
                            }
                        },
                        {
                            icon: FaUserCog,
                            label: "Contractor",
                            path: "contractors",
                            disabled: false,
                            onClick: () => {
                                navigate("/app/contractors")
                            }
                        },
                        {
                            icon: FaUserTie,
                            label: "Guest",
                            path: "guests",
                            disabled: false,
                            onClick: () => {
                                navigate("/app/guests")
                            }
                        }
                    ]
                },
                {
                    icon: FaLayerGroup,
                    label: "Group",
                    path: "group",
                    disabled: false,
                    onClick: () => {
                        navigate("/app/group")
                    }
                },
                {
                    icon: FaTasks,
                    label: "Activity",
                    path: "activity",
                    disabled: false,
                    onClick: () => {
                        navigate("/app/activity")
                    }
                },
                {
                    icon: FiBox,
                    label: "Deliveries",
                    disabled: false,
                    path: 'parcels',
                    onClick: () => {
                        navigate("/app/parcels")
                    }
                },
                {
                    icon: FaUserFriends,
                    label: "Tour",
                    disabled: false,
                    path: 'tour',
                    onClick: () => {
                        navigate("/app/tour")
                    }
                },
            ]
        },
        {
            heading: "Analytics",
            links: [
                {
                    icon: FiMic,
                    label: "Visit Log",
                    disabled: false,
                    path: 'visit-log',
                    onClick: () => {
                        navigate("/app/visit-log")
                    }
                },
                {
                    icon: FiCalendar,
                    label: "Timesheet",
                    disabled: false,
                    path: "timesheet",
                    onClick: () => {
                        navigate("/app/timesheet")
                    }
                },
                {
                    icon: FiCalendar,
                    label: "Visitor Compliance",
                    disabled: false,
                    path: 'reports',
                    onClick: () => {
                        navigate("/app/reports/visitor-compliance")
                    }
                },
                {
                    icon: FaTools,
                    label: "Resource Allocations",
                    disabled: false,
                    path: 'resource-allocations',
                    onClick: () => {
                        navigate("/app/resource-allocations")
                    }
                }
            ]
        },
        {
            heading: "Modules",
            links: [
                {
                    icon: FaSchool,
                    label: "Classes",
                    disabled: false,
                    path: "classes",
                    onClick: () => {
                        navigate("/app/classes")
                    }
                },
                {
                    icon: FaBuilding,
                    label: "Departments",
                    disabled: false,
                    path: "departments",
                    onClick: () => {
                        navigate("/app/departments")
                    }
                },
                {
                    icon: FaLayerGroup,
                    label: "Stations",
                    disabled: false,
                    path: "stations",
                    onClick: () => {
                        navigate("/app/stations")
                    }
                },
                {
                    icon: FaTools,
                    label: "Resources",
                    disabled: false,
                    path: 'resources',
                    onClick: () => {
                        navigate("/app/resources")
                    }
                },
                {
                    icon: FaInfo,
                    label: "Policies",
                    disabled: false,
                    path: 'disclaimers',
                    onClick: () => {
                        navigate("/app/disclaimers")
                    }
                },
                {
                    icon: FaFileImport,
                    label: "Data Import",
                    disabled: false,
                    path: 'data-import',
                    onClick: () => {
                        navigate("/app/data-import")
                    }
                }
            ]
        }
    ]

    const navMarkup = navConfig.map((line: INavElement, index: number) => {
        return (
            <Stack spacing="1" key={index}>
                <NavHeading>{line.heading}</NavHeading>
                <Stack spacing="1">
                    {line.links.map((link: ILinks, index: number) => {
                        if (link?.children && !_.isEmpty(link.children)) {
                            return (
                                <ParentNavLink {...link} key={index} activePath={activePath} />
                            )
                        } else {
                            return (
                                <NavLink
                                    key={index}
                                    fontWeight={"semibold"}
                                    pointerEvents={link.disabled ? 'none' : 'auto'}
                                    className={link.disabled ? 'opacity-50' : ''}
                                    aria-current={activePath === link.path ? 'page' : undefined}
                                    onClick={link.onClick}
                                    icon={link.icon}>{link.label}
                                </NavLink>
                            )
                        }
                    })}
                </Stack>
            </Stack>
        )
    });

    return (
        <Flex as="nav" height="full" direction="column" justify="space-between" {...props}>
            <Stack spacing="3">
                <HStack spacing={0} ml={3} mb={4} alignItems={"center"}>
                    <div className='relative'>
                        <img alt="Logo" className='pt-2' width={130} height={30} src={logo} onClick={() => navigate("/app")} />
                        <p className="absolute text-xs px-2">v2.3.0</p>
                    </div>
                </HStack>
                <Stack px="3" spacing="6">
                    <Stack spacing="1">
                        <NavLink onClick={() => navigate("/app")}
                            aria-current={activePath === 'home' ? 'page' : undefined} icon={FiHome}>Home</NavLink>
                        {/* <NavLink icon={FiGrid}>
                            Dashboard
                        </NavLink> */}
                    </Stack>
                    {navMarkup}
                </Stack>
            </Stack>
            {/* <Box borderTopWidth="1px">
                <NavButton>Sign In</NavButton>
            </Box> */}
        </Flex>
    )
}

/*
const NavButton = (props: ButtonProps) => (
    <Button
        width="full"
        borderRadius="0"
        variant="ghost"
        size="lg"
        fontSize="sm"
        _hover={{bg: mode('gray.100', 'gray.700')}}
        _active={{bg: mode('gray.200', 'gray.600')}}
        _focus={{boxShadow: 'none'}}
        _focusVisible={{boxShadow: 'outline'}}
        {...props}
    />
)
*/

interface NavLinkProps extends LinkProps {
    icon: As
}

export const NavLink = (props: NavLinkProps) => {
    const { icon, ...linkProps } = props
    return (
        <Link
            px="2"
            py="1.5"
            borderRadius="md"
            _hover={{ bg: mode('gray.100', 'gray.700') }}
            _activeLink={{
                bg: '#063c6a',
                color: 'white',
            }}
            {...linkProps}
        >
            <HStack justify="space-between">
                <HStack spacing="3">
                    <Icon as={icon} />
                    <Text fontWeight={"semibold"} as="span" fontSize="sm" lineHeight="1.25rem">
                        {props.children}
                    </Text>
                </HStack>
                {props.isExternal && (
                    <Icon as={FiArrowUpRight} boxSize="4" color={mode('gray.600', 'gray.400')} />
                )}
            </HStack>
        </Link>
    )
}

const ParentNavLink = (props: any) => {
    const isActive = () => {
        try {
            if (props?.children.some((line: any) => props.activePath === line.path)) {
                return [0]
            }

            return []
        } catch (error) {
            return []
        }
    }

    return (
        <Accordion border={0} borderRadius={"md"} defaultIndex={isActive()} allowMultiple>
            <AccordionItem borderRadius={"md"} border={0}>
                <HStack
                    borderRadius="md"
                    // _hover={{ bg: mode('gray.100', 'gray.700') }}
                    _activeLink={{
                        bg: 'gray.700',
                        color: 'white',
                    }}>
                    <AccordionButton borderRadius={"md"} _hover={{ bg: "gray.100" }} py={1.5} px={2}>
                        <HStack justifyContent={"space-between"} w={"full"}>
                            <HStack as="a" spacing="3">
                                <Icon as={FaUser} />
                                <Text fontWeight={"semibold"} as="span" fontSize="sm" lineHeight="1.25rem">
                                    Visitor
                                </Text>
                            </HStack>
                            <AccordionIcon />
                        </HStack>
                    </AccordionButton>
                </HStack>
                <AccordionPanel pb={0}>
                    <Stack spacing="1">
                        {props.children.map((link: any, index: number) => (
                            <NavLink
                                key={index}
                                fontWeight={"semibold"}
                                pointerEvents={link.disabled ? 'none' : 'auto'}
                                className={link.disabled ? 'opacity-50' : ''}
                                aria-current={props.activePath === link.path ? 'page' : undefined}
                                onClick={link.onClick}
                                icon={link.icon}>{link.label}
                            </NavLink>
                        ))}
                    </Stack>

                </AccordionPanel>
            </AccordionItem>
        </Accordion>
    )
}

export const NavHeading = (props: TextProps) => (
    <Text
        as="h4"
        fontSize="xs"
        fontWeight="semibold"
        px="2"
        lineHeight="1.25"
        color={mode('gray.600', 'gray.400')}
        {...props}
    />
)
