import { HStack, Stack } from "@chakra-ui/react";
import { title } from "process";

interface InstructionBoxProps {
    children: JSX.Element[] | JSX.Element,
    type?: 'info' | 'message' | 'warning' | 'success' | 'error',
    title?: string
}

const InstructionBox = ({ children, type = 'info', title }: InstructionBoxProps) => {
    const colorGenerator = () => {
        if (type == 'message') {
            return {
                bg: "gray.50",
                color: "gray.800"
            };
        }

        if (type == 'warning') {
            return {
                bg: "yellow.50",
                color: "yellow.800"
            };
        }

        if (type == 'success') {
            return {
                bg: "green.50",
                color: 'green.800'
            };
        }

        if (type == 'error') {
            return {
                bg: "red.50",
                color: 'red.800'
            };
        }

        return {
            bg: "blue.50",
            color: 'blue.800'
        }
    }

    return (
        <Stack {...colorGenerator()} className={`flex p-4 border mb-4 text-sm rounded-lg animation-item`} role="alert">
            <HStack>
                <svg className="flex-shrink-0 inline w-4 h-4 mr-1 mt-[2px]" aria-hidden="true" xmlns="http://www.w3.org/2000/svg" fill="currentColor" viewBox="0 0 20 20">
                    <path d="M10 .5a9.5 9.5 0 1 0 9.5 9.5A9.51 9.51 0 0 0 10 .5ZM9.5 4a1.5 1.5 0 1 1 0 3 1.5 1.5 0 0 1 0-3ZM12 15H8a1 1 0 0 1 0-2h1v-3H8a1 1 0 0 1 0-2h2a1 1 0 0 1 1 1v4h1a1 1 0 0 1 0 2Z" />
                </svg>
                <p className="sr-only">Info</p>
                <span className="font-medium">{title ? title : 'Instructions to be considered'}</span>
            </HStack>
            <div>
                <ul className="mt-1.5 pl-1 list-disc list-inside">
                    {children}
                </ul>
            </div>
        </Stack>
    )
}

export default InstructionBox;