export enum STUDENT_TAGS {
    LIST = "STUDENT_LIST",
    VIEW = "STUDENT_VIEW",
    ACTIVITY_LIST = "ACTIVITY_LIST"
}

export enum ACTIVITY_TAGS {
    LIST = "ACTIVITY_LIST",
    VIEW = "ACTIVITY_VIEW"
}

export enum STAFF_TAGS {
    LIST = "STAFF_LIST",
    VIEW = "STAFF_VIEW",
    DROPDOWN = "STAFF_DROPDOWN"
}

export enum GUARDIAN_TAGS {
    LIST = "GUARDIAN_LIST",
    VIEW = "GUARDIAN_VIEW"
}

export enum CONTRACTOR_TAGS {
    LIST = "CONTRACTOR_LIST",
    VIEW = "CONTRACTOR_VIEW"
}

export enum GUEST_TAGS {
    LIST = "GUEST_LIST",
    VIEW = "GUEST_VIEW"
}

export enum THERAPIST_TAGS {
    LIST = "THERAPIST_LIST",
    VIEW = "THERAPIST_VIEW"
}

export enum GROUP_TAGS {
    LIST = "GROUP_LIST",
    VIEW = "GROUP_VIEW"
}

export enum FILTER_TAGS {
    STUDENT_SELECTOR = 'STUDENT_SELECTOR',
    STAFF_SELECTOR = 'STAFF_SELECTOR',
    GUARDIAN_SELECTOR = 'GUARDIAN_SELECTOR',
    EXTERNAL_USER_SELECTOR = 'EXTERNAL_USER_SELECTOR',
}

export enum RESOURCE_TAGS {
    LIST = "RESOURCE_LIST",
    VIEW = "RESOURCE_VIEW",
    ASSIGNMENT_LIST = "RESOURCE_ASSIGNMENT_LIST"
}

export enum DISCLAIMER_TAGS {
    LIST = "DISCLAIMER_LIST",
    VIEW = "DISCLAIMER_VIEW"
}

export enum PARCEL_TAGS {
    LIST = "PARCEL_LIST",
    VIEW = "PARCEL_VIEW"
}

export enum DROPDOWN_TAGS {
    LIST = "DROPDOWN"
}

export enum DEPARTMENT_TAGS {
    LIST = "DEP_LIST"
}

export enum STATION_TAGS {
    LIST = "STATION_LIST"
}

export enum RESOURCE_TYPE_TAGS {
    LIST = "RESOURCE_TYPE_LIST"
}

export enum CLASS_TAGS {
    LIST = "CLASS_LIST"
}

export enum SCHOOL_TAGS {
    LIST = "SCHOOL_LIST"
}

export enum ATTACHMENT_TAGS {
    GET_PROFILE_IMAGE = "GET_PROFILE_IMAGE"
}

export enum COMPLIANCE_TAGS {
    LIST = "COMPLIANCE_LIST",
    VIEW = "COMPLIANCE_VIEW"
}