import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import moment from "moment";
import * as Yup from "yup";
import { ChakraModal } from "../..";
import { ACTIVITY } from "../../../consts/methods.consts";
import { ACTIVITY_TAGS, RESOURCE_TAGS } from "../../../consts/tags.consts";
import { usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";

interface IDeallocateResourceModal {
    title: string
    data: any
    disclosure: any
}

const DeallocateResourceModal = ({ disclosure, title, data }: IDeallocateResourceModal) => {
    const toast = new ToastService(useToast())
    const [create, {isLoading}] = usePostMutation();

    console.log(data?.resource_title)
    const formik = useFormik({
        initialValues: {
            resource_title: data?.resource_title
        },
        validationSchema: Yup.object({}),
        onSubmit: async (values: any) => {
            try {
                await create({
                    method: ACTIVITY.RESOURCE_UPDATE,
                    body: {
                        _id: data?.allocated_id,
                        sign_in: 1,
                        sign_in_time: moment().format("YYYY-MM-DD H:mm")
                    },
                    invalidatesTags: [ACTIVITY_TAGS.LIST, RESOURCE_TAGS.LIST, RESOURCE_TAGS.VIEW, RESOURCE_TAGS.ASSIGNMENT_LIST]
                }).unwrap();
                formik.resetForm()
                toast.setTitle("Success").setDescription("Resource handed over").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable handover resource").showErrorToast();
                console.error(error);
            } finally {
                disclosure.onClose();
            }
        }
    })

    const _hidePrimary = () => {
        return (data?.sign_in === "Yes")
    }

    return (
        <ChakraModal
            primaryAction={formik.submitForm}
            primaryButtonText={"Handover"}
            hidePrimaryButton={_hidePrimary()}
            modalTitle={title}
            {...disclosure}
            isLoading={isLoading}
        >
            {data?.resource_title}
        </ChakraModal>

    )
}

export default DeallocateResourceModal