import _ from "lodash";
import {PRINTING} from "../consts/methods.consts";
import {BASE_URL} from "../consts/app.consts";

/**
 * The frappeExceptionFormatter extract exact error message from frappe error
 *
 * @param Frappe error object.
 * @returns Exact error message.
 */
const frappeExceptionFormatter = (error: any) => {
    const exceptions = error?.data?.exception || ''

    if (!exceptions) {
        return exceptions;
    }

    const frappeError = exceptions.split(":")
    const customError: String = _.get(frappeError, ['1'], '')

    return customError.trim()
}

/**
 * Is a util that convert a file to a base64 string
 *
 * @param file - The file you want to convert to base64
 * @param cb - Callback function ready with base64 string
 */
const getBase64 = (file: any, cb: any) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = function () {
        cb(reader.result)
    };
    reader.onerror = function (error) {
        console.log('Error: ', error);
    };
}

export interface IOpenPdf {
    tableData: IOpenPdfData,
    toast: any
}

export interface IOpenPdfData {
    columns: any,
    data: any,
    info?: any
    filters?: any
}

const openPdf = ({tableData, toast}: IOpenPdf) => {
    const {columns, data, info = {}, filters = {}} = tableData;
    const dataToSend = JSON.stringify({columns, data, info, filters});

    try {
        fetch(BASE_URL + "/method/" + PRINTING.TABLES, {
            credentials: 'include',
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
            },
            body: dataToSend
        }).then(res => res.json()
            .then(data => {
                const {message} = data;

                if (message) {
                    var myWindow: any = window.open("", "", '');
                    myWindow.document.write(message);

                    myWindow.document.close();
                    myWindow.focus();
                } else {
                    throw new Error("Unable to generate print");
                }
            })
            .catch(err => {
                throw err;
            })
        ).catch(err => {
            throw err;
        });
    } catch (error) {
        toast.setTitle("Error").setDescription("Unable to generate print").showErrorToast();
        console.error(error);
    }
};


const getCurrentWeekDates = () => {
    const currentDate = new Date();
    const currentDay = currentDate.getDay();
    const diff = currentDate.getDate() - currentDay + (currentDay == 0 ? -6 : 1); // Adjust to Monday if current day is Sunday
    const startOfWeek = new Date(currentDate.setDate(diff));

    // Calculate end date by adding 6 days to the start date
    const endOfWeek = new Date(startOfWeek);
    endOfWeek.setDate(startOfWeek.getDate() + 6);

    // Reset hours, minutes, seconds, and milliseconds to 0
    startOfWeek.setHours(0, 0, 0, 0);
    endOfWeek.setHours(23, 59, 59, 999); // Set end time to 23:59:59.999

    return {startOfWeek, endOfWeek};
}

const filteredTableData = (columns: Array<any>, data: Array<any>, columnsAndDataToRemove: Array<string>): {
    filteredColumns: Array<any>,
    filteredData: Array<any>
} => {

    const filteredColumns = columns.slice()

    const filteredData = data.map((line: any) => _.omit(line, columnsAndDataToRemove))
    _.remove(filteredColumns, (line: any) => columnsAndDataToRemove.includes(line.accessor))

    return {filteredColumns, filteredData}
}

export {frappeExceptionFormatter, getBase64, openPdf, getCurrentWeekDates, filteredTableData}
