import {
    Button,
    Modal,
    ModalBody,
    ModalCloseButton,
    ModalContent,
    ModalFooter,
    ModalHeader,
    ModalOverlay,
    ThemeTypings,
    ThemingProps,
    UseDisclosureProps
} from "@chakra-ui/react";

interface IProps {
    modalTitle: string,
    isOpen: UseDisclosureProps["isOpen"],
    onClose: UseDisclosureProps["onClose"] | undefined,
    children: any,
    primaryAction?: () => void,
    primaryButtonText?: string
    primaryButtonColorScheme?: ThemeTypings["colorSchemes"]
    form?: string,
    size?: ThemingProps<"Modal">["size"],
    primaryActionButtonType?: 'button' | 'submit' | 'reset',
    isLoading?: boolean,
    disablePrimaryButton?: boolean,
    hideCancelButton?: boolean,
    hidePrimaryButton?: boolean
}

const ChakraModal = ({
    isOpen = false,
    modalTitle = "Modal Title",
    onClose = () => { },
    children,
    form,
    primaryActionButtonType = 'submit',
    primaryButtonText = "Create",
    primaryButtonColorScheme = 'teal',
    primaryAction,
    size = 'md',
    hideCancelButton = false,
    hidePrimaryButton = false,
    isLoading = false,
    disablePrimaryButton = false
}: IProps) => {

    return (
        <>
            <Modal
                closeOnOverlayClick={!isLoading}
                isOpen={isOpen}
                motionPreset='slideInBottom'
                onClose={onClose}
                size={size}
            >
                <ModalOverlay />
                <ModalContent>
                    <ModalHeader>{modalTitle}</ModalHeader>
                    <ModalCloseButton autoFocus={false} _focus={{ border: 'none' }} />
                    <ModalBody pb={1}>
                        {children}
                    </ModalBody>

                    <ModalFooter>
                        {!hidePrimaryButton && (<Button size={"sm"} isDisabled={disablePrimaryButton || isLoading} isLoading={isLoading} type={primaryActionButtonType} form={form} onClick={primaryAction} colorScheme={primaryButtonColorScheme} mr={3}>
                            {primaryButtonText}
                        </Button>)}
                        {!hideCancelButton && <Button size={"sm"} colorScheme="red" onClick={() => onClose()}>Cancel</Button>}
                    </ModalFooter>
                </ModalContent>
            </Modal>
        </>
    )
}

export default ChakraModal