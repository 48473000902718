import {TabPanel} from "@chakra-ui/react"
import ResourceListDataTable from "./ResourceListDataTable";


const ResourceListTab = () => {


    return (
        <TabPanel className="!p-0 !pt-3">
            <ResourceListDataTable/>
        </TabPanel>
    )
}

export default ResourceListTab;