import {
    Box,
    Button,
    HStack,
    Input,
    Stack,
    Table,
    Tbody,
    Td,
    Text,
    Th,
    Thead,
    ThemeTypings,
    ThemingProps,
    Tr
} from "@chakra-ui/react";
import _ from "lodash";
import search from "multi-search";
import { useEffect, useState } from 'react';
import { AiOutlinePrinter } from "react-icons/ai";
import { FaPlus } from "react-icons/fa";
import ColumnSort from "./components/ColumnSort";
import TablePagination from "./components/TablePagination";
import TableTr from "./components/TableTr";
import LengthPaginations from "./components/LengthPaginations";

const EMPTY_FUNC = () => {
}

export interface IColumn {
    header: string,
    width?: number,
    accessor: string | '',
    conditions?: ChakraTableColumnCondition,
    className?: string,
    conditionClassName?: (item: object) => String,
    customRenderer?: (value: string | number | null, item: object) => JSX.Element,
    disabled?: boolean,
    disableSorting?: boolean,
    options?: Array<ChakraTableColumnOption>
    type?: 'link',
    headerOptions?: any
}

export type ChakraTableColumnOption = { label: string, onClick: (item: object) => void, conditionalDisable?: (item: object) => boolean }

export interface ChakraTableColumnCondition {
    [key: string | number]: ThemeTypings["colorSchemes"]
}

export type ChakraTableCreatable = {
    text?: string | undefined,
    onClick: any,
    isLoading?: boolean,
    buttonColorScheme?: ThemeTypings["colorSchemes"]
}

export type ChakraTableColumns = Array<IColumn>

export type TableAction = {
    text: string,
    onClick: () => any,
    isDisabled?: boolean,
    buttonColorScheme?: ThemeTypings["colorSchemes"]
    buttonVariant?: ThemingProps<"Button">["variant"]
}

export interface IChakraTableProps {
    title?: string,
    columns: ChakraTableColumns,
    data: Array<object>,
    onSelected?: (line: object) => void,
    disableRowSelection?: boolean,
    disableBorder?: boolean,
    outerPadding?: boolean,
    header?: boolean,
    searchable?: boolean,
    searchPlaceholder?: string,
    creatable?: ChakraTableCreatable,
    customFilterRenderer?: JSX.Element,
    size?: ThemingProps<"Table">["size"],
    height?: number,
    pagination?: boolean,
    lengthPaginations?: boolean,
    pageCount?: number,
    totalResult?: number,
    isLoading?: boolean,
    isFetching?: boolean,
    onSearch?: (text: string) => void,
    actions?: Array<TableAction>
    onPrint?: (columns: Array<object>, tableData: Array<object>) => void,
    onLength?: (length: number) => void,
    onPage?: (page: number) => void
}

const SHIMMER_DATA = [1, 1, 1, 1, 1];

const ChakraTable = ({
    isLoading,
    isFetching,
    size = 'md',
    disableBorder = false,
    height,
    title,
    creatable,
    customFilterRenderer,
    searchable = true,
    header = false,
    columns,
    data,
    searchPlaceholder = "Search",
    onSelected = EMPTY_FUNC,
    disableRowSelection,
    pagination,
    lengthPaginations,
    pageCount = 1,
    totalResult = 0,
    outerPadding = false,
    onSearch,
    actions = [],
    onPrint,
    onLength,
    onPage
}: IChakraTableProps) => {
    const [tableData, setTableData] = useState([])
    const [isPrinting, setIsPrinting] = useState<boolean>(false)

    useEffect(() => {
        setTableData(data as any)
        setIsPrinting(false)
    }, [JSON.stringify(data)])

    function selectedRow(row: any) {
        onSelected(row)
    }

    const searchHandler = (e: any) => {
        if (onSearch) {
            onSearch(e.target.value)
            return
        }
        let filtered = search(data, e.target.value)
        setTableData(filtered as any)
    }

    const shimmerMarkup = SHIMMER_DATA.map((col: any, index: any) => <TrShimmer key={index} columns={columns} />)

    const rowMarkup = (tableData || []).map((item: any, index: any) => (
        <TableTr disablePointer={disableRowSelection} onSelected={(selectedItem: any) => {
            selectedRow(selectedItem)
        }} key={index} index={index} columns={columns} item={item} />
    ))

    const actionMarkup = actions.map((action: TableAction, index: number) => (
        <Button key={index}
            colorScheme={action.buttonColorScheme ?? "blackAlpha"}
            size={"sm"}
            isDisabled={Boolean(action.isDisabled)}
            variant={action.buttonVariant ?? 'outline'}
            onClick={action.onClick || (() => {
            })}>{action.text}</Button>
    ))

    const onTablePrint = () => {
        if (onPrint) {
            setIsPrinting(true)
            onPrint(columns, tableData);
            setTimeout(() => {
                setIsPrinting(false)
            }, 5000);
        }
    }

    return (
        <>
            <Stack border={disableBorder ? '0px' : '1px'} spacing={0} borderColor={"gray.200"} bg={"white"}
                borderRadius={"md"} mx={outerPadding ? 3 : 0}>
                {
                    // Table Header
                    header ? (
                        <HStack mx={0} p={3} pb={5} pt={5} justifyContent={"space-between"} bg={'gray.50'}
                            rounded={'md'}>
                            {/* Table header title */}
                            {title ? <Text fontSize={size == 'sm' ? 'lg' : 'xl'}
                                fontWeight={"semibold"}>{title}</Text> : null}
                            <HStack>
                                {actionMarkup}
                                <HStack className="transition-opacity" opacity={isLoading ? 0 : 1}>
                                    {customFilterRenderer ? customFilterRenderer : null}
                                </HStack>
                                {(onPrint && !_.isEmpty(tableData)) && (
                                    <Button isLoading={isPrinting} opacity={isLoading ? 0 : 1} iconSpacing={0} onClick={onTablePrint} size={"sm"} aria-label="Print"
                                        colorScheme={'gray'} variant={"outline"} className="mr-0 transition-opacity !border-gray-300 shadow-sm !h-[35px]" leftIcon={<AiOutlinePrinter className="!mr-0 text-gray-700 text-xl" />}>
                                    </Button>
                                )}
                                {searchable ?
                                    <Input opacity={isLoading ? 0 : 1} className="transition-opacity !h-[35px] duration-100"
                                        size={size} onChange={searchHandler} shadow={"sm"} borderColor={'gray.300'} width={250}
                                        placeholder={searchPlaceholder} borderRadius='md' type='text' /> : null}

                                {/* Creatable */}
                                {!_.isEmpty(creatable) ? (
                                    <Button size={size} aria-label="Add new" isDisabled={creatable?.isLoading}
                                        onClick={creatable?.onClick || EMPTY_FUNC}
                                        colorScheme={creatable.buttonColorScheme || 'blue'} leftIcon={<FaPlus />}>
                                        <HStack alignItems={"center"}>
                                            {creatable?.text ? <Text>{creatable.text}</Text> : null}
                                        </HStack>
                                    </Button>
                                ) : null}
                            </HStack>
                        </HStack>
                    ) : null
                }

                {header ? <hr></hr> : null}

                {/* eslint-disable-next-line react/jsx-no-undef */}
                <Box overflowY={"auto"} h={height ? height : undefined}>
                    <Table size={size} className="rounded-md" style={{ marginTop: header ? '0px' : '0px !important' }}
                        variant='simple'>
                        <Thead>
                            <Tr>
                                {columns?.map((item: any, index: any) => {
                                    if (!item?.disabled) {
                                        return (
                                            <Th
                                                style={{ width: item?.width ? item.width : 'unset' }}
                                                textAlign={item?.header == "Actions" ? "center" : "left"} py={2}
                                                key={index}>
                                                <div className={`flex items-start ${_.get(item, 'headerOptions.className')}`}>
                                                    {item.header}
                                                    {!item.disableSorting && <ColumnSort item={item} setTableData={setTableData} tableData={tableData} />}
                                                </div>
                                            </Th>
                                        )
                                    }
                                })}
                            </Tr>
                        </Thead>
                        <Tbody>
                            {(isLoading || isFetching) && shimmerMarkup}
                            {!isLoading && !isFetching && rowMarkup}
                        </Tbody>
                    </Table>
                </Box>
                {(!isLoading && !isFetching && _.isEmpty(tableData)) ?
                    <Text className="animation-no-data" pb={3} w={'full'} fontWeight={'semibold'} textAlign={'center'}>No
                        Data</Text> : null}
                <div className="flex">
                    {lengthPaginations &&
                        <LengthPaginations onLength={onLength} isLoading={isLoading || isFetching} totalResult={totalResult} />}
                    {pagination && <TablePagination onPage={onPage} isLoading={isLoading || isFetching} pageCount={pageCount} />}
                </div>
            </Stack>
        </>
    )
}

const TrShimmer = ({ columns = [] }: any) => {
    return (
        <Tr>
            {columns.map((col: any, index: any) => <Td key={index}>
                <div className="animate-pulse w-[75px] h-[15px] bg-gray-100 rounded-md border"></div>
            </Td>)}
        </Tr>
    )
}

export default ChakraTable