import moment from "moment";
import { Calendar, momentLocalizer } from "react-big-calendar";
import { ACTIVITY } from "../../../consts/methods.consts";
import { useGetQuery } from "../../../services/api.service";
import { ACTIVITY_TAGS } from "../../../consts/tags.consts";
import ViewCalendarEventModel from "../view-calendar-event-modal/ViewCalendarEventModel";
import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";

moment.locale("en-GB");
const localizer = momentLocalizer(moment);

interface IActivityCalendarTab {
    id: string | undefined,
    type: "Student" | "Staff"
}

const ActivityCalendarTab = ({ id, type }: IActivityCalendarTab) => {

    const [event, setEvent] = useState(null)
    const _handoverDisclosure = useDisclosure();

    const { data, isLoading } = useGetQuery({
        method: ACTIVITY.LIST_FOR_CALENDAR,
        body: {
            id,
            type: type
        },
        providesTags: [ACTIVITY_TAGS.LIST]
    })

    const _onSelected = (line: any) => {
        setEvent(line)
        _handoverDisclosure.onOpen()
    }

    const events = data && data['message']['data'] && data['message']['data'].length > 0 ? data['message']['data'].map((line: any) => ({
        id: line['participant_id'],
        title: line['title'] || 'No Description Provided',
        allDay: false,
        start: line['start_time'],
        end: line['start_time'], 
        json: line
    })) : []

    return (
        <div className="!w-full !h-full">
            <ViewCalendarEventModel title={"View Activity"} data={event} disclosure={_handoverDisclosure} />
            <Calendar
                views={["month"]}
                selectable
                localizer={localizer}
                defaultDate={new Date()}
                defaultView="month"
                events={events}
                style={{ height: "100vh" }}
                onSelectEvent={_onSelected}
                onSelectSlot={() => {
                }}
            />
        </div>
    )
}

export default ActivityCalendarTab