import {useRoutes} from "react-router-dom";
import {ExternalLayout, InternalLayout} from "../components/layouts";
import {
    ClassListPage,
    ContractorListPage,
    CreateNewPage,
    DepartmentListPage,
    DisclaimerListPage,
    EmergencyPage,
    GroupListPage,
    GuardianListPage,
    GuestListPage,
    HomePage,
    LoginPage,
    NewContractorPage,
    NewDisclaimerPage,
    NewGroupPage,
    NewGuardianPage,
    NewGuestPage,
    NewParcelPage,
    NewResourcePage,
    NewStaffPage,
    NewStudentPage,
    NewTherapistPage,
    ParcelListPage,
    ResourceListPage,
    StaffListPage,
    StationListPage,
    StudentListPage,
    TherapistListPage,
    TimeSheetPage,
    ViewContractorPage,
    ViewDisclaimerPage,
    ViewGroupPage,
    ViewGuardianPage,
    ViewGuestPage,
    ViewPage,
    ViewStaffPage,
    ViewStudentPage,
    ViewTherapistPage,
    VisitorCompliancePage
} from "../components/pages";
import ViewParcelPage from "../components/pages/parcel/ViewParcelPage";
import ActivityListPage from "../components/pages/activity/ActivityListPage";
import NewActivityPage from "../components/pages/activity/NewActivityPage";
import ViewActivityPage from "../components/pages/activity/ViewActivityPage";
import VisitLogPage from "../components/pages/visit-log/VisitLogPage";
import TourListPage from "../components/pages/tour/TourListPage";
import ViewTourPage from "../components/pages/tour/ViewTourPage";
import ViewResourcePage from "../components/pages/resource/ViewResourcePage";
import ResourceAllocationsPage from "../components/pages/resource-allocations/ResourceAllocationsPage";
import RestPasswordPage from "../components/pages/reset-password/RestPasswordPage";
import DataImportPage from "../components/pages/data-import/DataImportPage";

const RouterConfigs = () => {
    const element = useRoutes([
        {
            path: "/",
            element: <ExternalLayout/>,
            children: [
                {
                    index: true,
                    element: <LoginPage/>
                },
                {
                    path: "/change-password/:key",
                    element: <RestPasswordPage/>
                },
            ]
        },
        {
            path: "/app",
            element: <InternalLayout/>,
            children: [
                {index: true, element: <HomePage/>},
                {path: 'students', element: <StudentListPage/>},
                {path: 'students/new', element: <NewStudentPage/>},
                {path: 'students/:id', element: <ViewStudentPage/>},

                {path: 'staff', element: <StaffListPage/>},
                {path: 'staff/new', element: <NewStaffPage/>},
                {path: 'staff/:id', element: <ViewStaffPage/>},

                {path: 'resources', element: <ResourceListPage/>},
                {path: 'resources/new', element: <NewResourcePage/>},
                {path: 'resources/:id', element: <ViewResourcePage/>},

                {path: 'departments', element: <DepartmentListPage/>},
                {path: 'classes', element: <ClassListPage/>},
                {path: 'stations', element: <StationListPage/>},

                {path: 'guardians', element: <GuardianListPage/>},
                {path: 'guardians/new', element: <NewGuardianPage/>},
                {path: 'guardians/:id', element: <ViewGuardianPage/>},

                {path: 'therapists', element: <TherapistListPage/>},
                {path: 'therapists/new', element: <NewTherapistPage/>},
                {path: 'therapists/:id', element: <ViewTherapistPage/>},

                {path: 'contractors', element: <ContractorListPage/>},
                {path: 'contractors/new', element: <NewContractorPage/>},
                {path: 'contractors/:id', element: <ViewContractorPage/>},

                {path: 'guests', element: <GuestListPage/>},
                {path: 'guests/new', element: <NewGuestPage/>},
                {path: 'guests/:id', element: <ViewGuestPage/>},

                {path: 'group', element: <GroupListPage/>},
                {path: 'group/new', element: <NewGroupPage/>},
                {path: 'group/:id', element: <ViewGroupPage/>},

                {path: 'disclaimers', element: <DisclaimerListPage/>},
                {path: 'disclaimers/new', element: <NewDisclaimerPage/>},
                {path: 'disclaimers/:id', element: <ViewDisclaimerPage/>},

                {path: 'parcels', element: <ParcelListPage/>},
                {path: 'parcels/new', element: <NewParcelPage/>},
                {path: 'parcels/:id', element: <ViewParcelPage/>},

                {path: 'activity', element: <ActivityListPage/>},
                {path: 'activity/new', element: <NewActivityPage/>},
                {path: 'activity/:id', element: <ViewActivityPage/>},


                {path: 'tour', element: <TourListPage/>},
                // { path: 'tour/new', element: <NewActivityPage /> },
                {path: 'tour/:id', element: <ViewTourPage/>},

                {path: 'new', element: <CreateNewPage/>},
                {path: 'view/:id', element: <ViewPage/>},

                {path: 'emergency', element: <EmergencyPage/>},
                {path: 'timesheet', element: <TimeSheetPage/>},

                {path: 'visit-log', element: <VisitLogPage/>},

                {path: 'reports/visitor-compliance', element: <VisitorCompliancePage/>},
                {path: 'resource-allocations', element: <ResourceAllocationsPage/>},

                {path: 'data-import', element: <DataImportPage/>}

            ],
        },
        {path: "*", element: <h1>page not found</h1>},
    ]);

    return element;
};

export default RouterConfigs;