import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import { useGetQuery } from "../../../services/api.service";
import { STAFF } from "../../../consts/methods.consts";
import { STAFF_TAGS } from "../../../consts/tags.consts";
import { useTableDataProvider } from "../../hooks";
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { FormDropdown, FormDropdownOptions } from "../../common/form-controllers/FormDropdown";
import { IOpenPdf, openPdf } from "../../../utils/utils";
import { Link, useToast } from "@chakra-ui/react";
import { ToastService } from "../../../services/toast.service";
import { NavigateFunction, useNavigate } from "react-router-dom";

const typeOptions: FormDropdownOptions = [
    { label: "Staff", value: "ACADEMIC" },
    { label: "Education Support", value: "NON_ACADEMIC" }
]

const statusOptions: FormDropdownOptions = [
    { label: "Active", value: "Active" },
    { label: "Inactive", value: "Inactive" },
    { label: "Left", value: "Left" }
]

export const STAFF_LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        header: "Staff Id",
        accessor: "staff_id",
        // customRenderer: (value, item) => {
        //     return tblStaffIdCellMarkup(value, item)
        // }
    },
    {
        header: "First Name",
        accessor: "first_name",
    },
    {
        header: "Last Name",
        accessor: "last_name",
    },
    {
        header: "Type",
        accessor: "type",
        conditions: {
            "Staff": "green",
            "Education Support": "purple"
        }
    },
    {
        header: "Email",
        accessor: "email",
    },
    {
        header: "Mobile",
        accessor: "mobile",
    },
    {
        header: "Phone",
        accessor: "phone",
    },
    {
        header: "Status",
        accessor: "status",
        conditions: {
            'Inactive': 'red',
            'Active': 'green',
            'Left': "orange"
        },

    },
]

const tblStaffIdCellMarkup = (value: any, item: any) => {
    return (
        <Link color="#0073E5" href={`/app/staff/${item?._id}`}
            isExternal={true}>
            {value}
        </Link>
    )
}

const StaffListDataTable = () => {
    const navigate: NavigateFunction = useNavigate()
    const toast = new ToastService(useToast());
    const [filters, setFilters] = useState<object>({})
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)


    const { data, isFetching, isLoading } = useGetQuery({
        method: STAFF.LIST,
        body: {
            page,
            length: Number(length),
            ...filters
        },
        providesTags: [STAFF_TAGS.LIST]
    })

    const { dataList, pageCount, totalResult } = useTableDataProvider(data);

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({ ...filters, ...value })
    }, 200)

    const tableLineClickHandler = (line: any) => {
        navigate(`${line._id}`)
    }

    const onPrint = async (columns: any, data: any) => {
        const config: IOpenPdf = {
            tableData: {
                columns,
                data,
                filters,
                info: {
                    title: "Staff List",
                    print_footer: true
                }
            },
            toast
        }

        openPdf(config);
    }

    const customFilters = (
        <div className="flex gap-2 items-center">
            <FormDropdown minW="40" size={"sm"} options={statusOptions} isRequired formik={formik} name="status"
                placeholder="Status" />
            <FormDropdown minW="40" size={"sm"} options={typeOptions} isRequired formik={formik} name="type"
                placeholder="Type" />
        </div>
    )

    return (
        <ChakraTable onPage={setPage} onLength={setLength} onPrint={onPrint} pageCount={pageCount}
            isLoading={isLoading} isFetching={isFetching} pagination size={"sm"} header title="Staff"
            customFilterRenderer={customFilters} lengthPaginations totalResult={totalResult}
            onSearch={onSearchHandler} columns={STAFF_LIST_TAB_COLUMNS}
            data={dataList} onSelected={tableLineClickHandler} />
    )
}

export default StaffListDataTable