import {NavigateFunction, useNavigate} from "react-router";
import {PageHeader} from "../..";
import {PageHeaderActions} from "../../common/page-header/PageHeader";
import ActivityListDataTable from "./components/ActivityListDataTable";


const ActivityListPage = () => {
    const navigate: NavigateFunction = useNavigate()

    const actions: PageHeaderActions = [
        {
            text: "Create New",
            onClick: () => navigate("new"),
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        },
    ]


    return (
        <div>
            <PageHeader subtitle="Manage EdPass Activities" title={`Activities`} actions={actions}/>
            <ActivityListDataTable/>
        </div>
    )
}

export default ActivityListPage;