import React from "react";
import {Image, Tooltip} from "@chakra-ui/react";

interface IProps {
    value: any
}

const tooltipMarkup = (value: any) => {
    return (
        <Image w={52} h={32} src={value} alt="sign in out signature"/>
    )
}
const SignatureCell: React.FC<IProps> = ({value}) => {
    if (!value) {
        return <div className="flex justify-center">__</div>
    }

    return (
        <>
            <Tooltip hasArrow placement="right" shadow={"2xl"} borderRadius={"2xl"} label={tooltipMarkup(value)}
                     openDelay={100} closeDelay={300} bg="white">
                <div className="flex justify-center">
                    <Image maxW={10} maxH={6} src={value} alt="signature"/>
                </div>
            </Tooltip>
        </>
    )
}

export default SignatureCell
