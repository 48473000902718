import {PageHeader} from "../../index";
import {SimpleGrid} from "@chakra-ui/layout";
import {FormInput} from "../../common/form-controllers/FormInput";
import FormSelect from "../../common/form-controllers/FormSelect";
import {RESOURCE, SCHOOL, VALIDATION} from "../../../consts/methods.consts";
import {FormTextArea} from "../../common/form-controllers/FormTextArea";
import {PageHeaderActions} from "../../common/page-header/PageHeader";
import {useFormik} from "formik";
import * as Yup from "yup";
import {RESOURCE_TAGS} from "../../../consts/tags.consts";
import _ from "lodash";
import {useGetQuery, usePostMutation} from "../../../services/api.service";
import {ToastService} from "../../../services/toast.service";
import {Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, useToast} from "@chakra-ui/react";
import {useParams, useSearchParams} from "react-router-dom";
import {useEffect, useState} from "react";
import {useObjChange} from "../../hooks";
import InnerLoading from "../../common/inner-loading/InnterLoading";
import AllocateResourceUserModal from "./components/ResourceAllocationUserModal";
import DeallocateResourceModal from "../../common/deallocate-resource-modal/DeallocateResourceModal";
import AssignmentHistoryDataTable from "./components/AssignmentHistoryDataTable";

const ViewResourcePage = () => {
    const {id} = useParams()
    const [searchParams] = useSearchParams();
    const [create, {isLoading: isMutating}] = usePostMutation();
    const toast = new ToastService(useToast());
    const [resourceIdQ, setResourceIdQ] = useState(null)
    const resourceAllocationDisclosure = useDisclosure()
    const handoverDisclosure = useDisclosure()

    const {data: _data, isLoading, isFetching} = useGetQuery({
        method: RESOURCE.GET,
        body: {
            id,
            page: Number(searchParams.get("page"))
        },
        providesTags: [RESOURCE_TAGS.VIEW]
    })

    const {data: _validationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_RESOURCE_ID_UPDATE,
        body: {
            _id: id,
            resource_id: resourceIdQ
        },
        providesTags: [RESOURCE_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {})
    const title = _.get(data, ['title'])
    const resourceId = _.get(data, ['resource_id'])
    const availability = _.get(data, 'available')

    const statusConfig = {
        status: availability === 0 ? "Not Available" : "Available",
        statusColorSchemes: availability === 1 ? "green" : "red"
    }

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            resource_id: Yup.string().required("Resource ID is required"),
            title: Yup.string().required("Title is required"),
            type: Yup.string().required("Resource Type is required"),
            department: Yup.string().required("Department is required")
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: RESOURCE.UPDATE,
                    body: {
                        _id: id,
                        ...values
                    },
                    invalidatesTags: [RESOURCE_TAGS.VIEW, RESOURCE_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription("Resource data updated").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to update resource. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const {isChanged, setInitialObj} = useObjChange(formik.values)

    useEffect(() => {
        if (!_.isEmpty(data)) {
            let allocated_person = _.get(data, ['allocation', 'full_name'])
            formik.setValues({...data, allocated_person})
            setInitialObj({...data, allocated_person})
        }
    }, [JSON.stringify(data)])

    useEffect(() => {
        resourceIdQHandler(_.get(formik.values, "resource_id"))
    }, [_.get(formik.values, "resource_id")])

    const resourceIdQHandler = _.debounce((value: any) => {
        setResourceIdQ(value)
    }, 200)

    const actions: PageHeaderActions = [
        {
            text: availability === 0 ? "Handover" : "Allocate",
            onClick: availability === 0 ? handoverDisclosure.onOpen : resourceAllocationDisclosure.onOpen,
            buttonVariant: "outline",
            buttonColorScheme: "messenger"
        },
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isMutating
        },
    ]

    const handoverModalData = {
        resource_title: title,
        allocated_id: _.get(data, ['allocation', 'name'])
    }

    const allocatedPersonMarkup = availability === 0 && (
        <div className=" border rounded p-3 mt-2">
            <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 4}} spacing={3}>
                <FormInput isReadOnly label="Allocated Person" formik={formik} name="allocated_person"/>
            </SimpleGrid>
        </div>
    )

    if (isLoading || isFetching) {
        return (
            <>
                <PageHeader isLoading={true} enableBackButton actions={actions} title={""} subtitle={""}/>
                <InnerLoading/>
            </>
        )
    }

    const resourceIDCustomError = (_validationData?.message > 0) && (
        <p className=" text-sm text-red-500">Resource ID already taken.</p>
    )

    return (
        <>
            <PageHeader {...statusConfig} isLoading={isLoading} enableBackButton actions={actions} title={title}
                        subtitle={resourceId}/>
            <AllocateResourceUserModal id={id} title={"Allocate Resource"} type={"student"}
                                       disclosure={resourceAllocationDisclosure}/>
            <DeallocateResourceModal title={"Handover"} data={handoverModalData} disclosure={handoverDisclosure}/>

            <Tabs>
                <TabList>
                    <Tab>Info</Tab>
                    <Tab>Assignment History</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel className="!p-0 !pt-3">
                        <div className=" border rounded p-3">
                            <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 4}} spacing={3}>
                                <FormInput customMessage={resourceIDCustomError} isRequired label="Resource Id"
                                           formik={formik}
                                           name="resource_id"/>
                                <FormInput isRequired label="Name" formik={formik} name="title"/>
                                <FormSelect method={SCHOOL.GET_RESOURCE_TYPE_LIST_FOR_DROPDOWN} isRequired
                                            label="Resource Type"
                                            formik={formik} name="type"/>
                                <FormSelect method={SCHOOL.GET_DEPARTMENT_LIST_FOR_DROPDOWN} isRequired
                                            label="Department"
                                            formik={formik} name="department"/>
                                <FormTextArea label="Note" formik={formik} name="description"/>
                            </SimpleGrid>
                        </div>
                        {allocatedPersonMarkup}
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <AssignmentHistoryDataTable id={id ?? ""}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}

export default ViewResourcePage