import {Tab, TabList, TabPanels, Tabs} from "@chakra-ui/react";
import {NavigateFunction, useNavigate} from "react-router";
import {PageHeader} from "../..";
import {PageHeaderActions} from "../../common/page-header/PageHeader";
import ResourceListTab from "./components/ResourceListTab";
import ResourceTypeTab from "./components/ResourceTypeTab";

const ResourceListPage = () => {
    const navigate: NavigateFunction = useNavigate()

    const actions: PageHeaderActions = [
        {
            text: "Create New",
            onClick: () => navigate("new"),
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    return (
        <div>
            <PageHeader subtitle="View and Manage resources" title={`Resources`} actions={actions} />
            <Tabs>
                <TabList>
                    <Tab>Resources</Tab>
                    <Tab>Types</Tab>
                </TabList>

                <TabPanels>
                    <ResourceListTab />
                    <ResourceTypeTab />
                </TabPanels>
            </Tabs>
        </div>
    )
}

export default ResourceListPage;