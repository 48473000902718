import { SimpleGrid } from "@chakra-ui/layout";
import { Tab, TabList, TabPanel, TabPanels, Tabs, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { FormDropdown, FormDropdownOptions } from "../../common/form-controllers/FormDropdown";
import { ToastService } from "../../../services/toast.service";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { GUEST } from "../../../consts/methods.consts";
import { FILTER_TAGS, GUEST_TAGS } from "../../../consts/tags.consts";
import { useObjChange, useStatusProvider } from "../../hooks";
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader";
import { FormInput } from "../../common/form-controllers/FormInput";
import { FormCheckBox } from "../../common/form-controllers/FormCheckBox";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";
import { InnerLoading } from "../../index";
import ChakraTable, { ChakraTableColumns } from "../../common/chakra-table/ChakraTable";
import ActivityTab from "../../common/activity-tab/ActivityTab";
import { PHONE_REG_EXP } from "../../../consts/app.consts";


const gender: FormDropdownOptions = [
    { label: "Male", value: "Male" },
    { label: "Female", value: "Female" }
]

export const PARTICIPANTS_COLUMNS: ChakraTableColumns = [
    {
        header: "First Name",
        accessor: "first_name"
    },
    {
        header: "Middle Name",
        accessor: "middle_name",
    },
    {
        header: "Last Name",
        accessor: "last_name",
    },
    {
        header: "Type",
        accessor: "type",
    }
]

const ViewTourPage = () => {
    const toast = new ToastService(useToast());
    const { id } = useParams()
    const [create, {isLoading: isMutating}] = usePostMutation();
    const { data: _data, isLoading, isFetching } = useGetQuery({
        method: GUEST.GET,
        body: { id },
        providesTags: [GUEST_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {});
    const therapistName = `${data?.first_name || ''} ${data?.last_name || ''}`
    const dataList = _.get(data, "participants", [])

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            gender: Yup.string().required("Gender is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            mobile: Yup.string().matches(PHONE_REG_EXP, 'Mobile number is not valid')
                .required('Mobile number is required')
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: GUEST.UPDATE,
                    body: values,
                    invalidatesTags: [GUEST_TAGS.VIEW, GUEST_TAGS.LIST, FILTER_TAGS.EXTERNAL_USER_SELECTOR]
                }).unwrap();

                const newId = _.get(res, 'message')
                if (!newId) {
                    throw new Error("Unable to update Guest")
                }

                toast.setTitle("Success").setDescription("Guest updated").showSuccessToast();
            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to update Guest. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const { isChanged, setInitialObj } = useObjChange(formik.values)
    const statusConfig = useStatusProvider(data);

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [JSON.stringify(data)])

    const actions: PageHeaderActions = [
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isMutating
        },
    ]

    if (isLoading || isFetching) {
        return (
            <>
                <PageHeader isLoading={true} enableBackButton actions={actions} title={therapistName}
                    subtitle={"Tour"} />
                <InnerLoading />
            </>
        )
    }

    return (
        <>
            <PageHeader {...statusConfig} isLoading={isLoading} enableBackButton actions={actions} title={therapistName}
                subtitle={"Tour"} />


            <Tabs>
                <TabList>
                    <Tab>General Information</Tab>
                    <Tab>Activities</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel className="!p-0 !pt-3">
                        <div className="animation-form border rounded p-3">
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={3}>
                                <FormInput autoComplete="off" isRequired label="First Name" formik={formik}
                                    name="first_name" />
                                <FormInput autoComplete="off" isRequired label="Last Name" formik={formik}
                                    name="last_name" />
                                <FormDropdown options={gender} isRequired label="Gender" formik={formik} name="gender" />
                                <FormInput isRequired type="email" label="Email" formik={formik} name="email" />
                                <FormInput placeholder="0XXXXXXXXX" isRequired type="tel" label="Mobile" formik={formik} name="mobile" />
                                <FormCheckBox label="Active" formik={formik} name="active" />
                            </SimpleGrid>
                        </div>
                        <div className="animation-form border rounded p-3 mt-3 mb-3">
                            <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={3}>
                                <FormTextArea label="Notes" formik={formik} name="notes" />
                            </SimpleGrid>
                        </div>
                        <ChakraTable header size="sm" title="Participants" columns={PARTICIPANTS_COLUMNS}
                            data={dataList} />
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ActivityTab id={id} type={"Guest"} customFilter={true} />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}

export default ViewTourPage
