import {SimpleGrid} from "@chakra-ui/layout";
import {useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import _ from "lodash";
import {NavigateFunction, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {THERAPIST, VALIDATION} from "../../../../consts/methods.consts";
import {FILTER_TAGS, THERAPIST_TAGS} from "../../../../consts/tags.consts";
import {useGetQuery, usePostMutation} from "../../../../services/api.service";
import {ToastService} from "../../../../services/toast.service";
import {FormDropdown, FormDropdownOptions} from "../../../common/form-controllers/FormDropdown";
import {FormInput} from "../../../common/form-controllers/FormInput";
import PageHeader, {PageHeaderActions} from "../../../common/page-header/PageHeader";
import DisclaimerList from "../components/DisclaimerList";
import {FormCheckBox} from "../../../common/form-controllers/FormCheckBox";
import {InnerLoading} from "../../../index";
import {PHONE_REG_EXP} from "../../../../consts/app.consts";
import {useEffect, useState} from "react";

const gender: FormDropdownOptions = [
    {label: "Male", value: "Male"},
    {label: "Female", value: "Female"}
]

const titles: FormDropdownOptions = [
    {label: "Mr", value: "Mr"},
    {label: "Ms", value: "Ms"},
    {label: "Miss", value: "Miss"},
    {label: "Mrs", value: "Mrs"},
    {label: "Mx", value: "Mx"}
]

const NewTherapistPage = () => {
    const navigate: NavigateFunction = useNavigate();
    const [create, {isLoading}] = usePostMutation();
    const toast = new ToastService(useToast());
    const [therapistEmailQ, setTherapistEmailQ] = useState(null)
    const [therapistMobileQ, setTherapistMobileQ] = useState(null)

    const {data: _emailValidationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_THERAPIST_EMAIL_CREATE,
        body: {
            email: therapistEmailQ
        },
        providesTags: [THERAPIST_TAGS.VIEW]
    })

    const {data: _mobileValidationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_THERAPIST_MOBILE_CREATE,
        body: {
            mobile: therapistMobileQ
        },
        providesTags: [THERAPIST_TAGS.VIEW]
    })

    const formik = useFormik({
        initialValues: {
            active: true
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            gender: Yup.string().required("Gender is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            mobile: Yup.string().matches(PHONE_REG_EXP, 'Mobile number is not valid')
                .required('Mobile number is required')
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: THERAPIST.ADD,
                    body: values,
                    invalidatesTags: [THERAPIST_TAGS.LIST, FILTER_TAGS.EXTERNAL_USER_SELECTOR]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Unable to register therapist")
                }

                toast.setTitle("Success").setDescription("Therapist registered to the system").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/therapists/${newId}`, {replace: true});
                }, 750)

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to register therapist. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isLoading
        },
    ]

    useEffect(() => {
        therapistEmailQHandler(_.get(formik.values, "email"))
        therapistMobileQHandler(_.get(formik.values, "mobile"))
    }, [_.get(formik.values, "email"), _.get(formik.values, "mobile")])

    const therapistEmailQHandler = _.debounce((value: any) => {
        setTherapistEmailQ(value)
    }, 200)

    const therapistMobileQHandler = _.debounce((value: any) => {
        setTherapistMobileQ(value)
    }, 200)

    const therapistEmailCustomError = (_emailValidationData?.message > 0) && (
        <p className="mt-1 text-sm text-red-500">Email already taken.</p>
    )

    const therapistMobileCustomError = (_mobileValidationData?.message > 0) && (
        <p className="mt-1 text-sm text-red-500">Mobile number already taken.</p>
    )

    return (
        <>
            <PageHeader isLoading={isLoading} enableBackButton actions={actions} title="Create New Therapists"
                        subtitle="Therapist"/>
            {
                isLoading ? <InnerLoading/> :
                    <>
                        <div className="border rounded p-3">
                            <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 4}} spacing={3}>
                                <FormDropdown options={titles} isRequired label="Title" formik={formik} name="title"/>
                                <FormInput autoComplete="off" isRequired label="First Name" formik={formik}
                                           name="first_name"/>
                                <FormInput autoComplete="off" isRequired label="Last Name" formik={formik}
                                           name="last_name"/>
                                <FormDropdown options={gender} isRequired label="Gender" formik={formik} name="gender"/>
                                <FormInput isRequired type="email" label="Email" formik={formik} name="email"
                                           customMessage={therapistEmailCustomError}/>
                                <FormInput placeholder="0XXXXXXXXX" isRequired type="tel" label="Mobile" formik={formik}
                                           name="mobile" customMessage={therapistMobileCustomError}/>
                                <FormInput type="text" label="Company" formik={formik} name="company" />
                                <FormCheckBox label="Active" formik={formik} name="active"/>
                            </SimpleGrid>
                        </div>

                        <DisclaimerList type="Therapist"/>
                    </>
            }
        </>
    )
}

export default NewTherapistPage;