import ChakraTable, {ChakraTableColumns} from "../../../common/chakra-table/ChakraTable";
import {VStack} from "@chakra-ui/react";
import _ from "lodash";
import {useGetQuery} from "../../../../services/api.service";
import {DISCLAIMER} from "../../../../consts/methods.consts";
import {DISCLAIMER_TAGS} from "../../../../consts/tags.consts";
import {useTableDataProvider} from "../../../hooks";
import {NavigateFunction, useNavigate} from "react-router";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {FormDropdown, FormDropdownOptions} from "../../../common/form-controllers/FormDropdown";

const DisclaimerListDataTable = () => {

    const navigate: NavigateFunction = useNavigate()
    const [filters, setFilters] = useState<object>({})
    const [page, setPage] = useState(1)

    const {data, isLoading, isFetching} = useGetQuery({
        method: DISCLAIMER.LIST,
        body: {
            page,
            ...filters
        },
        providesTags: [DISCLAIMER_TAGS.LIST]
    })

    const {dataList, pageCount} = useTableDataProvider(data);
    const tableLineClickHandler = (line: any) => {
        navigate(`${line._id}`)
    }

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({...filters, ...value})
    }, 200)

    const customFiltersMarkup = (
        <div className="flex gap-2 items-center">
            <FormDropdown minW={40} size={"sm"} options={isActiveOptions} isRequired formik={formik} name="is_active"
                          placeholder="Status"/>
        </div>
    )

    return (
        <ChakraTable onPage={setPage} isFetching={isFetching} onSearch={onSearchHandler} pageCount={pageCount}
                     isLoading={isLoading}
                     pagination size={"sm"} customFilterRenderer={customFiltersMarkup}
                     header title="Policies" onSelected={tableLineClickHandler}
                     columns={DISCLAIMER_LIST_TAB_COLUMNS} data={dataList}/>
    )
}

const isActiveOptions: FormDropdownOptions = [
    {label: "Active", value: 1},
    {label: "Inactive", value: 0}
]

export const DISCLAIMER_LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        header: "Title",
        accessor: "title",
        customRenderer(value: any, item: any) {
            return (
                <VStack spacing={0} w={{base: 28, lg: 72}} alignItems={"flex-start"}>
                    <p className="font-semibold">{value}</p>
                    {item?.sub_title && <p className="text-gray-500">{item.sub_title}</p>}
                </VStack>
            )
        },
    },
    // {
    //     header: "Validity",
    //     accessor: "validity"
    // },
    {
        header: "Is Active",
        accessor: "active",
        conditions: {
            'INACTIVE': 'red',
            'ACTIVE': 'green'
        },
    },
    {
        header: "Required",
        accessor: "required_in_sign_in",
        conditions: {
            'YES': 'red',
            'NO': 'green'
        },
    },
    {
        header: "Created At",
        accessor: "creation",
    },
    {
        header: "Link",
        accessor: "link",
        type: "link"
    },
]
export default DisclaimerListDataTable