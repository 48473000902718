import { FILTER_TAGS } from "../../../../consts/tags.consts"
import apiService from "../../../../services/api.service"

export const getStudents = (method: string, body: any = {}) => {
    return async (dispatch: any) => {
        const res = await dispatch(apiService.endpoints.get.initiate({
            method,
            body,
            providesTags: [FILTER_TAGS.STUDENT_SELECTOR]

        }))

        return res;
    }
}