import ChakraTable, {ChakraTableColumns} from "../../../common/chakra-table/ChakraTable";
import {useEffect, useState} from "react";
import {useGetQuery} from "../../../../services/api.service";
import {GROUP} from "../../../../consts/methods.consts";
import {GROUP_TAGS} from "../../../../consts/tags.consts";
import {useTableDataProvider} from "../../../hooks";
import _ from "lodash";
import {useFormik} from "formik";

interface IGroupActivityDataTable {
    id: string | undefined
    onSelected: Function
}


const LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        accessor: "description",
        header: "Description"
    },
    {
        accessor: "start_time",
        header: "Date"
    }
]

const GroupActivityListDataTable: React.FC<IGroupActivityDataTable> = ({id, onSelected}) => {

    const [filters, setFilters] = useState({is_active: 1})
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)

    const {data, isLoading} = useGetQuery({
        method: GROUP.ACTIVITY_LIST,
        body: {
            id,
            page,
            length: Number(length),
            ...filters
        },
        providesTags: [GROUP_TAGS.VIEW]
    })

    const {pageCount, dataList, totalResult} = useTableDataProvider(data)

    const _onSelected = (line: any) => {
        onSelected(line?.name)
    }

    const formik = useFormik({
        initialValues: {
            status: "Active"
        },
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({...filters, ...value})
    }, 200)

    return (
        <>
            <ChakraTable
                onSearch={onSearchHandler}
                size={"sm"}
                header title="Activities"
                onSelected={_onSelected}
                isLoading={isLoading}
                columns={LIST_TAB_COLUMNS}
                pageCount={pageCount}
                totalResult={totalResult}
                lengthPaginations
                onPage={setPage}
                onLength={setLength}
                pagination
                data={dataList}/>
        </>
    )
}

export default GroupActivityListDataTable