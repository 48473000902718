import { GUARDIAN, STUDENT } from "../../../consts/methods.consts"
import { ChakraTable } from "../.."
import { useGetQuery, usePostMutation } from "../../../services/api.service"
import { STUDENT_TAGS } from "../../../consts/tags.consts"
import { ChakraTableColumns } from "../chakra-table/ChakraTable"
import { useTableDataProvider } from "../../hooks"
import _ from "lodash";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { FormCheckBox } from "../form-controllers/FormCheckBox";
import { ToastService } from "../../../services/toast.service";
import { Link, Spinner, useToast } from "@chakra-ui/react";
import { useParams } from "react-router-dom"
import { bool } from "yup"

interface IContactsTab {
    id: string | undefined
}

interface ITblCellMarkup {
    value: any
    item: any
}

const LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        accessor: "first_name",
        header: "First Name",
        customRenderer: (value, item) => {
            return <TblNameCellMarkup value={value} item={item} />
        }
    },
    {
        accessor: "last_name",
        header: "Last Name"
    },
    {
        accessor: "mobile",
        header: "Mobile"
    },
    {
        accessor: "relationship",
        header: "Relationship"
    },
    {
        accessor: "notes",
        header: "Notes"
    },
    {
        accessor: "consenter",
        header: "Consenter",
        customRenderer: (value, item) => {
            return <TblConsenterMarkup value={value} item={item} />
        }
    }
]

const TblConsenterMarkup: React.FC<ITblCellMarkup> = ({ value, item }) => {
    const { id } = useParams()
    const toast = new ToastService(useToast());
    const [create, { isLoading }] = usePostMutation()

    const formik = useFormik({
        initialValues: { consenter: Boolean(_.get(item, "consenter")) },
        onSubmit: async (values: any) => {
        },
    })

    useEffect(() => {
        formik.setFieldValue("consenter", Boolean(_.get(item, "consenter")))
    }, [_.get(item, "consenter")])

    const onMutationHandler = async () => {
        try {
            await create({
                method: GUARDIAN.MAKE_CONCENTER,
                body: {
                    _id: _.get(item, "parent"),
                    student_id: id
                },
                invalidatesTags: [STUDENT_TAGS.VIEW]
            }).unwrap()
            toast.setTitle("Success").setDescription(`Consenter ${_.get(formik.values, "consenter") ? "selection" : "unselect"} is successful`).setDuration(2000).showSuccessToast();
        } catch (e) {
            console.error(e)
            toast.setTitle("Error").setDescription(`Unable to ${_.get(formik.values, "consenter") ? "selection" : "unselect"} consenter. Please try again`).setDuration(2000).showErrorToast();
        }
    }

    useEffect(() => {
        if (!_.isEqual(Boolean(_.get(item, "consenter")), _.get(formik.values, "consenter"))) {
            onMutationHandler().then()
        }
    }, [_.get(formik.values, 'consenter')]);

    return (
        isLoading ? <Spinner className="ml-8" size='xs' /> :
            <FormCheckBox className="ml-8" formik={formik} name="consenter" />
    )
}

const TblNameCellMarkup: React.FC<ITblCellMarkup> = ({ value, item }) => {

    return (
        <Link color="#0073E5" href={`/app/guardians/${item.id}`}
            isExternal={true}>
            {value}
        </Link>
    )
}

const ContactsTab = ({ id }: IContactsTab) => {
    const [filters, setFilters] = useState({})
    const [page, setPage] = useState(1)

    const { data, isLoading } = useGetQuery({
        method: STUDENT.GUARDIAN_LIST,
        body: {
            id,
            page,
            ...filters
        },
        providesTags: [STUDENT_TAGS.VIEW]
    })

    const { pageCount, dataList } = useTableDataProvider(data)

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({ ...filters, ...value })
    }, 200)

    return (
        <ChakraTable
            onSearch={onSearchHandler}
            size={"sm"}
            header title="Contacts"
            isLoading={isLoading}
            columns={LIST_TAB_COLUMNS}
            pageCount={pageCount}
            pagination
            onPage={setPage}
            data={dataList} />
    )
}

export default ContactsTab