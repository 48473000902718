import ChakraTable, { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable";
import React, { useEffect, useState } from "react";
import { useGetQuery, usePostMutation } from "../../../../services/api.service";
import { COMPLIANCE } from "../../../../consts/methods.consts";
import { COMPLIANCE_TAGS } from "../../../../consts/tags.consts";
import { useTableDataProvider } from "../../../hooks";
import _ from "lodash";
import { FormInput } from "../../../common/form-controllers/FormInput";
import { FormDropdown, FormDropdownOptions } from "../../../common/form-controllers/FormDropdown";
import { useFormik } from "formik";
import { ToastService } from "../../../../services/toast.service";
import { Button, useToast } from "@chakra-ui/react";
import { IOpenPdf, openPdf } from "../../../../utils/utils";
import { SiMicrosoftexcel } from "react-icons/si";


const statusOptions: FormDropdownOptions = [
    { label: "Valid", value: "Valid" },
    { label: "Expired", value: "Expired" }
]

interface IProps {
    setSelectedItem: Function,
    disclosure: any,
    disclosureExportData: any,
}

const WwccDataTable: React.FC<IProps> = ({ setSelectedItem, disclosure, disclosureExportData }) => {

    const toast = new ToastService(useToast())
    const [filters, setFilters] = useState({})
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)

    const [create] = usePostMutation()

    const { data, isLoading } = useGetQuery({
        method: COMPLIANCE.WWCC_LIST,
        body: {
            id: 10,
            page,
            length: Number(length),
            ...filters
        },
        providesTags: [COMPLIANCE_TAGS.LIST]
    })

    const LIST_TAB_COLUMNS: ChakraTableColumns = [
        {
            accessor: "card_number",
            header: "Card Number"
        },
        {
            accessor: "full_name",
            header: "Name"
        },
        {
            accessor: "expire_date",
            header: "Expiry Date"
        },
        {
            accessor: "status",
            header: "Status",
            conditions: {
                'Not Valid': 'red',
                'Valid': 'green'
            }
        },
        {
            accessor: "last_checked_date",
            header: "Last Checked"
        },
        {
            accessor: "",
            header: "Actions",
            options: [
                {
                    label: "Update",
                    onClick(item) {
                        setSelectedItem(item)
                        disclosure.onOpen()
                    }
                },
                {
                    label: "Verify",
                    onClick(item) {
                        verifyWWCC(_.get(item, 'name'))
                    }
                }
            ]
        }
    ]

    const { pageCount, dataList } = useTableDataProvider(data)

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const verifyWWCC = async (_id: any) => {
        try {
            await create({
                method: COMPLIANCE.VERIFY_WWCC,
                body: {
                    _id
                },
                invalidatesTags: [COMPLIANCE_TAGS.LIST]
            }).unwrap();
            toast.setTitle("Success").setDescription("Compliance verification successful").showSuccessToast();

        } catch (error) {
            toast.setTitle("Error").setDescription("Unable to verify compliance. Please try again").showErrorToast();
            console.error(error);
        }
    }

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({ ...filters, ...value })
    }, 200)

    const customFiltersMarkup = (
        <div className="flex flex-row justify-center items-center gap-2">
            <FormInput w={36} onFocus={(e) => (e.currentTarget.type = "date")}
                onBlur={(e) => (e.currentTarget.type = "text")}
                size={"sm"} type="text" isRequired formik={formik} name="from" placeholder="From" />
            <FormInput w={36} onFocus={(e) => (e.currentTarget.type = "date")}
                onBlur={(e) => (e.currentTarget.type = "text")}
                size={"sm"} type="text" isRequired formik={formik} name="to" placeholder="To" />
            <FormDropdown size={"sm"} options={statusOptions} isRequired formik={formik} name="status"
                placeholder="Status" />
            <Button iconSpacing={0} onClick={disclosureExportData.onOpen} size={"sm"} aria-label="export as excel"
                colorScheme={'gray'} variant={"outline"}
                className="transition-opacity !border-gray-300 shadow-sm !h-[35px]"
                leftIcon={<SiMicrosoftexcel className="!mx-5 text-gray-700 text-xl" />}>
            </Button>
        </div>
    )

    const onPrint = async (columns: any, data: any) => {
        const config: IOpenPdf = {
            tableData: {
                columns,
                data,
                filters,
                info: {
                    title: "WWCC Compliance Report"
                }
            },
            toast
        }

        openPdf(config);
    }

    return (
        <>
            <ChakraTable
                onSearch={onSearchHandler}
                size={"sm"}
                header title="WWCC Compliance Report"
                isLoading={isLoading}
                columns={LIST_TAB_COLUMNS}
                pageCount={pageCount}
                pagination
                onPage={setPage}
                onLength={setLength}
                lengthPaginations
                customFilterRenderer={customFiltersMarkup}
                onPrint={onPrint}
                data={dataList} />
        </>
    )
}

export default WwccDataTable