import {useDisclosure} from "@chakra-ui/react";
import {NavigateFunction, useNavigate} from "react-router";
import {ChakraModal, PageHeader, TestComponent} from "../..";
import {PageHeaderActions} from "../../common/page-header/PageHeader";
import StaffListDataTable from "./StaffListDataTable";


const StaffListPage = () => {
    const navigate: NavigateFunction = useNavigate()
    const disclosure = useDisclosure()

    const actions: PageHeaderActions = [
        {
            text: "Create New",
            onClick: () => navigate("new"),
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]


    return (
        <div>
            <PageHeader subtitle="View and Manage registered staff" title={`Staff`} actions={actions}/>

            <StaffListDataTable/>
            <ChakraModal modalTitle="Test" {...disclosure}>
                <TestComponent/>
            </ChakraModal>
        </div>
    )
}

export default StaffListPage;