import {PageHeader} from "../..";
import TourListDataTable from "./components/TourListDataTable";


const TourListPage = () => {
    // const navigate: NavigateFunction = useNavigate()
    //
    // const actions: PageHeaderActions = [
    //     // {
    //     //     text: "Create New",
    //     //     onClick: () => navigate("new"),
    //     //     buttonVariant: "solid",
    //     //     buttonColorScheme: "teal"
    //     // },
    // ]


    return (
        <div>
            <PageHeader subtitle="View and Manage school tour" title={`School Tour`}/>
            <TourListDataTable/>
        </div>
    )
}

export default TourListPage;