import _ from "lodash";
import moment from "moment";
import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import { useEffect } from "react";
import { ChakraModal } from "../../../index";
import { PARTICIPANT, SCHOOL } from "../../../../consts/methods.consts";
import { FormTextArea } from "../../../common/form-controllers/FormTextArea";
import { ToastService } from "../../../../services/toast.service";
import { GROUP_TAGS, STUDENT_TAGS } from "../../../../consts/tags.consts";
import { usePostMutation } from "../../../../services/api.service";
import FormSelect from "../../form-controllers/FormSelect";
// test build
interface ISignInOut {
    disclosure: any
    data: any
}

const modalTitle = (sign_in: string, sign_out: string) => {
    let model: any = {
        "0": "Sing In/Out",
        "1": "Sign in student",
        "2": "Sign out student",
        "3": "Sing In/Out"
    }

    return _.get(model, checkIsSignInOut(sign_in, sign_out))
}

const modalButtonText = (sign_in: string, sign_out: string) => {
    let model: any = {
        "1": "Sign in",
        "2": "Sign out",
    }

    return _.get(model, checkIsSignInOut(sign_in, sign_out))
}

const checkIsSignInOut = (sign_in: string, sign_out: string) => {
    let model: any = {
        "No | No": "0",
        "No | Yes": "1",
        "Yes | No": "2",
        "Yes | Yes": "3"
    }

    return _.get(model, `${sign_in} | ${sign_out}`)
}

const SignInOut = ({ disclosure, data }: ISignInOut) => {
    const toast = new ToastService(useToast())
    const [signInOut] = usePostMutation();

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values) => {
            try {
                let isSignInOut = checkIsSignInOut(data?.sign_in, data?.sign_out)

                // sign in student from station
                if (isSignInOut === "1") {
                    await signInOut({
                        method: PARTICIPANT.UPDATE,
                        body: {
                            _id: data?.participant_id,
                            sign_in: 1,
                            sign_in_station: _.get(values, "station"),
                            sign_in_time: moment().format("YYYY-MM-DD H:mm")
                        },
                        invalidatesTags: [STUDENT_TAGS.ACTIVITY_LIST, GROUP_TAGS.VIEW]
                    }).unwrap();
                    formik.resetForm()
                    toast.setTitle("Success").setDescription("Sign in to the Station").showSuccessToast();
                }

                // sign out student from station
                if (isSignInOut === "2") {
                    await signInOut({
                        method: PARTICIPANT.UPDATE,
                        body: {
                            _id: data?.participant_id,
                            sign_out: 1,
                            sign_out_station: _.get(values, "station"),
                            sign_out_time: moment().format("YYYY-MM-DD H:mm")
                        },
                        invalidatesTags: [STUDENT_TAGS.ACTIVITY_LIST, GROUP_TAGS.VIEW]
                    }).unwrap();
                    formik.resetForm()
                    toast.setTitle("Success").setDescription("Sign out from the Station").showSuccessToast();
                }
            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to process. Please try again").showErrorToast();
                console.error(error);
            } finally {
                disclosure.onClose();
            }
        }
    })

    useEffect(() => {
        formik.setValues({
            description: data?.description
        })
    }, [data])

    const signInOutStatus = checkIsSignInOut(_.get(data, "sign_in"), _.get(data, "sign_out"))

    return (
        <ChakraModal
            primaryAction={formik.submitForm}
            primaryButtonText={modalButtonText(_.get(data, "sign_in"), _.get(data, "sign_out"))}
            hidePrimaryButton={["0", "3"].includes(checkIsSignInOut(_.get(data, "sign_in"), _.get(data, "sign_out")))}
            modalTitle={modalTitle(_.get(data, "sign_in"), _.get(data, "sign_out"))}
            {...disclosure}
        >
            {
                signInOutStatus != '3' ?
                    <FormSelect
                        method={SCHOOL.GET_STATION_LIST_FOR_DROPDOWN}
                        isRequired label="Station"
                        formik={formik}
                        name="station" />
                    : null
            }
            <FormTextArea isReadOnly formik={formik} name={"description"} label={"Reason"} />
        </ChakraModal>
    )
}

export default SignInOut