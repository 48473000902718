import {Link, useDisclosure} from "@chakra-ui/react"
import React, {useEffect, useState} from "react"
import {ChakraTable, SectionCard} from "../../../.."
import {ChakraTableColumns} from "../../../../common/chakra-table/ChakraTable"
import {SectionCardCreatable} from "../../../../common/section-card/SectionCard"
import StaffSelectorModal from "../../../../common/staff-selector-modal/StaffSelectorModal"

interface ISelectStaff {
    onSelect: (items: any[]) => void
}


const SelectStaff = ({onSelect}: ISelectStaff) => {
    const disclosure = useDisclosure()
    const [isError, setError] = useState(false)
    const [children, setChildren] = useState<any>([])

    useEffect(() => {
        onSelect(children)
    }, [children])

    const STUDENT_LIST_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "Staff Id",
            accessor: "staff_id",
            customRenderer: (value, item) => {
                return tblStaffIdCellMarkup(value, item)
            }
        },
        {
            header: "First Name",
            accessor: "first_name",
        },
        {
            header: "Last Name",
            accessor: "last_name",
        },
        {
            header: "Gender",
            accessor: "gender",
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            options: [
                {
                    label: "Remove",
                    onClick(item: any) {
                        const filteredData = children.filter((i: any) => i.id != item.id);
                        setChildren(filteredData);
                    },
                }
            ]
        }
    ]

    const creatable: SectionCardCreatable = {
        text: "Browse",
        onClick: () => {
            disclosure.onOpen();
        }
    }

    return (
        <div>
            <StaffSelectorModal selectedItems={children} setSelectedItems={setChildren} {...disclosure} />
            <SectionCard isError={isError} errorMessage="Please add a staff member" creatable={creatable}
                         subTitle="Select staff related to the activity" title="Staff" p={3}>
                <ChakraTable pageCount={10} isLoading={false} size={"sm"}
                             title="Staff" columns={STUDENT_LIST_TAB_COLUMNS} data={children || []}/>
            </SectionCard>
        </div>
    )
}

const tblStaffIdCellMarkup = (value: any, item: any) => {
    return (
        <Link color="#0073E5" href={`/app/staff/${item?.id}`}
              isExternal={true}>
            {value}
        </Link>
    )
}

export default SelectStaff