import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, InputProps } from "@chakra-ui/react";
import _ from "lodash";

type MutatedTypes = Omit<InputProps, 'onChange' | 'name' | 'isRequired' | 'isInvalid' | 'defaultValue'>

export interface FormInputProps extends MutatedTypes {
    containerClassNames?: string,
    formik: any,
    name: string,
    label?: string,
    isRequired?: boolean,
    customMessage?: any,
    helperText?: string,
    ref?: any
}

export const FormInput = ({ rounded = "md", size = 'sm', customMessage, formik, isRequired = false, name, label, ref, containerClassNames = '', helperText, ...restProps }: FormInputProps) => {

    return (
        <FormControl isRequired={isRequired} className={containerClassNames} isInvalid={Boolean(formik.submitCount && formik.errors[name])}>
            {label && <FormLabel>{label}</FormLabel>}
            <Input style={{ height: '35px' }} ref={ref} shadow={"sm"} borderColor={'gray.300'} size={size} {...restProps} name={name} value={_.isEmpty(formik.values) ? '' : formik.values[name]} onChange={formik.handleChange} rounded={rounded} />
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {(formik.submitCount && formik.errors[name]) ? <FormErrorMessage mt={2}>{formik.errors[name]}</FormErrorMessage> : customMessage && customMessage}
        </FormControl>
    )
}