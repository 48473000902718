import _ from "lodash";
import axios from "axios";
import { getBase64 } from "../../../utils/utils";
import { ATTACHMENT } from "../../../consts/methods.consts";
import { ToastService } from "../../../services/toast.service";
import { FILE_UPLOAD_URL } from "../../../consts/app.consts";
import { ATTACHMENT_TAGS } from "../../../consts/tags.consts";
import { Avatar, useToast } from "@chakra-ui/react";
import { FaRegEye, FaRegTrashAlt } from "react-icons/fa";
import { useGetQuery, usePostMutation } from "../../../services/api.service";

interface ProfileAvatarProps {
    doctype: string,
    docname: string,
    placeholder?: string
}

const ProfileAvatar = ({ doctype, docname, placeholder }: ProfileAvatarProps) => {
    const toast = new ToastService(useToast())
    const [remove] = usePostMutation();

    const { data, refetch } = useGetQuery({
        method: ATTACHMENT.GET_PROFILE_IMAGE,
        body: {
            id: docname
        },
        providesTags: [ATTACHMENT_TAGS.GET_PROFILE_IMAGE]
    })

    const isProfileImageExists = _.get(data, ['message', 'profile_image'], 0)
    const profileImageUrl = _.get(data, ['message', 'file_url']);

    const onInputChange = (event: any) => {
        const file: File = event.target.files[0];

        if (!file) {
            toast.setTitle("Error").setDescription("No image selected. Please select a valid image file").setDuration(4000).showErrorToast()
            return;
        }

        if (file.size > (1 * 1024 * 1024)) {
            toast.setTitle("Error").setDescription("File size exceeds the maximum allowed size (5MB)").setDuration(4000).showErrorToast()
            return;
        }

        getBase64(file, async (result: any) => {
            axios.post(FILE_UPLOAD_URL, {
                cmd: "uploadfile",
                doctype,
                docname,
                filename: _.get(file, "name"),
                docfield: "profile_image",
                filedata: result,
                from_form: '1'
            },
                { withCredentials: true }
            ).then((res: any) => {
                refetch()
                toast.setDescription("Profile image removed successfully").showSuccessToast()
            }).catch(() => {
                toast.setDescription("Unable to update profile image").showErrorToast()
            })
        })
    }

    const onRemoveProfileImage = () => {
        remove({
            method: ATTACHMENT.REMOVE_PROFILE_IMAGE,
            body: {
                id: docname
            },
            invalidatesTags: [ATTACHMENT_TAGS.GET_PROFILE_IMAGE]
        }).unwrap().then(() => {
            refetch()
            toast.setDescription("Profile image updated successfully").showSuccessToast()
        }).catch(() => {
            toast.setDescription("Unable to remove profile image").showErrorToast()
        })
    }

    const onViewProfileImage = () => {
        window.open(profileImageUrl, `_blank`)
    }

    return (
        <div className="flex flex-col animation-form">
            <button type="button">
                <label htmlFor="file">
                    <Avatar loading={"lazy"} src={profileImageUrl} bgColor={"gray.200"} color={"blackAlpha.700"} className="cursor-pointer hover:bg-gray-300 transition-all !border-gray-400 !border-2 !text-3xl" w={"12rem"} h={"12rem"} name={placeholder || 'Full Name'} />
                </label>
            </button>
            <input disabled={isProfileImageExists} onChange={onInputChange} accept="image/*" type="file" id="file" style={{ display: "none" }} />
            {isProfileImageExists ? (
                <div className="flex justify-center gap-3 mt-2 items-center">
                    <div onClick={onRemoveProfileImage} className="flex items-center gap-1 cursor-pointer text-gray-500 hover:text-red-500 transition-all">
                        <FaRegTrashAlt />
                        <p className="text-xs">Remove</p>
                    </div>
                    <div onClick={onViewProfileImage} className="flex items-center gap-1 cursor-pointer text-gray-500 hover:text-blue-500 transition-all">
                        <FaRegEye />
                        <p className="text-xs">View</p>
                    </div>
                </div>
            ) : null}
        </div>
    )
}

export default ProfileAvatar;