import _ from "lodash"
import { useState } from "react"
import { CONTRACTOR, THERAPIST } from "../../../../consts/methods.consts"
import { STAFF_TAGS } from "../../../../consts/tags.consts"
import { useGetQuery } from "../../../../services/api.service"
import FormLiveSelect from "../../../common/form-controllers/FormLiveSelect"

interface ISelectTherapist {
    formik: any,
    isRequired?: boolean
    initQuery?: string
    isMulti?: boolean
}

const SelectConstructor = ({ formik, isRequired = false, initQuery = "", isMulti = false }: ISelectTherapist) => {
    const [query, setQuery] = useState(initQuery)

    const { data, isFetching } = useGetQuery({
        method: CONTRACTOR.SEARCH,
        body: { q: query },
        providesTags: [STAFF_TAGS.DROPDOWN]
    })

    const searchHandler = _.debounce((text: string) => {
        setQuery(text)
    }, 250)

    return (
        <FormLiveSelect label="Contractor" formik={formik} name={"contractor"} options={data?.message?.map((line: any) => ({ label: line?.full_name, value: line?.name }))} isLoading={isFetching} onInputChange={searchHandler} isRequired={isRequired} isMulti={isMulti} />
    )
}

export default SelectConstructor