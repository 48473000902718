export enum STUDENT {
    LIST = "edpass.api.student.get_list",
    ADD = "edpass.api.student.add",
    GET = "edpass.api.student.get",
    UPDATE = "edpass.api.student.update",
    RESOURCE_LIST = "edpass.api.student.get_resource_list",
    GUARDIAN_LIST = "edpass.api.student.get_guardian_list",
    GUARDIAN_LIST_DROPDOWN = "edpass.api.student.get_guardian_list_dropdown",
    DOWNLOAD_QR_CODE = "edpass.api.student.download_qr"
}

export enum STAFF {
    LIST = "edpass.api.staff.get_list",
    ADD = "edpass.api.staff.add",
    GET = "edpass.api.staff.get",
    UPDATE = "edpass.api.staff.update",
    SEARCH = "edpass.api.staff.search",
    RESOURCE_LIST = "edpass.api.staff.get_resource_list",
    RESEND_QR = "edpass.api.staff.resend_qr_for_user"
}

export enum DISCLAIMER {
    LIST = "edpass.api.disclaimer.get_list",
    ADD = "edpass.api.disclaimer.add",
    GET = "edpass.api.disclaimer.get",
    UPDATE = "edpass.api.disclaimer.update",
}

export enum PARCEL {
    LIST = "edpass.api.parcel.get_list",
    ADD = "edpass.api.parcel.add",
    GET = "edpass.api.parcel.get",
    UPDATE = "edpass.api.parcel.update",
    LIST_FOR_DROPDOWN = "edpass.api.parcel.list_for_dropdown",
    NOTIFY = "edpass.api.parcel.notify"
}

export enum RESOURCE {
    LIST = "edpass.api.resource.get_list",
    SEARCH = "edpass.api.resource.search",
    ADD = "edpass.api.resource.add",
    UPDATE = "edpass.api.resource.update",
    GET = "edpass.api.resource.get",
    ASSIGNMENT_HISTORY = "edpass.api.resource.assignment_history"
}

export enum DEPARTMENT {
    LIST = "edpass.api.department.get_list",
    ADD = "edpass.api.department.add",
    UPDATE = "edpass.api.department.update",
    DELETE = "edpass.api.department.delete"
}

export enum CLASS {
    LIST = "edpass.api.grade.get_list",
    ADD = "edpass.api.grade.add",
    UPDATE = "edpass.api.grade.update"
}

export enum STATION {
    LIST = "edpass.api.station.get_list",
    ADD = "edpass.api.station.add",
    UPDATE = "edpass.api.station.update"
}

export enum RESOURCE_TYPE {
    LIST = "edpass.api.resource_type.get_list",
    ADD = "edpass.api.resource_type.add",
    UPDATE = "edpass.api.resource_type.update"
}

export enum SCHOOL {
    GET_SCHOOL_LIST = "edpass.api.school.get_list",
    GET_CLASS_LIST_FOR_DROPDOWN = "edpass.api.school.get_class_list",
    GET_STATION_LIST_FOR_DROPDOWN = "edpass.api.school.get_station_list",
    GET_CLASS_LIST_FOR_STUDENT_SELECTOR = "edpass.api.school.get_class_list_for_student_selector",
    GET_SELECTED_SCHOOL = "edpass.api.school.get_selected_school",
    GET_DEPARTMENT_LIST_FOR_DROPDOWN = "edpass.api.school.get_department_list",
    GET_RESOURCE_TYPE_LIST_FOR_DROPDOWN = "edpass.api.school.get_resource_type_list",
    SELECT_SCHOOL = "edpass.api.school.select_school",
    GET_EXTERNAL_USER_TYPES = "edpass.api.school.get_external_user_type_list",
    GET_EXTERNAL_USER_TYPES_FOR_GROUPS = "edpass.api.school.get_external_user_type_list_for_groups",
    GET_CONTRACTOR_TYPES = "edpass.api.school.get_contractor_type_list",
    GET_GUEST_TYPES = "edpass.api.school.get_guest_type_list",
    GET_DISCLAIMER_LIST_BY_USER_TYPE = 'edpass.api.school.get_disclaimer_list_by_user_type',
    GET_DISCLAIMER_LIST_BY_USER_TYPE_WITH_CONCENT = 'edpass.api.school.get_disclaimer_list_by_user_type_with_concent',
    DELETE_CLASS = 'edpass.api.school.delete_class'
}

export enum FILTERS {
    STUDENT_SELECTOR = "edpass.api.filter.student",
    STAFF_SELECTOR = "edpass.api.filter.staff",
    ACADEMIC_STAFF_SELECTOR = "edpass.api.filter.academic_staff",
    NON_ACADEMIC_STAFF_SELECTOR = "edpass.api.filter.non_academic_staff",
    GUARDIAN_SELECTOR = "edpass.api.filter.guardian",
    EXTERNAL_USER_SELECTOR = "edpass.api.filter.external_user",
    SEARCH_STUDENT_STAFF = "edpass.api.filter.student_staff"
}

export enum GUARDIAN {
    LIST = "edpass.api.guardian.get_list",
    ADD = "edpass.api.guardian.add",
    GET = "edpass.api.guardian.get",
    UPDATE = "edpass.api.guardian.update",
    MAKE_CONCENTER = "edpass.api.guardian.make_concenter"
}

export enum THERAPIST {
    LIST = "edpass.api.therapist.get_list",
    ADD = "edpass.api.therapist.add",
    GET = "edpass.api.therapist.get",
    UPDATE = "edpass.api.therapist.update",
    SEARCH = "edpass.api.therapist.search"
}

export enum EXTERNAL {
    SEARCH_THERAPY_CONTRACTOR = "edpass.api.external.search_therapy_contractor",
    EXTEND_USER = "edpass.api.external.extend"
}

export enum CONTRACTOR {
    LIST = "edpass.api.contractor.get_list",
    ADD = "edpass.api.contractor.add",
    GET = "edpass.api.contractor.get",
    UPDATE = "edpass.api.contractor.update",
    SEARCH = "edpass.api.contractor.search",
}

export enum GUEST {
    LIST = "edpass.api.guest.get_list",
    ADD = "edpass.api.guest.add",
    GET = "edpass.api.guest.get",
    UPDATE = "edpass.api.guest.update",
    TOUR_LIST = "edpass.api.guest.tour_list"
}

export enum GROUP {
    LIST = "edpass.api.group.get_list",
    ADD = "edpass.api.group.add",
    GET = "edpass.api.group.get",
    UPDATE = "edpass.api.group.update",
    SEARCH = "edpass.api.group.search",
    REMOVE_MEMBER = "edpass.api.group.remove_member",
    ADD_MEMBER = "edpass.api.group.add_member",
    SIGN_IN = "edpass.api.group.sign_in",
    SIGN_OUT = "edpass.api.group.sign_out",
    ACTIVITY_LIST = "edpass.api.group.activity_list",
    GET_ACTIVITY = "edpass.api.group.get_activity",
    RESEND_QR_FOR_STAFF = "edpass.api.group.resend_qr_for_member"
}

export enum ACTIVITY {
    ADD = "edpass.api.activity.add",
    UPDATE = "edpass.api.activity.update",
    GET = "edpass.api.activity.get",
    EVENT_LIST = "edpass.api.activity.get_event_list",
    RESOURCE_LIST_BY_STUDENT = "edpass.api.activity.get_resource_list_by_student",
    RESOURCE_ADD = "edpass.api.activity.resource.add",
    RESOURCE_UPDATE = "edpass.api.activity.resource.update",
    PARENT_CONSENT_LIST = "edpass.api.activity.parent_consent_list",
    LIST_BY_USER_TYPE = "edpass.api.activity.get_list_by_user_type",
    LIST_FOR_CALENDAR = "edpass.api.activity.get_list_for_calendar",
    RESEND_QR = "edpass.api.activity.resend_qr_for_activity"
}


export enum REPORTS {
    VISIT_LOG = "edpass.api.reports.analytics.visit_log",
    STUDENT_VISIT_LOG = "edpass.api.reports.analytics.student_visit_log",
    TODAY_VISITOR_COUNT = "edpass.api.reports.home_dashboard.visitor_count",
    TODAY_STUDENT_COUNT = "edpass.api.reports.home_dashboard.student_count",
    TODAY_STAFF_COUNT = "edpass.api.reports.home_dashboard.staff_count",
    TODAY_ATTENDANCE = "edpass.api.reports.home_dashboard.today_sign_in",
    SIGN_OUT_RESOURCES = "edpass.api.reports.home_dashboard.sign_out_resources",
    MONTH_OVERVIEW = "edpass.api.reports.home_dashboard.month_overview",
    STAFF_TIME_SHEET = "edpass.api.reports.analytics.staff_time_sheet",
    ALL_CURRENT_VISITORS = "edpass.api.reports.analytics.current_visitors",
    ALL_CURRENT_STAFF = "edpass.api.reports.analytics.current_staff",
    ALL_CURRENT_STUDENTS = "edpass.api.reports.analytics.current_students",
    ALL_EARLY_DEPARTURE_STUDENTS = "edpass.api.reports.analytics.early_departure_students",
    ALL_CURRENT_GUESTS = "edpass.api.reports.analytics.current_guests",
    RESOURCES_ALLOCATIONS = "edpass.api.reports.analytics.resources_allocations"

}

export enum PARTICIPANT {
    ADD = "edpass.api.participant.add",
    UPDATE = "edpass.api.participant.update",
    DELETE = "edpass.api.participant.delete"
}

export enum COMPLIANCE {
    ADD = "edpass.api.compliance.add",
    UPDATE = "edpass.api.compliance.update",
    DELETE = "edpass.api.compliance.delete",
    WWCC_LIST = "edpass.api.compliance.wwcc_list",
    VIT_LIST = "edpass.api.compliance.vit_list",
    VERIFY_WWCC = "edpass.api.compliance.verify_wwcc"
}

export enum ATTACHMENT {
    REMOVE_PROFILE_IMAGE = "edpass.api.attachment.remove_profile_image",
    GET_PROFILE_IMAGE = "edpass.api.attachment.get_profile_image",
}

export enum EXPORT_DATA {
    VISIT_LOG = "edpass.api.export.visit_log",
    TIME_SHEET = "edpass.api.export.time_sheet",
    WWCC_LIST = "edpass.api.export.wwcc_list",
    VISIT_LIST = "edpass.api.export.visit_list",
    CURRENT_VISITORS = "edpass.api.export.current_visitors",
    CURRENT_STUDENTS = "edpass.api.export.current_students",
    ALL_EARLY_DEPARTURE_STUDENTS = "edpass.api.export.all_early_departure_students",
    CURRENT_STAFF = "edpass.api.export.current_staff",
    CURRENT_GUEST = "edpass.api.export.current_guests"
}

export enum VALIDATION {
    DUPLICATE_STUDENT_ID_CREATE = "edpass.api.validations.duplicate_student_id_for_create",
    DUPLICATE_STUDENT_ID_UPDATE = "edpass.api.validations.duplicate_student_id_for_update",
    DUPLICATE_STAFF_ID_CREATE = "edpass.api.validations.duplicate_staff_id_for_create",
    DUPLICATE_STAFF_ID_UPDATE = "edpass.api.validations.duplicate_staff_id_for_update",
    DUPLICATE_RESOURCE_ID_CREATE = "edpass.api.validations.duplicate_resource_id_for_create",
    DUPLICATE_RESOURCE_ID_UPDATE = "edpass.api.validations.duplicate_resource_id_for_update",
    DUPLICATE_GUARDIAN_EMAIL_CREATE = "edpass.api.validations.duplicate_guardian_email_for_create",
    DUPLICATE_GUARDIAN_EMAIL_UPDATE = "edpass.api.validations.duplicate_guardian_email_for_update",
    DUPLICATE_STAFF_EMAIL_CREATE = "edpass.api.validations.duplicate_staff_email_for_create",
    DUPLICATE_STAFF_EMAIL_UPDATE = "edpass.api.validations.duplicate_staff_email_for_update",
    DUPLICATE_STAFF_MOBILE_CREATE = "edpass.api.validations.duplicate_staff_mobile_for_create",
    DUPLICATE_STAFF_MOBILE_UPDATE = "edpass.api.validations.duplicate_staff_mobile_for_update",
    DUPLICATE_GUEST_EMAIL_CREATE = "edpass.api.validations.duplicate_guest_email_for_create",
    DUPLICATE_GUEST_EMAIL_UPDATE = "edpass.api.validations.duplicate_guest_email_for_update",
    DUPLICATE_GUEST_MOBILE_CREATE = "edpass.api.validations.duplicate_guest_mobile_for_create",
    DUPLICATE_GUEST_MOBILE_UPDATE = "edpass.api.validations.duplicate_guest_mobile_for_update",
    DUPLICATE_THERAPIST_EMAIL_CREATE = "edpass.api.validations.duplicate_therapist_email_for_create",
    DUPLICATE_THERAPIST_EMAIL_UPDATE = "edpass.api.validations.duplicate_therapist_email_for_update",
    DUPLICATE_THERAPIST_MOBILE_CREATE = "edpass.api.validations.duplicate_therapist_mobile_for_create",
    DUPLICATE_THERAPIST_MOBILE_UPDATE = "edpass.api.validations.duplicate_therapist_mobile_for_update",
    DUPLICATE_CONTRACTOR_EMAIL_CREATE = "edpass.api.validations.duplicate_contractor_email_for_create",
    DUPLICATE_CONTRACTOR_EMAIL_UPDATE = "edpass.api.validations.duplicate_contractor_email_for_update",
    DUPLICATE_CONTRACTOR_MOBILE_CREATE = "edpass.api.validations.duplicate_contractor_mobile_for_create",
    DUPLICATE_CONTRACTOR_MOBILE_UPDATE = "edpass.api.validations.duplicate_contractor_mobile_for_update"
}

export enum PRINTING {
    TABLES = "edpass.api.printing.tables"
}

export enum AUTH {
    FORGOT_PASSWORD = "edpass.api.auth.forgot_password",
    CHANGE_PASSWORD = "edpass.api.auth.change_password"
}

export enum IMPORT_DATA {
    STUDENT = "edpass.api.import_data.student",
    STAFF = "edpass.api.import_data.staff"
}