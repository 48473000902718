import {Badge, Box, Button, Heading, HStack, Stack, Text, ThemeTypings, ThemingProps, VStack} from "@chakra-ui/react";
import {FiArrowLeft} from "react-icons/fi";
import {NavigateFunction, useLocation, useNavigate} from "react-router";

type PageHeaderAction = {
    text: string,
    onClick: () => any,
    isDisabled?: boolean,
    buttonColorScheme?: ThemeTypings["colorSchemes"]
    buttonVariant?: ThemingProps<"Button">["variant"],
    isLoading?: boolean
}

export type PageHeaderActions = Array<PageHeaderAction>;

export interface IPageHeaderProps {
    title?: string,
    subtitle?: string,
    status?: string,
    statusColorSchemes?: ThemeTypings["colorSchemes"]
    actions?: PageHeaderActions
    actionRenderer?: JSX.Element,
    titleRenderer?: JSX.Element,
    subTitleRenderer?: JSX.Element,
    enableBackButton?: boolean,
    isLoading?: boolean
}

const PageHeader = ({
    title,
    isLoading,
    enableBackButton,
    subtitle,
    statusColorSchemes = "green",
    status,
    actions = [],
    actionRenderer,
    titleRenderer,
    subTitleRenderer
}: IPageHeaderProps) => {
    const navigate: NavigateFunction = useNavigate();
    const location: any = useLocation();

    const actionMarkup = actions.map((action: PageHeaderAction, index: number) => <Button key={index}
        colorScheme={action.buttonColorScheme || "blackAlpha"}
        size={"sm"}
        isDisabled={Boolean(action.isDisabled)} isLoading={action.isLoading}
        variant={action.buttonVariant || 'outline'}
        onClick={action.onClick || (() => {
        })}>{action.text}</Button>)

    return (
        <Box p={3} mb={4} w={"full"} className="bg-gray-50 border rounded relative" as="section" bg="bg-surface">
            <Stack spacing="4" direction={{ base: 'column', md: 'row' }} justify="space-between">
                <HStack alignItems={"center"} spacing={3}>
                    {/* Back button */}
                    {(enableBackButton && location.key != 'default') && (
                        <div onClick={() => navigate(-1)}
                            className="border rounded bg-white p-3 hover:bg-gray-100 transition-all cursor-pointer">
                            <FiArrowLeft />
                        </div>
                    )}

                    {!isLoading && (
                        <VStack alignItems={"flex-start"} spacing="1">
                            <HStack alignItems={"start"} spacing={2}>
                                {/* Title Renderer */}
                                {titleRenderer && (
                                    <HStack alignItems={"center"} spacing={2}>
                                        {titleRenderer}
                                    </HStack>
                                )}
                                {/* Title */}
                                {(!titleRenderer && title) && (
                                    <Heading size={"md"} fontWeight="semibold">
                                        {title}
                                    </Heading>
                                )}
                                {/* Status */}
                                {status && (
                                    <Badge className="mt-[6px]" variant='solid' colorScheme={statusColorSchemes}>
                                        {status}
                                    </Badge>
                                )}
                            </HStack>

                            <Stack
                                direction={{ base: 'column', sm: 'row' }}
                                spacing={{ base: '2', sm: '6' }}
                                color="muted">
                                {/* Subtitle Renderer */}
                                {subTitleRenderer && (
                                    <HStack alignItems={"center"} spacing={2}>
                                        {subTitleRenderer}
                                    </HStack>
                                )}
                                {/* Subtitle */}
                                <HStack>
                                    {(!subTitleRenderer && title) && <Text>{subtitle}</Text>}
                                </HStack>
                            </Stack>
                        </VStack>
                    )}

                    {isLoading && (
                        <VStack spacing={'3'} alignItems={"flex-start"}>
                            <div className="!h-[22px] border rounded-md w-28 bg-gray-200 animate-pulse"></div>
                            <div className="!h-[15px] border rounded-md w-20 bg-gray-200 animate-pulse"></div>
                        </VStack>
                    )}
                </HStack>
                {(!actionRenderer && actions.length > 0) && (
                    <Stack direction="row" spacing="3">
                        {actionMarkup}
                    </Stack>
                )}

                {actionRenderer}
            </Stack>

            {/* <div className=" absolute rounded-sm text-center bottom-[-25px] text-amber-600 px-2 text-xs py-0.5 bg-amber-200 left-0 right-0">Not Saved</div> */}
        </Box>
    )
}

export default PageHeader;