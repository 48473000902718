import { useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useEffect } from "react";
import * as Yup from "yup";
import { COMPLIANCE } from "../../../../consts/methods.consts";
import { COMPLIANCE_TAGS } from "../../../../consts/tags.consts";
import { usePostMutation } from "../../../../services/api.service";
import { ToastService } from "../../../../services/toast.service";
import { FormInput } from "../../../common/form-controllers/FormInput";
import { ChakraModal } from "../../../index";


interface IUpdateComplianceModal {
    disclosure: any,
    data: any
}

const UpdateComplianceModal = ({ disclosure, data }: IUpdateComplianceModal) => {
    const toast = new ToastService(useToast())
    const [create, {isLoading}] = usePostMutation();

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            expire_date: Yup.string().required("Expire date is required")
        }),
        onSubmit: async (values) => {
            try {

                await create({
                    method: COMPLIANCE.UPDATE,
                    body: {
                        _id: _.get(data, 'name'),
                        expire_date: _.get(values, 'expire_date')
                    },
                    invalidatesTags: [COMPLIANCE_TAGS.LIST]
                }).unwrap();
                formik.resetForm()
                toast.setTitle("Success").setDescription("Compliance update successful").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to update compliance. Please try again").showErrorToast();
                console.error(error);
            } finally {
                disclosure.onClose();
            }
        }
    })

    useEffect(() => {
        if (_.get(data, "expire_date")) {
            formik.setFieldValue("expire_date", _.get(data, "expire_date"));
        }
    }, [data])

    return (
        <ChakraModal
            primaryAction={formik.submitForm}
            primaryButtonText={"Update"}
            modalTitle={"Update Compliance"}
            {...disclosure}
            isLoading={isLoading}
        >
            <FormInput type={'date'} label="Expire Date" formik={formik} name="expire_date" isRequired />
        </ChakraModal>
    )
}

export default UpdateComplianceModal