import React, {JSX, useRef, useState} from "react";
import {ToastService} from "../../../../services/toast.service";
import {Button, useToast} from "@chakra-ui/react";
import {FileRejection, useDropzone} from "react-dropzone";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import _ from "lodash";
import {FaCloudUploadAlt} from "react-icons/fa";
import GuardianDataImport from "./GuardianDataImport";
import {usePostMutation} from "../../../../services/api.service";

interface IProps {
    method: string
}

const StudentDataImport: React.FC<IProps> = ({method}) => {
    const toast: ToastService = new ToastService(useToast())
    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
    const [fileRejections, setFileRejections] = useState<FileRejection[]>([]);
    const [multipleFleError, setMultipleFleError] = useState<string | null>(null)
    const [invalidDataFile, setInvalidDataFile] = useState<boolean>(false)
    const [guardianData, setGuardianData] = useState<Array<any>>([])
    const guardianRef = useRef<any>(null);

    const [create] = usePostMutation()

    const onDropHandler = (acceptedFiles: File[]) => {
        setMultipleFleError(null)
        setFileRejections([])
        setAcceptedFiles(acceptedFiles);
    }

    const onRejectedHandler = (fileRejections: FileRejection[]) => {
        setAcceptedFiles([])
        if (fileRejections.length > 1) {
            setMultipleFleError("Can't add multiple file. Please try again")
            return
        }
        setFileRejections(fileRejections);
    }

    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'text/csv': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
        },
        multiple: false,
        maxFiles: 1,
        onDrop: onDropHandler,
        onDropRejected: onRejectedHandler
    });

    const parseExcel = (file: File) => {
        setIsLoading(true)
        const reader = new FileReader();

        reader.onload = async (e: any) => {
            setIsLoading(true)
            const xlsxData = new Uint8Array(e.target.result);
            const workbook = XLSX.read(xlsxData, {type: 'array'});

            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];

            const columnData: Array<string> = XLSX.utils.sheet_to_json(sheet, {header: 1})[0] as Array<string>

            if (!columnData.some((columnName) => columnName.trim() === "STKEY")) {
                setInvalidDataFile(true)
                setIsLoading(false)
                return
            } else {
                setInvalidDataFile(false)
            }

            const excelData: Array<any> = XLSX.utils.sheet_to_json(sheet, {header: 1});

            // Assuming the first row contains headers
            const headers: Array<any> = excelData[0];

            const formattedData = excelData.slice(1).map((row: any) => {

                const rowData: any = {};
                headers.forEach((value: any, index: number) => {
                    rowData[value] = row[index] ? row[index] : null
                })
                return rowData;
            })

            await onMutationHandler(formattedData, guardianData)
        };

        reader.readAsArrayBuffer(file);
    };

    const parseCSV = (file: File) => {
        setIsLoading(true)
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: async (result: any) => {

                const hasSTKEYColumn = result.meta.fields.some((columnName: any) => columnName.trim() === "STKEY")

                if (!hasSTKEYColumn) {
                    setIsLoading(false)
                    setInvalidDataFile(true)
                    return;
                } else {
                    setInvalidDataFile(false)
                }

                await onMutationHandler(result.data, guardianData)
            },
            error: (error: any) => {
                console.error('Error parsing CSV:', error);
                toast.setTitle("Error").setDescription("Internal Error").showErrorToast();
            }
        });
    };

    const onUploadHandler = () => {
        setIsLoading(true)
        if (["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
            .includes(acceptedFiles[0].type)) {
            parseExcel(acceptedFiles[0])
        }
        if (acceptedFiles[0].type === "text/csv") {
            parseCSV(acceptedFiles[0])
        }
    }

    const onMutationHandler = async (formattedData: Array<any>, guardianData: Array<any>) => {
        try {
            await create({
                method: method,
                body: {
                    student: formattedData,
                    guardian: guardianData
                },
                invalidatesTags: []
            }).unwrap()

            setAcceptedFiles([])
            guardianRef?.current?.onResetHandler()
            toast.setTitle("Success").setDescription("Data imported successfully").showSuccessToast();
        } catch (e) {
            toast.setTitle("Error").setDescription("Unable import data. Please try again").showErrorToast();
        } finally {
            setIsLoading(false)
        }
    }

    const renderFileItems: Array<JSX.Element | []> = acceptedFiles.map((file: File) => {
        if (!acceptedFiles.length) return [];

        return (
            <div key={file.name}>
                {file.name}
            </div>
        )
    });

    const renderRejectionItems: Array<JSX.Element | []> = fileRejections.map(({file, errors}) => {
            if (!fileRejections.length) return [];

            return (
                <div className="text-red-600 text-sm" key={file.name}>
                    {file.name}
                </div>
            )
        }
    );

    const isEmptyRenderFileItems: boolean = _.isEmpty(renderFileItems)
    const isEmptyRenderRejectionItems: boolean = _.isEmpty(renderRejectionItems)
    const borderColor: string = isEmptyRenderFileItems ? 'border-gray-200' : 'border-green-500'

    const onGetDataCB = (data: Array<any>) => {
        setGuardianData(data)
    }

    return (
        <div className="flex flex-col p-3 mb-4 border rounded">
            <div className="flex justify-between mb-4">
                <div className="text-lg font-bold mb-2">Student and Guardian data import section</div>
                <div className="mt-1">
                    <Button size="sm" leftIcon={<FaCloudUploadAlt size="18"/>} variant="outline" colorScheme="blue"
                            isDisabled={!isEmptyRenderRejectionItems || isEmptyRenderFileItems || !guardianData.length}
                            isLoading={isLoading} onClick={onUploadHandler}>Import</Button>
                </div>
            </div>
            <section
                className={`bg-gray-50 p-4 border-dashed border-2 ${!isEmptyRenderRejectionItems || multipleFleError || invalidDataFile ?
                    'border-red-500' : borderColor} rounded-xl cursor-pointer`}>
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} />
                    <div> {isEmptyRenderFileItems && isEmptyRenderRejectionItems && !multipleFleError ? "Drag and drop the student data file you want to upload, or click to select it." :
                        <div>
                            {renderFileItems}
                            {renderRejectionItems}
                            <div className="text-red-600 text-sm">
                                {multipleFleError}
                            </div>
                        </div>
                    }
                    </div>
                </div>
            </section>
            <div className=" pl-2 pt-2 text-sm text-red-600">
                {!isEmptyRenderRejectionItems && "Invalid file type"}
                {invalidDataFile && "The file is missing some mandatory fields. Please ensure all required fields are filled in."}
            </div>
            <GuardianDataImport ref={guardianRef} isDisable={isEmptyRenderFileItems} onGetDataCB={onGetDataCB}/>
        </div>
    )
}

export default StudentDataImport