import {SimpleGrid, Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import _ from "lodash";
import moment from "moment";
import {useEffect, useState} from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {useParams, useSearchParams} from "react-router-dom";
import * as Yup from "yup";
import {InnerLoading} from "../..";
import {ACTIVITY} from "../../../consts/methods.consts";
import {ACTIVITY_TAGS} from "../../../consts/tags.consts";
import {useGetQuery, usePostMutation} from "../../../services/api.service";
import {ToastService} from "../../../services/toast.service";
import {FormInput} from "../../common/form-controllers/FormInput";
import {FormTextArea} from "../../common/form-controllers/FormTextArea";
import PageHeader, {PageHeaderActions} from "../../common/page-header/PageHeader";
import {useObjChange} from "../../hooks";
import ActivityCloseModal from "./components/ActivityCloseModal";
import SelectConstructor from "./components/SelectConstructor";
import SelectTherapist from "./components/SelectTherapist";
import StaffListTable from "./components/view-participants-list/StaffListTable";
import StudentListTable from "./components/view-participants-list/StudentsListTable";
import ParentConsentListTab from "./components/ParentConsentListTab";

moment.locale("en-GB");

const statusColorModel = {
    "Complete": "green",
    "Canceled": "red",
    "Open": "blue"
}

const ViewActivityPage = () => {
    const [searchParams] = useSearchParams();
    const toast = new ToastService(useToast());
    const closeActivityDisclosure = useDisclosure();
    const [qrResendIsLoading, setQrResendIsLoading] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)

    const {id} = useParams()
    const [create] = usePostMutation();

    const {data: _data, isLoading, isFetching} = useGetQuery({
        method: ACTIVITY.GET,
        body: {
            id,
            page: Number(searchParams.get("page"))
        },
        providesTags: [ACTIVITY_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {})
    const studentId = _.get(data, 'student_id')
    const title = _.get(data, "title")
    const status = _.get(data, "status")

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            title: Yup.string().required("Activity title is required"),
            subtype: Yup.string().required("Please choose a valid type"),
            start_time: Yup.string().required("Start time is required"),
            end_time: Yup.string().required("End time is required"),
            guardian_consent: Yup.string().required("Guardian consent is required")
        }),
        onSubmit: async (values: any) => {
            try {
                setIsSubmitting(true)
                const res = await create({
                    method: ACTIVITY.UPDATE,
                    body: {
                        _id: id,
                        ...values,
                        start_time: moment(_.get(values, 'start_time')).format("YYYY-MM-DD H:mm"),
                        end_time: moment(_.get(values, 'end_time')).format("YYYY-MM-DD H:mm")
                    },
                    invalidatesTags: [ACTIVITY_TAGS.VIEW, ACTIVITY_TAGS.LIST,]
                }).unwrap();

                toast.setTitle("Success").setDescription("Activity data updated").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to update activity. Please try again").showErrorToast();
                console.error(error);
            } finally {
                setIsSubmitting(false)
            }
        }
    })

    const {isChanged, setInitialObj} = useObjChange(formik.values)
    const statusConfig = {
        status: status,
        statusColorSchemes: _.get(statusColorModel, status)
    };

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [JSON.stringify(data)])

    const resendQRHandler = async () => {
        try {
            setQrResendIsLoading(true)
            await create({
                method: ACTIVITY.RESEND_QR,
                body: {
                    id,
                },
                invalidatesTags: []
            }).unwrap();

            toast.setTitle("Success").setDescription("The QR code has been successfully resent.").showSuccessToast();
        } catch (error) {
            toast.setTitle("Error").setDescription("Unable to resend QR. Please try again.").showErrorToast();
        } finally {
            setQrResendIsLoading(false)
        }
    }

    const actions: PageHeaderActions = [
        {
            text: "Resend Access QR",
            isDisabled: (status == "Canceled"),
            onClick: resendQRHandler,
            buttonVariant: "outline",
            buttonColorScheme: "cyan",
            isLoading: qrResendIsLoading
        },
        {
            text: "Cancel",
            isDisabled: (status == "Canceled"),
            onClick: () => closeActivityDisclosure.onOpen(),
            buttonVariant: "outline",
            buttonColorScheme: "red"
        },
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "messenger",
            isLoading: isSubmitting
        }
    ]

    if (isLoading || isFetching) {
        return (
            <>
                <PageHeader
                    isLoading={true}
                    enableBackButton
                    actions={actions}
                    title={title}
                    subtitle={studentId}/>
                <InnerLoading/>
            </>
        )
    }

    return (
        <div>
            <PageHeader
                {...statusConfig}
                isLoading={isLoading}
                enableBackButton
                actions={actions}
                title={title}
                subtitle={studentId}/>
            <ActivityCloseModal id={id} disclosure={closeActivityDisclosure}/>
            <Tabs className="mt-3">
                <TabList>
                    <Tab>Info</Tab>
                    <Tab>Consents</Tab>
                </TabList>

                <TabPanels className="animation-form">
                    <TabPanel className="!p-0 !pt-3">
                        <div className="border rounded p-3">
                            <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 4}} spacing={3}>
                                <FormInput isRequired label="Title" formik={formik} name="title"/>
                                <FormInput isReadOnly isRequired label="Type" formik={formik} name="subtype"/>
                                <FormInput isRequired type="datetime-local" label="Start Time" formik={formik}
                                           name="start_time"/>
                                <FormInput isRequired type="datetime-local" label="End Time" formik={formik}
                                           name="end_time"/>
                                <FormTextArea label="Description" formik={formik} name="description"/>
                                {_.get(formik.values, "subtype") === "Therapy" ?
                                    <SelectTherapist isRequired isMulti formik={formik}/> : null}
                                {_.get(formik.values, "subtype") === "Contractor" ?
                                    <SelectConstructor isRequired isMulti formik={formik}/> : null}
                                {_.get(formik.values, "subtype") !== "Contractor" ? <FormInput isReadOnly isRequired
                                                                                               helperText="If you select 'Consent Required', the system will automatically notify the appropriate people."
                                                                                               label="Guardian Consent?"
                                                                                               formik={formik}
                                                                                               name="guardian_consent"/> : null}
                            </SimpleGrid>
                        </div>
                        <StudentListTable data={_.get(data, 'students') ?? []} activityId={id ?? ""}/>
                        <StaffListTable data={_.get(data, 'staff') ?? []} activityId={id ?? ""}/>
                        {/* <GuardianListTable data={_.get(data, 'guardians') ?? []} activityId={id ?? ""}/> */}


                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ParentConsentListTab _id={id}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>

            {/* <FormTester formik={formik} /> */}
        </div>
    )
}

export default ViewActivityPage