import {useState} from "react"
import ChakraTable, {ChakraTableColumns, TableAction} from "../../../common/chakra-table/ChakraTable"
import {useTableDataProvider} from "../../../hooks"
import {useGetQuery} from "../../../../services/api.service"
import {EXPORT_DATA, REPORTS} from "../../../../consts/methods.consts"
import {ACTIVITY_TAGS} from "../../../../consts/tags.consts"
import {useSearchParams} from "react-router-dom"
import _ from "lodash";
import {useDisclosure, useToast} from "@chakra-ui/react";
import ExportModal from "../../../common/export-modal/ExportModal";
import { IOpenPdf, openPdf } from "../../../../utils/utils"
import { ToastService } from "../../../../services/toast.service"

interface IStudentsList {
    id: string | undefined
}

const LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        accessor: "student_id",
        header: "Student Id"
    },
    {
        accessor: "full_name",
        header: "Name"
    },
    {
        accessor: "sign_in_time",
        header: "Sign in time"
    },
    {
        accessor: "description",
        header: "Reason"
    }
]

const StudentsList = ({ id }: IStudentsList) => {
    const [searchParams] = useSearchParams()
    const toast = new ToastService(useToast());
    const [searchQuery, setSearchQuery] = useState<string>()
    const _disclosure = useDisclosure();

    const { data, isLoading, isFetching } = useGetQuery({
        method: REPORTS.ALL_CURRENT_STUDENTS,
        body: {
            page: Number(searchParams.get("page")),
            q: searchQuery
        },
        providesTags: [ACTIVITY_TAGS.LIST]
    })

    const actions: Array<TableAction> = [
        {
            text: "Export as Excel",
            isDisabled: false,
            onClick: _disclosure.onOpen,
            buttonColorScheme: "messenger"
        },
    ]

    const { pageCount, dataList } = useTableDataProvider(data)

    const onSearchHandler = _.debounce((q: string) => {
        setSearchQuery(q)
    }, 200)

    const onPrint = async (columns: any, data: any) => {
        const config: IOpenPdf = {
            tableData: {
                columns,
                data,
                info: {
                    title: "School Emergency Report"
                }
            },
            toast
        }

        openPdf(config);
    }

    return (
        <>
            <ChakraTable
                onSearch={onSearchHandler}
                size={"sm"}
                header title="Students List"
                isLoading={isLoading}
                isFetching={isFetching}
                columns={LIST_TAB_COLUMNS}
                pageCount={pageCount}
                pagination
                actions={actions}
                onPrint={onPrint}
                data={dataList} />
            <ExportModal method={EXPORT_DATA.CURRENT_STUDENTS} disclosure={_disclosure}/>
        </>
    )
}

export default StudentsList