import ChakraTable, { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable";
import _ from "lodash";
import { useGetQuery, usePostMutation } from "../../../../services/api.service";
import { DEPARTMENT } from "../../../../consts/methods.consts";
import { DEPARTMENT_TAGS } from "../../../../consts/tags.consts";
import { useTableDataProvider } from "../../../hooks";
import React, { useEffect, useState } from "react";
import { useFormik } from "formik";
import { useToast } from "@chakra-ui/react";
import { ToastService } from "../../../../services/toast.service";

interface IProps {
    setIsSelected: Function
    disclosure: any,
    formik: any
}

const DepartmentListDataTable: React.FC<IProps> = ({ setIsSelected, disclosure, formik }) => {
    const toast = new ToastService(useToast())
    const [filters, setFilters] = useState({})
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)
    const [create] = usePostMutation()


    const DEPARTMENT_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "Department",
            accessor: "department",
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            options: [
                {
                    label: "Edit",
                    onClick(item) {
                        formik.setFieldValue("department", _.get(item, "department"))
                        formik.setFieldValue("_id", _.get(item, "_id"))
                        setIsSelected(true)
                        disclosure.onOpen()
                    },
                },
                {
                    label: "Delete",
                    onClick(item) {
                        deleteDepartment(_.get(item, "_id"))
                    },
                }
            ]
        }
    ]

    const { data, isLoading, isFetching } = useGetQuery({
        method: DEPARTMENT.LIST,
        body: {
            page,
            length: Number(length),
            ...filters
        },
        providesTags: [DEPARTMENT_TAGS.LIST]
    })

    const { dataList, pageCount } = useTableDataProvider(data);

    const tblFilterFormik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(tblFilterFormik.values)
    }, [JSON.stringify(tblFilterFormik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        tblFilterFormik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({ ...filters, ...value })
    }, 200)

    const deleteDepartment = async (_id: string | undefined) => {
        try {
            await create({
                method: DEPARTMENT.DELETE,
                body: { _id },
                invalidatesTags: [DEPARTMENT_TAGS.LIST]
            }).unwrap()

            toast.setTitle("Success").setDescription("Department removed from the system").showSuccessToast();
        } catch (error) {
            toast.setTitle("Error").setDescription("Unable to remove department. This department is already in use.").showErrorToast();
            console.error(error);
        }
    }

    return (
        <>
            <ChakraTable isFetching={isFetching} onSearch={onSearchHandler} pageCount={pageCount}
                isLoading={isLoading} pagination lengthPaginations size={"sm"} header title="Departments"
                onPage={setPage} onLength={setLength} columns={DEPARTMENT_TAB_COLUMNS} data={dataList} />
        </>
    )
}
export default React.memo(DepartmentListDataTable)