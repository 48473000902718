import {useGetQuery, usePostMutation} from "../../../../services/api.service";
import {SCHOOL} from "../../../../consts/methods.consts";
import {SCHOOL_TAGS} from "../../../../consts/tags.consts";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {ToastService} from "../../../../services/toast.service";
import {useToast} from "@chakra-ui/react";

const SelectSchool = () => {
    const {data} = useGetQuery({
        method: SCHOOL.GET_SCHOOL_LIST,
        body: {},
        providesTags: [SCHOOL_TAGS.LIST]
    })

    const markup = Array.isArray(data?.message) && data?.message.map((line: IListItem) => (
        <ListItem name={line?.name} title={line?.title}/>))

    return (
        <div
            className="w-full max-w-sm p-4 bg-white border border-gray-200 rounded-lg shadow sm:p-6 dark:bg-gray-800 dark:border-gray-700">
            <h5 className="mb-3 text-base font-semibold text-gray-900 md:text-xl dark:text-white">
                Your schools
            </h5>
            <ul className="my-4 space-y-3">
                {markup}
            </ul>
        </div>
    )
}

interface IListItem {
    name: string,
    title: string
}

const ListItem = (props: IListItem) => {
    const navigator: NavigateFunction = useNavigate()
    const [selectSchool] = usePostMutation()
    const toast = new ToastService(useToast())

    const selectHandler = (school: string) => async () => {
        try {
            await selectSchool({
                method: SCHOOL.SELECT_SCHOOL,
                body: {id: school},
                invalidatesTags: []
            }).unwrap()
            navigator('/app')
        } catch (error) {
            toast.setTitle("Error").setDescription("Invalid credentials. Try again").showErrorToast()
            console.error(error)
        }
    }

    return (
        <li onClick={selectHandler(props?.name)}>
            <a href="#"
               className="flex items-center p-3 text-base font-bold text-gray-900 rounded-lg bg-gray-50 hover:bg-gray-100 group hover:shadow dark:bg-gray-600 dark:hover:bg-gray-500 dark:text-white">
                <span className="flex-1 ml-3 whitespace-nowrap">{props?.title}</span>
            </a>
        </li>
    )
}

export default SelectSchool