import SignInOut from "./components/SignInOut"
import React, {useEffect, useState} from "react"
import {ACTIVITY} from "../../../consts/methods.consts"
import {ChakraTable} from "../.."
import {useGetQuery} from "../../../services/api.service"
import {ACTIVITY_TAGS} from "../../../consts/tags.consts"
import {useDisclosure, useToast} from "@chakra-ui/react"
import {ChakraTableColumns} from "../chakra-table/ChakraTable"
import {useObjChange, useTableDataProvider} from "../../hooks"
import _ from "lodash";
import {FormInput} from "../form-controllers/FormInput";
import {useFormik} from "formik";
import {filteredTableData, IOpenPdf, openPdf} from "../../../utils/utils"
import {ToastService} from "../../../services/toast.service"
import SignatureCell from "../signature-cell/SignatureCell";

interface IActivityTab {
    id: string | undefined,
    type: "Student" | "Staff" | "Guardian" | "Contractor" | "Guest" | "Therapist",
    customFilter?: boolean
}

const LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        accessor: "description",
        header: "Description"
    },
    {
        accessor: "sign_in_station",
        header: "Sign In Station"
    },
    {
        accessor: "sign_in",
        header: "Sign In",
        conditions: {
            'No': 'red',
            'Yes': 'green'
        }
    },
    {
        accessor: "sign_in_signature",
        header: "Sign In Signature",
        customRenderer: (value, item) => {
            return <SignatureCell value={value}/>
        }
    },
    {
        accessor: "sign_in_time",
        header: "Sign In Time"
    },
    {
        accessor: "sign_out_station",
        header: "Sign Out Station"
    },
    {
        accessor: "sign_out",
        header: "Sign Out",
        conditions: {
            'No': 'red',
            'Yes': 'green'
        }
    },
    {
        accessor: "sign_out_signature",
        header: "Sign Out Signature",
        customRenderer: (value, item) => {
            return <SignatureCell value={value}/>
        }
    },
    {
        accessor: "sign_out_time",
        header: "Sign Out Time"
    }
]

const ActivityTab = ({id, type, customFilter = false}: IActivityTab) => {
    const [activity, setActivity] = useState(null)
    const toast = new ToastService(useToast());
    const _disclosure = useDisclosure();
    const [filters, setFilters] = useState({})
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)

    const {data, isLoading} = useGetQuery({
        method: ACTIVITY.LIST_BY_USER_TYPE,
        body: {
            id,
            type: type,
            page,
            length: Number(length),
            ...filters
        },
        providesTags: [ACTIVITY_TAGS.LIST]
    })

    const {pageCount, dataList} = useTableDataProvider(data)

    const _onSelected = (line: any) => {
        setActivity(line)
        _disclosure.onOpen()
    }

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    const {isChanged} = useObjChange(formik.values)

    useEffect(() => {
        if (isChanged) {
            onFilterHandler(formik.values)
        }
    }, [JSON.stringify(formik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({...filters, ...value})
    }, 200)

    const customFiltersMarkup = (
        <div className=" flex flex-row justify-center items-center gap-2">
            <FormInput onFocus={(e) => (e.currentTarget.type = "date")} onBlur={(e) => (e.currentTarget.type = "text")}
                       size={"sm"} type="text" isRequired formik={formik} name="from" placeholder="From"/>
            <FormInput onFocus={(e) => (e.currentTarget.type = "date")} onBlur={(e) => (e.currentTarget.type = "text")}
                       size={"sm"} type="text" isRequired formik={formik} name="to" placeholder="To"/>
        </div>
    )

    const onPrint = async (columns: any, data: any) => {

        const columnsAndDataToRemove = ["sign_out_signature", "sign_in_signature"];
        const {filteredColumns, filteredData} = filteredTableData(columns, data, columnsAndDataToRemove)

        const config: IOpenPdf = {
            tableData: {
                columns: filteredColumns,
                data: filteredData,
                filters,
                info: {
                    title: `Student Activity Report`
                }
            },
            toast
        }

        openPdf(config);
    }

    return (
        <>
            <SignInOut disclosure={_disclosure} data={activity}/>
            <ChakraTable
                onSearch={onSearchHandler}
                size={"sm"}
                header title="Activities"
                onSelected={_onSelected}
                isLoading={isLoading}
                columns={LIST_TAB_COLUMNS}
                pageCount={pageCount}
                pagination
                lengthPaginations
                onPrint={onPrint}
                onPage={setPage}
                onLength={setLength}
                customFilterRenderer={customFilter ? customFiltersMarkup : undefined}
                data={dataList}/>
        </>
    )
}

export default ActivityTab
