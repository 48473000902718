import {SimpleGrid} from "@chakra-ui/layout";
import {Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import _ from "lodash";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import * as Yup from "yup";
import {InnerLoading} from "../../..";
import {CONTRACTOR, SCHOOL, VALIDATION} from "../../../../consts/methods.consts";
import {CONTRACTOR_TAGS, FILTER_TAGS} from "../../../../consts/tags.consts";
import {useGetQuery, usePostMutation} from "../../../../services/api.service";
import {ToastService} from "../../../../services/toast.service";
import {FormCheckBox} from "../../../common/form-controllers/FormCheckBox";
import {FormDropdown, FormDropdownOptions} from "../../../common/form-controllers/FormDropdown";
import {FormInput} from "../../../common/form-controllers/FormInput";
import FormSelect from "../../../common/form-controllers/FormSelect";
import PageHeader, {PageHeaderActions} from "../../../common/page-header/PageHeader";
import {useObjChange, useStatusProvider} from "../../../hooks";
import DisclaimerListWithConcent from "../components/DisclaimerListWithConcent";
import ExtendUserModal from "../components/ExtendUserModal";
import ActivityTab from "../../../common/activity-tab/ActivityTab";
import {PHONE_REG_EXP} from "../../../../consts/app.consts";

const gender: FormDropdownOptions = [
    {label: "Male", value: "Male"},
    {label: "Female", value: "Female"}
]

const extend_types: FormDropdownOptions = [
    // { label: "Guardian", value: "Guardian" },
    {label: "Therapist", value: "Therapist"}
]

const titles: FormDropdownOptions = [
    {label: "Mr", value: "Mr"},
    {label: "Ms", value: "Ms"},
    {label: "Miss", value: "Miss"},
    {label: "Mrs", value: "Mrs"},
    {label: "Mx", value: "Mx"}
]

const ViewContractorPage = () => {
    const toast = new ToastService(useToast());
    const {id} = useParams()
    const [create, {isLoading: isMutating}] = usePostMutation();
    const extendDisclosure = useDisclosure()
    const [contractorEmailQ, setContractorEmailQ] = useState(null)
    const [contractorMobileQ, setContractorMobileQ] = useState(null)

    const {data: _data, isLoading, isFetching} = useGetQuery({
        method: CONTRACTOR.GET,
        body: {id},
        providesTags: [CONTRACTOR_TAGS.VIEW]
    })

    const {data: _emailValidationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_CONTRACTOR_EMAIL_UPDATE,
        body: {
            _id: id,
            email: contractorEmailQ
        },
        providesTags: [CONTRACTOR_TAGS.VIEW]
    })

    const {data: _mobileValidationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_CONTRACTOR_MOBILE_UPDATE,
        body: {
            _id: id,
            mobile: contractorMobileQ
        },
        providesTags: [CONTRACTOR_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {});
    const therapistName = `${data?.first_name || ''} ${data?.last_name || ''}`

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            gender: Yup.string().required("Gender is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            mobile: Yup.string().matches(PHONE_REG_EXP, 'Mobile number is not valid')
                .required('Mobile number is required')
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: CONTRACTOR.UPDATE,
                    body: values,
                    invalidatesTags: [CONTRACTOR_TAGS.VIEW, CONTRACTOR_TAGS.LIST, FILTER_TAGS.EXTERNAL_USER_SELECTOR]
                }).unwrap();

                const newId = _.get(res, 'message')
                if (!newId) {
                    throw new Error("Unable to update contractor")
                }

                toast.setTitle("Success").setDescription("Contractor updated").showSuccessToast();
            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to update contractor. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const {isChanged, setInitialObj} = useObjChange(formik.values)
    const statusConfig = useStatusProvider(data);

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [JSON.stringify(data)])

    useEffect(() => {
        contractorEmailQHandler(_.get(formik.values, "email"))
        contractorMobileQHandler(_.get(formik.values, "mobile"))
    }, [_.get(formik.values, "email"), _.get(formik.values, "mobile")])

    const contractorEmailQHandler = _.debounce((value: any) => {
        setContractorEmailQ(value)
    }, 200)

    const contractorMobileQHandler = _.debounce((value: any) => {
        setContractorMobileQ(value)
    }, 200)

    const contractorEmailCustomError = (_emailValidationData?.message > 0) && (
        <p className="mt-1 text-sm text-red-500">Email already taken.</p>
    )

    const contractorMobileCustomError = (_mobileValidationData?.message > 0) && (
        <p className="mt-1 text-sm text-red-500">Mobile number already taken.</p>
    )

    const actions: PageHeaderActions = [
        // {
        //     text: "Extend User",
        //     onClick: extendDisclosure.onOpen,
        //     buttonColorScheme: "messenger"
        // },
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isMutating
        },
    ]

    if (isLoading || isFetching) {
        return (
            <>
                <PageHeader isLoading={true} enableBackButton actions={actions} title={therapistName}
                            subtitle={"Therapist"}/>
                <InnerLoading/>
            </>
        )
    }

    const typeGenerator = () => {
        const type = _.get(data, ['type'], '')

        if (type == 'Mechanical') {
            return ['Contractor', 'Mechanical']
        }

        if (type == 'Plumber') {
            return ['Contractor', 'Plumber']
        }

        return ['Contractor', 'Mechanical', 'Plumber']
    }

    return (
        <>
            <PageHeader {...statusConfig} isLoading={isLoading} enableBackButton actions={actions} title={therapistName}
                        subtitle={"Therapist"}/>
            <ExtendUserModal disclosure={extendDisclosure} data={data} types={extend_types}/>

            <Tabs>
                <TabList>
                    <Tab>General Information</Tab>
                    <Tab>Activities</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel className="!p-0 !pt-3">
                        <div className="animation-form border rounded p-3">
                            <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 4}} spacing={3}>
                                <FormDropdown options={titles} isRequired label="Title" formik={formik} name="title"/>
                                <FormInput autoComplete="off" isRequired label="First Name" formik={formik}
                                    name="first_name" />
                                <FormInput label="Middle Name" formik={formik} name="middle_name" />
                                <FormInput autoComplete="off" isRequired label="Last Name" formik={formik}
                                           name="last_name"/>
                                <FormSelect method={SCHOOL.GET_CONTRACTOR_TYPES} label="Contractor Type" formik={formik}
                                            name="type"/>
                                <FormDropdown options={gender} isRequired label="Gender" formik={formik} name="gender"/>
                                <FormInput isRequired type="email" label="Email" formik={formik} name="email"
                                           customMessage={contractorEmailCustomError}/>
                                <FormInput placeholder="0XXXXXXXXX" isRequired type="tel" label="Mobile" formik={formik}
                                           name="mobile" customMessage={contractorMobileCustomError}/>
                                <FormInput type="text" label="Company" formik={formik} name="company" />
                                <FormCheckBox label="Active" formik={formik} name="active"/>
                            </SimpleGrid>
                        </div>

                        <DisclaimerListWithConcent doc_name="Contractor" type={typeGenerator()}/>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ActivityTab id={id} type={"Contractor"} customFilter={true}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}

export default ViewContractorPage