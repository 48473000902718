import { HStack, Input, VStack, Stack, Checkbox, Button } from "@chakra-ui/react";
import { Badge } from "flowbite-react";
import _ from "lodash";
import { FaTimes } from "react-icons/fa";
import { FILTERS, SCHOOL } from "../../../../consts/methods.consts";
import FormSelect from "../../form-controllers/FormSelect";
import { useFormik } from "formik";
import { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { getStudents } from "../actions/user-selector.actions";
import SelectedItem from "./SelectedItem";
import { isAllChecked, itemSelector, onIsAllCheckChanges } from "../utils/user-selector.utils";

interface StudentTabProps {
    isOpen: boolean,
    selected: [],
    setSelected: any
}

const StudentTab = ({ isOpen, selected, setSelected = () => { } }: StudentTabProps) => {
    const [isLoading, setIsLoading] = useState<boolean>(false);
    const [data, setData] = useState([]);
    const dispatch = useDispatch()

    const formik = useFormik({
        initialValues: {
            name: '',
            class: ''
        },
        onSubmit: () => { }
    })

    useEffect(() => {
        fetchData().then()
    }, [JSON.stringify(formik.values)])

    const fetchData = async () => {
        try {
            setIsLoading(true);

            const method = FILTERS.STUDENT_SELECTOR;
            const body = {
                name: _.get(formik, ['values', 'name'], ''),
                class: _.get(formik, ['values', 'class'], '')
            };

            const res = await dispatch(getStudents(method, body) as any)
            const data = _.get(res, ['data', 'message'], [])

            setData(data);
        } catch (error) {
            console.log(error);
        } finally {
            setIsLoading(false)
        }
    }

    useEffect(() => {
        formik.resetForm();
    }, [isOpen])

    const searchInputOnChange = (event: any) => {
        _.debounce(() => {
            formik.setFieldValue("name", (event.target.value || '').trim())
        }, 1000)()
    }

    const selectedItemMarkup = selected.map((line: any, index: number) => <SelectedItem key={index} onClick={() => itemSelector(line, setSelected)} end={(selected?.length || 0) == (index + 1)} selected={selected} item={line} />)

    const itemMarkup = data.map((line: any, index: number) => <Item key={index} onClick={() => itemSelector(line, setSelected)} end={(data?.length || 0) == (index + 1)} selected={selected} item={line} />)

    return (
        <>
            <HStack>
                <Input className="!border-gray-300" onChange={searchInputOnChange} placeholder="Search by name or user id" size={"sm"} rounded={"md"} shadow={"sm"} />
            </HStack>

            <VStack pt={2} className="item-animation">
                <HStack className="w-full" justifyContent={"space-between"}>
                    <div className="flex items-center gap-2">
                        <FormSelect showLoading={false} size="sm" placeholder={"Select a class"} method={SCHOOL.GET_CLASS_LIST_FOR_STUDENT_SELECTOR} formik={formik} name="class" />
                    </div>
                    <Button onClick={formik.resetForm as any} leftIcon={<FaTimes />} iconSpacing={0} size={"sm"}></Button>
                </HStack>
            </VStack>

            <div className="grid grid-cols-2 gap-4 pt-2">
                <div className="border rounded shadow-sm">
                    <HStack my={0} py={1} px={3} className="border-b mt-2 border-gray-300 bg-slate-100">
                        <Checkbox borderColor={'gray.300'} colorScheme="teal" onChange={(event: any) => onIsAllCheckChanges(event, data, selected, setSelected, 'Student')} isChecked={isAllChecked(selected, data, 'Student')} size={"md"} />
                        <div className="flex justify-between w-full items-center">
                            <p className="ml-1 select-none font-semibold text-gray-600 text-sm">Students</p>
                            {/* <p className="ml-1 select-none font-semibold text-gray-600 text-sm">Class</p> */}
                        </div>
                    </HStack>
                    <Stack gap={0} className="max-h-[45vh] !min-h-[45vh] overflow-y-auto">
                        {!isLoading && itemMarkup}
                        {!isLoading && _.isEmpty(data) && <p className="text-sm text-center mt-3">No students found!</p>}
                    </Stack>
                </div>

                <div className="border rounded shadow-sm">
                    <HStack my={0} py={1} px={3} className="border-b mt-2 border-gray-300 bg-slate-100">
                        <div className="flex justify-between w-full items-center">
                            <p className="select-none font-semibold text-gray-600 text-sm">Selected members({selected?.length || 0})</p>
                            <p className="ml-1 select-none font-semibold text-gray-600 text-sm">User Type</p>
                        </div>
                    </HStack>
                    <Stack gap={0} className="max-h-[45vh] min-h-[45vh] overflow-y-auto">
                        {selectedItemMarkup}
                    </Stack>
                </div>
            </div>
        </>
    )
}

interface ItemProps {
    end?: boolean,
    item: any,
    selected?: any,
    onClick?: any
}

const Item = ({ end, item, selected = [], onClick }: ItemProps) => {
    const isChecked = selected.findIndex((i: any) => i.id == item.id)

    return (
        <VStack className="hover:bg-slate-50 transition-all" px={3} onClick={() => onClick(item)} cursor={"pointer"} borderBottom={end ? 'unset' : '1px'} borderColor={"gray.300"} alignItems={"flex-start"}>
            <HStack gap={3} py={2} justifyContent={"space-between"} w={'full'}>
                <div className="flex items-center gap-3">
                    <Checkbox onChange={() => onClick(item)} className="outline-none " colorScheme="teal" isChecked={isChecked != -1} size={"md"} />
                    <VStack spacing={0} alignItems={"flex-start"}>
                        <p className="select-none text-gray-600 text-sm">{item.full_name}</p>
                        <p className="text-gray-500 select-none text-xs">{item.student_id}</p>
                    </VStack>
                </div>
                <Badge color={"purple"} className="select-none">{item.class}</Badge>
            </HStack>
        </VStack>
    )
}

export default StudentTab;