import {ChakraTable} from "../../../../index";
import {ChakraTableColumns} from "../../../../common/chakra-table/ChakraTable";
import {useGetQuery} from "../../../../../services/api.service";
import {THERAPIST} from "../../../../../consts/methods.consts";
import {THERAPIST_TAGS} from "../../../../../consts/tags.consts";
import {useTableDataProvider} from "../../../../hooks";
import {NavigateFunction, useNavigate} from "react-router";
import _ from "lodash";
import {FormDropdown, FormDropdownOptions} from "../../../../common/form-controllers/FormDropdown";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {useToast} from "@chakra-ui/react";
import {ToastService} from "../../../../../services/toast.service";
import {IOpenPdf, openPdf} from "../../../../../utils/utils";

const TherapistListDataTable = () => {
    const navigate: NavigateFunction = useNavigate()
    const [filters, setFilters] = useState<object>({})
    const toast = new ToastService(useToast());
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)

    const {data, isLoading, isFetching} = useGetQuery({
        method: THERAPIST.LIST,
        body: {
            page,
            length: Number(length),
            ...filters
        },
        providesTags: [THERAPIST_TAGS.LIST]
    })

    const {dataList, pageCount, totalResult} = useTableDataProvider(data);

    const tableLineClickHandler = (line: any) => {
        navigate(`${line._id}`)
    }

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({...filters, ...value})
    }, 200)

    const customFilters = (
        <div className="flex gap-2 items-center">
            <FormDropdown minW="40" size={"sm"} options={isActiveOptions} isRequired formik={formik} name="is_active"
                          placeholder="Status"/>
        </div>
    )

    const onPrint = async (columns: any, data: any) => {
        const config: IOpenPdf = {
            tableData: {
                columns,
                data,
                filters,
                info: {
                    title: "Therapist List"
                }
            },
            toast
        }

        openPdf(config);
    }

    return (
        <ChakraTable onPage={setPage} onLength={setLength} onPrint={onPrint} onSearch={onSearchHandler}
                     pageCount={pageCount} isLoading={isLoading} isFetching={isFetching} pagination size={"sm"}
                     customFilterRenderer={customFilters} lengthPaginations totalResult={totalResult} header
                     title="Therapists" onSelected={tableLineClickHandler} columns={TAB_COLUMNS} data={dataList}/>
    )
}

const isActiveOptions: FormDropdownOptions = [
    {label: "Active", value: 1},
    {label: "Inactive", value: 0}
]

const TAB_COLUMNS: ChakraTableColumns = [
    {
        header: "First Name",
        accessor: "first_name",
    },
    {
        header: "Last Name",
        accessor: "last_name",
    },
    {
        header: "Email",
        accessor: "email",
    },
    {
        header: "Mobile",
        accessor: "mobile",
    },
    {
        header: "Gender",
        accessor: "gender",
    },
    {
        header: "Is Active",
        accessor: "active",
        conditions: {
            'INACTIVE': 'red',
            'ACTIVE': 'green'
        },
    },
]

export default TherapistListDataTable