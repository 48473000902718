import {NavigateFunction, useNavigate} from "react-router";
import {PageHeader} from "../..";
import {PageHeaderActions} from "../../common/page-header/PageHeader";
import StudentListDataTable from "./components/StudentListDataTable";

const StudentListPage = () => {
    const navigate: NavigateFunction = useNavigate()

    const actions: PageHeaderActions = [
        {
            text: "Create New",
            onClick: () => navigate("new"),
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    return (
        <div>
            <PageHeader subtitle="View and Manage registered students" title={`Students`} actions={actions}/>
            <StudentListDataTable/>
        </div>
    )
}

export default StudentListPage;