import { VStack } from "@chakra-ui/react"
import { useParams } from "react-router-dom"
import { SCHOOL } from "../../../../consts/methods.consts";
import { DISCLAIMER_TAGS } from "../../../../consts/tags.consts";
import { useGetQuery } from "../../../../services/api.service";
import ChakraTable, { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable";
import { useTableDataProvider } from "../../../hooks";

interface DisclaimerListWithConcentProps {
    doc_name: string,
    type: String | Array<String>
}

const DisclaimerListWithConcent = ({ type, doc_name }: DisclaimerListWithConcentProps) => {
    const { id } = useParams();
    const { data, isLoading } = useGetQuery({
        method: SCHOOL.GET_DISCLAIMER_LIST_BY_USER_TYPE_WITH_CONCENT,
        body: {
            doc_name,
            type,
            id
        },
        providesTags: [DISCLAIMER_TAGS.LIST]
    })

    const { dataList } = useTableDataProvider(data, { withoutPagination: true });

    const COLUMNS: ChakraTableColumns = [
        {
            header: "Title",
            accessor: "title",
            customRenderer(value: any, item: any) {
                return (
                    <VStack spacing={0} w={{ base: 28, lg: 72 }} alignItems={"flex-start"}>
                        <p className="font-semibold">{value}</p>
                        {item?.sub_title && <p className="text-gray-500">{item.sub_title}</p>}
                    </VStack>
                )
            },
        },
        {
            header: "Validity",
            accessor: "validity",
        },
        {
            header: "Is Active",
            accessor: "active",
            conditions: {
                'INACTIVE': 'red',
                'ACTIVE': 'green'
            },
        },
        {
            header: "Required",
            accessor: "required_in_sign_in",
            conditions: {
                'YES': 'red',
                'NO': 'green'
            },
        },
        {
            header: "Created At",
            accessor: "creation",
        },
        {
            header: "Link",
            accessor: "link",
            type: "link"
        },
        {
            header: "Concent",
            accessor: "concent",
            conditions: {
                'NO': 'red',
                'YES': 'green'
            },
        },
    ]

    return (
        <div className="mt-3 animation-form-l3">
            <ChakraTable header isLoading={isLoading} size={"sm"} title="Policies" columns={COLUMNS} data={dataList} />
        </div>
    )
}

export default DisclaimerListWithConcent;