import { Box, Heading, Link } from "@chakra-ui/react"
import _ from "lodash"
import { REPORTS } from "../../../../consts/methods.consts"
import { ACTIVITY_TAGS } from "../../../../consts/tags.consts"
import { useGetQuery } from "../../../../services/api.service"
import ChakraTable, { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable"

const AttendanceToday = () => {
    const LIST_TAB_COLUMNS: ChakraTableColumns = [
        {
            accessor: "full_name",
            header: "Name",
            customRenderer: (value, item) => {
                if (_.get(item, 'type') === "Student") {
                    return (
                        <Link color="#0073E5" href={`/app/students/${_.get(item, '_id')}`} isExternal>{value}</Link>
                    )
                }

                if (_.get(item, 'type') === "Staff") {
                    return (
                        <Link color="#0073E5" href={`/app/staff/${_.get(item, '_id')}`} isExternal>{value}</Link>
                    )
                }

                if (_.get(item, 'type') === "Guardian") {
                    return (
                        <Link color="#0073E5" href={`/app/guardians/${_.get(item, '_id')}`} isExternal>{value}</Link>
                    )
                }

                if (_.get(item, 'type') === "Therapist") {
                    return (
                        <Link color="#0073E5" href={`/app/therapists/${_.get(item, '_id')}`} isExternal>{value}</Link>
                    )
                }

                if (_.get(item, 'type') === "Contractor") {
                    return (
                        <Link color="#0073E5" href={`/app/contractors/${_.get(item, '_id')}`} isExternal>{value}</Link>
                    )
                }

                if (_.get(item, 'type') === "Guest") {
                    return (
                        <Link color="#0073E5" href={`/app/guests/${_.get(item, '_id')}`} isExternal>{value}</Link>
                    )
                }

                return <p>{value}</p>
            },
        },
        {
            accessor: "type",
            header: "Type",
            conditions: {
                'Staff': 'cyan',
                'Student': 'blue',
                'Guest': 'linkedin',
                'Contractor': 'linkedin',
                'Guardian': 'linkedin',
                'Therapist': 'linkedin'
            }
        },
        {
            accessor: "sign_in_time",
            header: "Time"
        },
        {
            accessor: "sign_in_station",
            header: "Station"
        }
    ]

    const { data, isLoading } = useGetQuery({
        method: REPORTS.TODAY_ATTENDANCE,
        body: { id: 10 },
        providesTags: [ACTIVITY_TAGS.LIST]
    })

    return (
        <div className="border mt-4 pt-4 col-span-1 rounded-md shadow-sm relative">
            <Box px={4} pb={3}>
                <Heading fontSize='xl'>Check In - Today</Heading>
            </Box>
            <ChakraTable height={350} disableBorder columns={LIST_TAB_COLUMNS} data={data?.message ?? []} />
        </div>
    )
}

export default AttendanceToday