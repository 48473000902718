import {SimpleGrid, useDisclosure, useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import _ from "lodash";
import moment from "moment";
import {useEffect, useState} from "react";
import "react-big-calendar/lib/css/react-big-calendar.css";
import {useParams, useSearchParams} from "react-router-dom";
import * as Yup from "yup";
import {InnerLoading} from "../..";
import {PARCEL, STAFF} from "../../../consts/methods.consts";
import {PARCEL_TAGS, STAFF_TAGS} from "../../../consts/tags.consts";
import {useGetQuery, usePostMutation} from "../../../services/api.service";
import {ToastService} from "../../../services/toast.service";
import {FormInput} from "../../common/form-controllers/FormInput";
import FormLiveSelect from "../../common/form-controllers/FormLiveSelect";
import FormSelect from "../../common/form-controllers/FormSelect";
import {FormTextArea} from "../../common/form-controllers/FormTextArea";
import PageHeader, {PageHeaderActions} from "../../common/page-header/PageHeader";
import {useObjChange} from "../../hooks";
import DeliverNotificationModal from "./components/DeliverNotificationModal";
import {PHONE_REG_EXP} from "../../../consts/app.consts";

moment.locale("en-GB");

const ViewParcelPage = () => {
    const [query, setQuery] = useState("")
    const [searchParams] = useSearchParams();
    const toast = new ToastService(useToast());
    const disclosure = useDisclosure();
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const [markAsPicking, setMarkAsPicking] = useState<boolean>(false)

    const {id} = useParams()
    const [create] = usePostMutation();

    const {data: _data, isLoading, isFetching: isFetchGetParcel} = useGetQuery({
        method: PARCEL.GET,
        body: {
            id,
            page: Number(searchParams.get("page"))
        },
        providesTags: [PARCEL_TAGS.VIEW]
    })

    const {data: staffData, isFetching} = useGetQuery({
        method: STAFF.SEARCH,
        body: {q: query || _data?.message?.receiver_name},
        providesTags: [STAFF_TAGS.DROPDOWN]
    })

    const data = _.get(_data, ['message'], {})
    const itemName = _.get(_data, ['message', 'item_name'])

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            sender: Yup.string().required("Sender is required"),
            receiver: Yup.string().required("Receiver is required"),
            item_name: Yup.string().required("Item name is required"),
            sender_contact: Yup.string().matches(PHONE_REG_EXP, 'Contact number is not valid').nullable()
        }),
        onSubmit: async (values: any) => {
            try {
                setIsSubmitting(true)
                const res = await create({
                    method: PARCEL.UPDATE,
                    body: {
                        ...values,
                        verified_receiver: _.get(values, ["verified_receiver", "value"]) ?? ""
                    },
                    invalidatesTags: [PARCEL_TAGS.VIEW, PARCEL_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription("Delivery data updated").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to update delivery data. Please try again").showErrorToast();
                console.error(error);
            } finally {
                setIsSubmitting(false)
            }
        }
    })

    const {isChanged, setInitialObj} = useObjChange(formik.values)

    useEffect(() => {
        if (!_.isEmpty(data)) {
            let cloned_data = _.cloneDeep(data)
            // modify data model
            if (_.get(cloned_data, "verified_receiver")) {
                cloned_data["verified_receiver"] = {
                    value: cloned_data["verified_receiver"],
                    label: cloned_data["verified_receiver_name"]
                }
            }
            formik.setValues(cloned_data)
            setInitialObj(cloned_data)
        }
    }, [JSON.stringify(data)])

    const staffSearchHandler = _.debounce((text: string) => {
        setQuery(text)
    }, 250)

    const notifyToReceiver = () => {
        disclosure.onOpen()
    }

    const markAsPickup = async () => {
        try {
            setMarkAsPicking(true)
            const res = await create({
                method: PARCEL.UPDATE,
                body: {
                    _id: id,
                    is_pickup: 1,
                    pickup_date: moment().format("YYYY-MM-DD H:mm")
                },
                invalidatesTags: [PARCEL_TAGS.VIEW, PARCEL_TAGS.LIST]
            }).unwrap();

            toast.setTitle("Success").setDescription("Mark as pickup").showSuccessToast();

        } catch (error) {
            toast.setTitle("Error").setDescription("Unable to mark as pickup. Please try again").showErrorToast();
            console.error(error);
        } finally {
            setMarkAsPicking(false)
        }
    }

    const actions: PageHeaderActions = [
        {
            text: "Notify",
            onClick: notifyToReceiver,
            isDisabled: !_.isString(_.get(formik.values, ["verified_receiver", "value"])),
            buttonVariant: "outline",
            buttonColorScheme: "blue"
        },
        {
            text: "Pickup",
            onClick: markAsPickup,
            isDisabled: _.get(formik.values, "is_pickup") === 1,
            buttonVariant: "outline",
            buttonColorScheme: "green",
            isLoading: markAsPicking
        },
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "messenger",
            isLoading: isSubmitting
        }
    ]

    if (isLoading || isFetchGetParcel) {
        return (
            <>
                <PageHeader
                    isLoading={true}
                    enableBackButton
                    actions={actions}
                    title={itemName}
                    subtitle={"Parcel"}/>
                <InnerLoading/>
            </>
        )
    }

    const statusSchema = {
        status: _.get(data, 'is_pickup', 0) ? "Picked Up" : "Not picked up",
        statusColorSchemes: _.get(data, 'is_pickup', 0) ? "green" : "red"
    }

    return (
        <div>
            <PageHeader
                {...statusSchema}
                isLoading={isLoading}
                enableBackButton
                actions={actions}
                title={itemName}
                subtitle={"Parcel"}/>
            <DeliverNotificationModal id={id} note={_.get(formik.values, ["note"])} disclosure={disclosure}/>
            <div className="border rounded p-3">
                <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 4}} spacing={3}>
                    <FormSelect method={PARCEL.LIST_FOR_DROPDOWN} isRequired label="Sender" formik={formik}
                                name="sender"/>
                    {/* <FormSelect
                        method={SCHOOL.GET_STATION_LIST_FOR_DROPDOWN}
                        isRequired label="Station"
                        formik={formik}
                        name="station" /> */}
                    <FormInput label="Station" formik={formik} name="station_name" isReadOnly isRequired/>
                    <FormInput placeholder="0XXXXXXXXX" label="Contact Number" formik={formik} name="sender_contact"/>
                    <FormLiveSelect label="Verified Receiver" formik={formik} name={"verified_receiver"}
                                    options={staffData?.message?.map((line: any) => ({
                                        label: line?.full_name,
                                        value: line?.name
                                    }))} isLoading={isFetching} onInputChange={staffSearchHandler}
                                    helperText="You can send notifications"/>
                    <FormInput isReadOnly label="Receiver" formik={formik} name={"receiver"}
                               helperText="This is a note from delivery service"/>
                    <FormInput isRequired label="Item Name" formik={formik} name="item_name"/>
                    <FormInput isRequired label="Received Date" formik={formik} name="received_date" isReadOnly/>
                    <FormInput isRequired label="Pickup Date" formik={formik} name="pickup_date" isReadOnly/>
                    <FormTextArea label="Note" formik={formik} name="note"/>
                </SimpleGrid>
            </div>
            {/* <FormTester formik={formik} /> */}
        </div>
    )
}

export default ViewParcelPage