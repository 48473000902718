import { ThemeTypings } from "@chakra-ui/react"
import _, { PropertyPath } from "lodash"

type UseStatusProvider = {
    status: string,
    statusColorSchemes: ThemeTypings["colorSchemes"]
}

type Options = {
    key?: PropertyPath,
    trueColor?: ThemeTypings["colorSchemes"],
    falseColor?: ThemeTypings["colorSchemes"]
}

const initialOptions: Options = {
    key: ['active'],
    trueColor: 'green',
    falseColor: 'red'
}

export const useStatusProvider = (data: object, options: Options = initialOptions): UseStatusProvider => {
    const isActive = _.get(data, options.key as PropertyPath) ? 'Active' : 'InActive'

    return {
        status: isActive,
        statusColorSchemes: isActive == 'Active' ? initialOptions.trueColor as ThemeTypings["colorSchemes"] : initialOptions.falseColor as ThemeTypings["colorSchemes"]
    }
}