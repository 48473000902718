import {NavigateFunction, useNavigate} from "react-router";
import {PageHeader} from "../..";
import {PageHeaderActions} from "../../common/page-header/PageHeader";
import GroupListDataTable from "./components/GroupListDataTable";

const GroupListPage = () => {
    const navigate: NavigateFunction = useNavigate()


    const actions: PageHeaderActions = [

        {
            text: "Create New",
            onClick: () => navigate("new"),
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]


    return (
        <div>
            <PageHeader subtitle="View and Manage registered groups" title={`Groups`} actions={actions}/>
            <GroupListDataTable/>
        </div>
    )
}

export default GroupListPage;