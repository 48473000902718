import {NavigateFunction, useNavigate} from "react-router";
import {PageHeader} from "../../..";
import {PageHeaderActions} from "../../../common/page-header/PageHeader";
import GuestListDataTable from "./components/GuestListDataTable";

const GuestListPage = () => {
    const navigate: NavigateFunction = useNavigate()


    const actions: PageHeaderActions = [

        {
            text: "Create New",
            onClick: () => navigate("new"),
            buttonVariant: "solid",
            buttonColorScheme: "teal"
        }
    ]

    return (
        <div>
            <PageHeader subtitle="View and Manage registered guests" title={`Guests`} actions={actions}/>
            <GuestListDataTable/>
        </div>
    )
}

export default GuestListPage;