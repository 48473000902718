import ChakraTable, {ChakraTableColumns} from "../../../common/chakra-table/ChakraTable";
import _ from "lodash";
import {useGetQuery} from "../../../../services/api.service";
import {STATION} from "../../../../consts/methods.consts";
import {STATION_TAGS} from "../../../../consts/tags.consts";
import {useTableDataProvider} from "../../../hooks";
import {useFormik} from "formik";
import React, {useEffect, useState} from "react";

interface IProps {
    setIsSelected: Function
    disclosure: any,
    formik: any
}

const StationListDataTable: React.FC<IProps> = ({setIsSelected, disclosure, formik}) => {
    
    const [filters, setFilters] = useState({})
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)

    const DEPARTMENT_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "Station",
            accessor: "station",
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            options: [
                {
                    label: "Edit",
                    onClick(item) {
                        formik.setFieldValue("station", _.get(item, "station"))
                        formik.setFieldValue("_id", _.get(item, "_id"))
                        setIsSelected(true)
                        disclosure.onOpen()
                    },
                }
            ]
        }
    ]

    const {data, isLoading, isFetching} = useGetQuery({
        method: STATION.LIST,
        body: {
            page,
            length: Number(length),
            ...filters
        },
        providesTags: [STATION_TAGS.LIST]
    })

    const {dataList, pageCount} = useTableDataProvider(data);

    const tblFilterFormik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(tblFilterFormik.values)
    }, [JSON.stringify(tblFilterFormik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        tblFilterFormik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({...filters, ...value})
    }, 200)

    return (
        <>
            <ChakraTable onSearch={onSearchHandler} pageCount={pageCount} isLoading={isLoading}
                         isFetching={isFetching} pagination lengthPaginations size={"sm"} header title="Stations"
                         onPage={setPage} onLength={setLength} columns={DEPARTMENT_TAB_COLUMNS} data={dataList}/>
        </>
    )
}
export default StationListDataTable