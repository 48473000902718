import React, {forwardRef, JSX, useEffect, useImperativeHandle, useState} from "react";
import {ToastService} from "../../../../services/toast.service";
import {useToast} from "@chakra-ui/react";
import {FileRejection, useDropzone} from "react-dropzone";
import * as XLSX from "xlsx";
import Papa from "papaparse";
import _ from "lodash";

interface IProps {
    isDisable: boolean
    onGetDataCB: (data: Array<any>) => void
}

type IRef = {
    onResetHandler: () => void;
};

const GuardianDataImport = forwardRef<IRef, IProps>(({isDisable, onGetDataCB}, ref) => {
    const toast: ToastService = new ToastService(useToast())
    const [acceptedFiles, setAcceptedFiles] = useState<File[]>([]);
    const [fileRejections, setFileRejections] = useState<FileRejection[]>([]);
    const [invalidDataFile, setInvalidDataFile] = useState<boolean>(false)
    const [multipleFleError, setMultipleFleError] = useState<string | null>(null)

    const onDropHandler = (acceptedFiles: File[]) => {
        if (isDisable) return
        setMultipleFleError(null)
        setFileRejections([])
        setAcceptedFiles(acceptedFiles);
    }

    const onResetHandler = () => {
        setAcceptedFiles([])
    }

    useImperativeHandle(ref, () => ({
        onResetHandler: onResetHandler
    }))

    const onRejectedHandler = (fileRejections: FileRejection[]) => {
        if (isDisable) return
        setAcceptedFiles([])
        if (fileRejections.length > 1) {
            setMultipleFleError("Can't add multiple file. Please try again")
            return
        }
        setFileRejections(fileRejections);
    }


    const {getRootProps, getInputProps} = useDropzone({
        accept: {
            'text/csv': [],
            'application/vnd.ms-excel': [],
            'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': []
        },
        multiple: false,
        maxFiles: 1,
        onDrop: onDropHandler,
        onDropRejected: onRejectedHandler
    });

    const parseExcel = (file: File): void => {
        try {
            const reader = new FileReader();

            reader.onload = (e: any) => {
                const xlsxData = new Uint8Array(e.target.result);
                const workbook = XLSX.read(xlsxData, {type: 'array'});

                const sheetName = workbook.SheetNames[0];
                const sheet = workbook.Sheets[sheetName];

                const columnData: Array<string> = XLSX.utils.sheet_to_json(sheet, {header: 1})[0] as Array<string>

                if (!columnData.some((columnName) => columnName.trim() === "DFKEY")) {
                    setInvalidDataFile(true)
                    onGetDataCB([])
                    return
                } else {
                    setInvalidDataFile(false)
                }

                const excelData: Array<any> = XLSX.utils.sheet_to_json(sheet, {header: 1});

                // Assuming the first row contains headers
                const headers: Array<any> = excelData[0];
                const formattedData = excelData.slice(1).map((row: any) => {
                    const rowData: any = {};
                    headers.forEach((value: any, index: number) => {
                        rowData[value] = row[index] ? row[index] : null
                    })
                    return rowData;
                })

                onGetDataCB(formattedData)
            };

            reader.readAsArrayBuffer(file);

        } catch (e) {
            toast.setTitle("Error").setDescription("Internal Error").showErrorToast();
        }
    };

    const parseCSV = (file: File) => {
        Papa.parse(file, {
            header: true,
            dynamicTyping: true,
            skipEmptyLines: true,
            complete: (result: any) => {
                const hasSTKEYColumn = result.meta.fields.some((columnName: any) => columnName.trim() === "DFKEY")

                if (!hasSTKEYColumn) {
                    setInvalidDataFile(true)
                    onGetDataCB([])
                    return;
                } else {
                    setInvalidDataFile(false)
                }

                onGetDataCB(result.data)
            },
            error: (error: any) => {
                console.error('Error parsing CSV:', error);
                toast.setTitle("Error").setDescription("Internal Error").showErrorToast();
            },
        });
    };

    const onUploadHandler = () => {
        if (["application/vnd.ms-excel", "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet"]
            .includes(acceptedFiles[0].type)) {
            parseExcel(acceptedFiles[0])
        }
        if (acceptedFiles[0].type === "text/csv") {
            parseCSV(acceptedFiles[0])
        }
    }
    useEffect(() => {
        if (acceptedFiles.length > 0) {
            onUploadHandler()
        } else {
            onGetDataCB([])
        }
    }, [acceptedFiles])

    const renderFileItems: Array<JSX.Element | []> = acceptedFiles.map((file: File) => {
        if (!acceptedFiles.length) return [];

        return (
            <div key={file.name}>
                {file.name}
            </div>
        )
    });

    const renderRejectionItems: Array<JSX.Element | []> = fileRejections.map(({file, errors}) => {
            if (!fileRejections.length) return [];

            return (
                <div className="text-red-600 text-sm" key={file.name}>
                    {file.name}
                </div>
            )
        }
    );

    const isEmptyRenderFileItems: boolean = _.isEmpty(renderFileItems)
    const isEmptyRenderRejectionItems: boolean = _.isEmpty(renderRejectionItems)
    const borderColor: string = isEmptyRenderFileItems ? 'border-gray-200' : 'border-green-500'

    return (
        <div className="my-2">
            <section
                style={isDisable ? {cursor: "no-drop"} : {cursor: "pointer"}}
                className={`${isDisable ? 'bg-gray-200 border-gray-400' : 'bg-gray-50'} p-4 border-dashed border-2 
                ${!isEmptyRenderRejectionItems || multipleFleError || invalidDataFile ? 'border-red-500' : borderColor} 
                rounded-xl cursor-pointer`}>
                <div {...getRootProps({className: 'dropzone'})}>
                    <input {...getInputProps()} disabled={isDisable}/>
                    <div
                        className={`${isDisable ? 'text-gray-400' : 'text-black'}`}> {isEmptyRenderFileItems && isEmptyRenderRejectionItems && !multipleFleError ? "Drag and drop the guardian data file you want to upload, or click to select it." :
                        <div>
                            {renderFileItems}
                            {renderRejectionItems}
                            <div className="text-red-600 text-sm">
                                {multipleFleError}
                            </div>
                        </div>
                    }
                    </div>
                </div>
            </section>
            <div className=" pl-2 pt-2 text-sm text-red-600">
                {!isEmptyRenderRejectionItems && "Invalid file type"}
                {invalidDataFile && "The file is missing some mandatory fields. Please ensure all required fields are filled in."}
            </div>
        </div>
    )
})

export default GuardianDataImport