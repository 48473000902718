import {SimpleGrid} from "@chakra-ui/layout";
import {useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import _ from "lodash";
import {NavigateFunction, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {InnerLoading, PageHeader} from "../..";
import {RESOURCE, SCHOOL, VALIDATION} from "../../../consts/methods.consts";
import {RESOURCE_TAGS} from "../../../consts/tags.consts";
import {useGetQuery, usePostMutation} from "../../../services/api.service";
import {ToastService} from "../../../services/toast.service";
import {FormInput} from "../../common/form-controllers/FormInput";
import FormSelect from "../../common/form-controllers/FormSelect";
import {FormTextArea} from "../../common/form-controllers/FormTextArea";
import {PageHeaderActions} from "../../common/page-header/PageHeader";
import {useEffect, useState} from "react";


const NewResourcePage = () => {
    const navigate: NavigateFunction = useNavigate();
    const [create, {isLoading}] = usePostMutation();
    const toast = new ToastService(useToast());
    const [resourceIdQ, setResourceIdQ] = useState(null)

    const {data: _validationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_RESOURCE_ID_CREATE,
        body: {
            resource_id: resourceIdQ
        },
        providesTags: [RESOURCE_TAGS.VIEW]
    })

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            resource_id: Yup.string().required("Resource ID is required"),
            title: Yup.string().required("Title is required"),
            type: Yup.string().required("Resource Type is required"),
            department: Yup.string().required("Department is required")
        }),
        onSubmit: async (values: any) => {

            try {
                const res = await create({
                    method: RESOURCE.ADD,
                    body: values,
                    invalidatesTags: [RESOURCE_TAGS.LIST]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Unable to register new resource")
                }

                toast.setTitle("Success").setDescription("Resource registered to the system").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/resources/${newId}`);
                }, 1500)

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to register resource. Please try again").showErrorToast();
                console.error(error);
            }

        }
    })

    useEffect(() => {
        resourceIdQHandler(_.get(formik.values, "resource_id"))
    }, [_.get(formik.values, "resource_id")])

    const resourceIdQHandler = _.debounce((value: any) => {
        setResourceIdQ(value)
    }, 200)

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isLoading
        },
    ]

    const resourceIDCustomError = (_validationData?.message > 0) && (
        <p className=" text-sm text-red-500">Resource ID already taken.</p>
    )

    return (
        <>
            <PageHeader isLoading={isLoading} enableBackButton actions={actions} title="Create New Resource"
                        subtitle="Resource"/>
            {
                isLoading ? <InnerLoading/> :
                    <>
                        <div className=" border rounded p-3">
                            <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 4}} spacing={3}>
                                <FormInput customMessage={resourceIDCustomError} isRequired label="Resource Id"
                                           formik={formik} name="resource_id"/>
                                <FormInput isRequired label="Name" formik={formik} name="title"/>
                                <FormSelect method={SCHOOL.GET_RESOURCE_TYPE_LIST_FOR_DROPDOWN} isRequired
                                            label="Resource Type" formik={formik} name="type"/>
                                <FormSelect method={SCHOOL.GET_DEPARTMENT_LIST_FOR_DROPDOWN} isRequired
                                            label="Department" formik={formik} name="department"/>
                                <FormTextArea label="Note" formik={formik} name="description"/>
                            </SimpleGrid>
                        </div>
                    </>
            }
        </>
    )
}

export default NewResourcePage