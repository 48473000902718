import { Button, HStack, Modal, ModalBody, ModalCloseButton, ModalContent, ModalFooter, ModalHeader, ModalOverlay, Tab, TabList, TabPanel, TabPanels, Tabs, UseDisclosureProps } from "@chakra-ui/react"
import { useEffect, useState } from "react"
import AcademicStaffTab from "./components/AcademicStaffTab"
import NonAcademicStaffTab from "./components/NonAcademicStaffTab"
import StudentTab from "./components/StudentTab"
import GuardianTab from "./components/GuardianTab"
import OtherTab from "./components/OtherTab"

interface UserSelectorProps {
    isOpen: UseDisclosureProps["isOpen"],
    onClose: UseDisclosureProps["onClose"] | undefined,
    setSelectedItems: any
    selectedItems: any
}

const UserSelector = ({ isOpen = false, onClose = () => { }, setSelectedItems = () => { }, selectedItems = [] }: UserSelectorProps) => {
    const [selected, setSelected] = useState<any>([]);

    useEffect(() => {
        setSelected(selectedItems);
    }, [selectedItems])

    const onSaveHandler = () => {
        setSelectedItems((prev: any) => [...selected]);
        onClose();
    }

    return (
        <Modal
            closeOnOverlayClick={false}
            isOpen={isOpen}
            motionPreset='slideInBottom'
            onClose={onClose}
            size={"5xl"}>
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>Select Members</ModalHeader>
                <ModalCloseButton autoFocus={false} _focus={{ border: 'none' }} />
                <ModalBody pb={0}>
                    <Tabs isFitted colorScheme="teal" variant='enclosed-colored'>
                        <TabList className="!mb-[9px]">
                            <Tab fontWeight={"semibold"} className="rounded-l-md" fontSize={"sm"}>Student</Tab>
                            <Tab fontWeight={"semibold"} fontSize={"sm"}>Academic Staff</Tab>
                            <Tab fontWeight={"semibold"} fontSize={"sm"}>Non-Academic Staff</Tab>
                            {/* <Tab fontWeight={"semibold"} fontSize={"sm"}>Guardian</Tab>
                            <Tab fontWeight={"semibold"} className="rounded-r-md" fontSize={"sm"}>Other</Tab> */}
                        </TabList>
                        <TabPanels>
                            <TabPanel className="!p-0">
                                <StudentTab isOpen={isOpen} selected={selected} setSelected={setSelected} />
                            </TabPanel>
                            <TabPanel className="!p-0">
                                <AcademicStaffTab isOpen={isOpen} selected={selected} setSelected={setSelected} />
                            </TabPanel>
                            <TabPanel className="!p-0">
                                <NonAcademicStaffTab isOpen={isOpen} selected={selected} setSelected={setSelected} />
                            </TabPanel>
                            {/* <TabPanel className="!p-0">
                                <GuardianTab isOpen={isOpen} selected={selected} setSelected={setSelected} />
                            </TabPanel>
                            <TabPanel className="!p-0">
                                <OtherTab isOpen={isOpen} selected={selected} setSelected={setSelected} />
                            </TabPanel> */}
                        </TabPanels>
                    </Tabs>
                </ModalBody>

                <ModalFooter>
                    <HStack spacing={3}>
                        <Button variant={"solid"} size={"sm"} colorScheme="teal" onClick={onSaveHandler}>Apply</Button>
                        <Button variant={"solid"} size={"sm"} colorScheme="red" onClick={onClose}>Cancel</Button>
                    </HStack>
                </ModalFooter>
            </ModalContent >
        </Modal >
    )
}


export default UserSelector