import { IconButton } from "@chakra-ui/react"
import { current } from "@reduxjs/toolkit";
import { useEffect, useState } from "react";
import { FiArrowLeft, FiArrowRight } from "react-icons/fi"
import { useSearchParams } from "react-router-dom"
import { URLSearchParams } from "url";

interface TablePaginationProps {
    pageCount: number,
    isLoading?: boolean,
    onPage?: (page: number) => void
}

const TablePagination = ({ pageCount, isLoading, onPage }: TablePaginationProps) => {
    const [currentPage, setCurrentPage] = useState(1);

    const onNext = () => setCurrentPage(prev => prev + 1)

    const onPrevious = () => setCurrentPage(prev => prev !== 1 ? prev - 1 : prev)

    useEffect(() => { onPage && onPage(currentPage) }, [currentPage])

    const styles = {
        opacity: isLoading ? 0 : 1,
        height: isLoading ? 0 : 52
    }

    return (
        <div style={{ ...styles }}
            className="w-full px-4 transition-all duration-500 flex items-center gap-6 justify-end bg-gray-50">
            <p className="text-sm">{`Showing ${currentPage} of ${pageCount} page${pageCount > 1 ? 's' : ''}`}</p>

            <div className="flex items-center gap-3 lg:gap-2">
                <IconButton
                    onClick={onPrevious}
                    size={"sm"}
                    isDisabled={currentPage === 1}
                    variant="outline"
                    icon={<FiArrowLeft fontSize="1rem" />}
                    aria-label="Previous"
                />
                <IconButton
                    size={"sm"}
                    onClick={onNext}
                    isDisabled={currentPage >= pageCount}
                    variant="outline"
                    icon={<FiArrowRight fontSize="1rem" />}
                    aria-label="Next"
                />
            </div>
        </div>
    )
}

export default TablePagination;