import { Box, FormControl, FormErrorMessage, FormHelperText, FormLabel } from "@chakra-ui/react"
import { Select } from "chakra-react-select"
import _ from "lodash"
import { useEffect } from "react"
import "./form-controller-styles.css"
import React from "react"

interface IFormSelect {
    classNames?: string,
    formik: any,
    name: string,
    label?: string,
    isRequired?: boolean,
    isDisabled?: boolean,
    isSearchable?: boolean,
    isLoading?: boolean
    metaDispatcher?: any,
    isMulti?: boolean,
    isClearable?: boolean,
    components?: any,
    placeholder?: string | number | undefined
    options?: FormSelectOptions,
    size?: "sm" | "md" | "lg",
    method?: string,
    returnArray?: boolean,
    showLoading?: boolean,
    helperText?: string,
    w?: string,
    onInputChange?: (value: string) => void
}

type option = { label: string | number, value: string | number }
export type FormSelectOptions = Array<option>

const FormLiveSelect = ({ isClearable = true, size = "sm", showLoading = true, returnArray = false, placeholder = '', components, isMulti = false, metaDispatcher = () => { }, isSearchable = true, formik, name, label, isRequired = false, isDisabled = false, options: _options = [], method, isLoading, options, onInputChange, helperText, w }: IFormSelect) => {

    useEffect(() => {
        const elements: any = document.querySelectorAll(".fcc") || [];

        if (elements?.length > 0) {
            elements.forEach((element: any, index: any) => {
                const tagContainer = element["children"][2];
                const tagContainerParent = tagContainer["children"][0]

                if (tagContainer && tagContainerParent) {
                    tagContainer.classList.add("mfstcc")
                    tagContainerParent.classList.add("mfstccp")
                }
            })
        }

    }, [formik.values[name]])

    const defaultValueSelector = () => {
        let data: any = [];
        let values = _.get(formik, ["values", name])

        try {
            if (isMulti) {
                if (_.isArray(values)) {
                    values.forEach((val: any) => {
                        data.push(_.find(options, { 'value': val }))
                    })
                } else {
                    console.error("Error: Default values for FormSelect should be a type of array")
                }
            } else {
                data = _.find(options, { 'value': values });
            }
        } catch (e: any) {
            console.error(e);
        }

        return data;
    }

    const onChangeHandler = (e: any) => {
        formik.setFieldValue(name, e);
        // debugger
        // console.log(e, '---')
        // if (!_.isEmpty(e)) {

        //     if (isMulti) {
        //         formik.setFieldValue(name, e.map((i: any) => i["value"]));
        //     } else {
        //         formik.setFieldValue(name, returnArray ? [e["value"]] : e["value"]);
        //     }

        //     metaDispatcher(e)
        // } else {
        //     if (isMulti) {
        //         formik.setFieldValue(name, []);
        //     } else {
        //         formik.setFieldValue(name, returnArray ? [] : '');
        //     }
        // }
    }

    const _onInputChange = (value: string) => {
        onInputChange && onInputChange(value)
    }

    return (
        <FormControl isRequired={isRequired} isInvalid={Boolean(formik.submitCount && formik.errors[name])}>
            {label && <FormLabel>{label}</FormLabel>}
            <Box w={w}>
                <Select
                    className="border-gray-300 shadow-sm fcc"
                    onChange={onChangeHandler}
                    isLoading={isLoading}
                    isDisabled={isDisabled}
                    placeholder={placeholder}
                    isClearable={isClearable}
                    isSearchable={isSearchable}
                    name={name}
                    size={size}
                    selectedOptionColorScheme="teal"
                    isMulti={isMulti}
                    tagVariant="solid"
                    colorScheme={"teal"}
                    components={components ? components : undefined}
                    options={options}
                    onInputChange={_onInputChange}
                    value={_.isEmpty(formik.values[name]) ? [] : formik.values[name]}
                />
            </Box>
            {helperText && <FormHelperText>{helperText}</FormHelperText>}
            {(formik.submitCount && formik.errors[name]) ? <FormErrorMessage mt={2}>{formik.errors[name]}</FormErrorMessage> : null}
        </FormControl>
    )
}

export default React.memo(FormLiveSelect);