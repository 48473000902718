import { Badge, Center, HStack, Link, Menu, MenuButton, MenuItem, MenuList, Td, Text, Tr } from "@chakra-ui/react";
import { FaEllipsisH } from "react-icons/fa";
import _ from "lodash";

import "../chakra-table.css"

const TrComponent = ({ item, columns, onSelected, disablePointer }: any) => {

    function selectedRow(row: any) {
        onSelected(row)
    }

    const tdResolver = (type: any = '', className: any = '', value: any = '', conditionClassName: (param: any) => {}) => {
        const classNames = `${conditionClassName(item)} ${className}`
        if (type == 'link') {
            return (
                <Link className={classNames} href={value} isExternal>
                    {value}
                </Link>
            )
        }

        return (
            <Text className={classNames}>{value}</Text>
        )
    }

    const onClickHandler = (col: any) => {
        if (!col?.options && col?.type != 'link') {
            selectedRow(item)
            return;
        }
    }

    return (
        <Tr _hover={{ bg: disablePointer ? '#fff' : "#EBEBEB" }} className="!w-fit" cursor={disablePointer ? '' : 'pointer'}>
            {columns.map((col: any, index: any) => (
                <Td className={`${col?.customRenderer ? '' : null}`} onClick={() => onClickHandler(col)} key={index}>
                    {col?.customRenderer ? col.customRenderer(item[columns[index].accessor], item) : (
                        <>
                            {!_.isEmpty(_.get(col, 'conditions', false)) ? <Badge
                                variant={"solid"}
                                className="!capitalize !rounded-[3px] !pb-0.5"
                                fontWeight={"semibold"}
                                colorScheme={col.conditions[item[columns[index].accessor]]}>{item[columns[index].accessor]}</Badge> :
                                tdResolver(columns[index]?.type, columns[index]?.className ? columns[index]?.className : undefined, item[columns[index]?.accessor], columns[index]?.conditionClassName ? columns[index]?.conditionClassName : (param: any) => { })
                            }

                            {(col.options && !col?.disabled) ? <Center>
                                <HStack spacing={5}>
                                    <Menu>
                                        <MenuButton>
                                            <FaEllipsisH />
                                        </MenuButton>
                                        <MenuList>
                                            {col.options.map((actionItem: any, index: any) => {

                                                if (actionItem?.conditionalDisable == null || actionItem.conditionalDisable(item)) {
                                                    return (
                                                        <MenuItem onClick={() => {
                                                            actionItem.onClick(item)
                                                        }} key={index}>{actionItem.label}
                                                        </MenuItem>
                                                    )
                                                }
                                            })}
                                        </MenuList>
                                    </Menu>
                                </HStack>
                            </Center> : null}
                        </>
                    )}
                </Td>
            )
            )}
        </Tr>
    )
}
export default TrComponent;