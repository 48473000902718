import {Box, Heading} from "@chakra-ui/react"
import ChakraTable, {ChakraTableColumns} from "../../../common/chakra-table/ChakraTable"
import {REPORTS} from "../../../../consts/methods.consts"
import {ACTIVITY_TAGS} from "../../../../consts/tags.consts"
import {useGetQuery} from "../../../../services/api.service"

const LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        accessor: "resource_id",
        header: "Resource ID"
    },
    {
        accessor: "title",
        header: "Resource Name"
    },
    {
        accessor: "sign_out_time",
        header: "Out Date/time"
    },
    {
        accessor: "due_date",
        header: "Due date"
    }
]

const SignedOutResources = () => {
    const { data, isLoading } = useGetQuery({
        method: REPORTS.SIGN_OUT_RESOURCES,
        body: { id: 10 },
        providesTags: [ACTIVITY_TAGS.LIST]
    })

    return (
        <div className="border mt-4 pt-4 col-span-1 rounded-md shadow-sm relative">
            <Box px={4} pb={3}>
                <Heading fontSize='xl'>Signed Out Resources</Heading>
            </Box>
            <ChakraTable height={350} disableBorder columns={LIST_TAB_COLUMNS} data={data?.message ?? []} />
        </div>
    )
}

export default SignedOutResources