import { useDisclosure } from "@chakra-ui/react";
import { useState } from "react";
import { EXPORT_DATA } from "../../../../consts/methods.consts";
import UpdateComplianceModal from "./UpdateComplianceModal";
import ExportModal from "../../../common/export-modal/ExportModal";
import WwccDataTable from "./WwccDataTable";


const WWCCReportTab = () => {
    const _disclosure = useDisclosure();
    const _disclosureExportData = useDisclosure();
    const [selectedItem, setSelectedItem] = useState({})

    return (
        <>
            <WwccDataTable setSelectedItem={setSelectedItem} disclosure={_disclosure}
                disclosureExportData={_disclosureExportData} />
            <UpdateComplianceModal disclosure={_disclosure} data={selectedItem} />
            <ExportModal method={EXPORT_DATA.WWCC_LIST} disclosure={_disclosureExportData} />
        </>

    )
}

export default WWCCReportTab