import { useEffect, useState } from "react"
import { ChakraTable, SectionCard } from "../../../.."
import { SectionCardCreatable } from "../../../../common/section-card/SectionCard"
import { useDisclosure } from "@chakra-ui/react"
import { ChakraTableColumns } from "../../../../common/chakra-table/ChakraTable"
import StudentSelector from "../../../../common/student-selector/StudentSelector"

interface ISelectStudent {
    onSelect: (items: any[]) => void
}


const SelectStudent = ({ onSelect }: ISelectStudent) => {
    const disclosure = useDisclosure()
    const [isError, setError] = useState(false)
    const [children, setChildren] = useState<any>([])

    const onChildrenTableClickHandler = (row: any) => {
        window.open(`${window.location.origin}/app/students/${row.id}`)
    }

    useEffect(() => {
        onSelect(children)
    }, [children])

    const STUDENT_LIST_TAB_COLUMNS: ChakraTableColumns = [
        {
            header: "Student Id",
            accessor: "student_id",
        },
        {
            header: "First Name",
            accessor: "first_name",
        },
        {
            header: "Last Name",
            accessor: "last_name",
        },
        {
            header: "Gender",
            accessor: "gender",
        },
        {
            header: "Class",
            accessor: "class",
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            options: [
                {
                    label: "Remove",
                    onClick(item: any) {
                        const filteredData = children.filter((i: any) => i.id != item.id);
                        setChildren(filteredData);
                    },
                }
            ]
        }
    ]

    const creatable: SectionCardCreatable = {
        text: "Browse",
        onClick: () => {
            disclosure.onOpen();
        }
    }

    return (
        <div>
            <StudentSelector selectedItems={children} setSelectedItems={setChildren} {...disclosure} />
            <SectionCard isError={isError} errorMessage="Please add a child" creatable={creatable} subTitle="Select children related to the activity" title="Children" p={3}>
                <ChakraTable pageCount={10} onSelected={onChildrenTableClickHandler} isLoading={false} size={"sm"} title="Children" columns={STUDENT_LIST_TAB_COLUMNS} data={children || []} />
            </SectionCard>
        </div>
    )
}

export default SelectStudent