import ChakraTable, {ChakraTableColumns} from "../../../common/chakra-table/ChakraTable";
import _ from "lodash";
import {useGetQuery} from "../../../../services/api.service";
import {ACTIVITY} from "../../../../consts/methods.consts";
import {ACTIVITY_TAGS} from "../../../../consts/tags.consts";
import {useTableDataProvider} from "../../../hooks";
import {NavigateFunction, useNavigate} from "react-router";
import React, {useEffect, useState} from "react";
import {useFormik} from "formik";
import {FormDropdown, FormDropdownOptions} from "../../../common/form-controllers/FormDropdown";
import {FormInput} from "../../../common/form-controllers/FormInput";
import {useToast} from "@chakra-ui/react";
import {ToastService} from "../../../../services/toast.service";
import {IOpenPdf, openPdf} from "../../../../utils/utils";

const ActivityListDataTable = () => {
    const navigate: NavigateFunction = useNavigate()
    const [filters, setFilters] = useState({})
    const toast = new ToastService(useToast());
    const [length, setLength] = useState(15)
    const [page, setPage] = useState(1)

    const {data, isLoading, isFetching} = useGetQuery({
        method: ACTIVITY.EVENT_LIST,
        body: {
            page,
            length: Number(length),
            ...filters
        },
        providesTags: [ACTIVITY_TAGS.LIST]
    })

    const {dataList, pageCount} = useTableDataProvider(data);
    const tableLineClickHandler = (line: any) => {
        navigate(`${line.name}`)
    }

    const formik = useFormik({
        initialValues: {},
        onSubmit: async (values: any) => {
        }
    })

    useEffect(() => {
        onFilterHandler(formik.values)
    }, [JSON.stringify(formik.values)])

    const onSearchHandler = _.debounce((q: string) => {
        formik.setFieldValue("q", q)
    }, 200)

    const onFilterHandler = _.debounce((value: any) => {
        setFilters({...filters, ...value})
    }, 200)

    const customFiltersMarkup = (
        <div className="flex gap-2 items-center">
            <FormInput onFocus={(e) => (e.currentTarget.type = "date")} onBlur={(e) => (e.currentTarget.type = "text")}
                       size={"sm"} type="text" isRequired formik={formik} name="from" placeholder="From"/>
            <FormInput onFocus={(e) => (e.currentTarget.type = "date")} onBlur={(e) => (e.currentTarget.type = "text")}
                       size={"sm"} type="text" isRequired formik={formik} name="to" placeholder="To"/>
            <FormDropdown size={"sm"} options={userTypeOptions} isRequired formik={formik} name="type"
                          placeholder="Type"/>
        </div>
    )

    const onPrint = async (columns: any, data: any) => {
        const config: IOpenPdf = {
            tableData: {
                columns,
                data,
                filters,
                info: {
                    title: "Student List"
                }
            },
            toast
        }

        openPdf(config);
    }

    return (
        <>
            <ChakraTable onPrint={onPrint} onSearch={onSearchHandler} pageCount={pageCount} isLoading={isLoading}
                         isFetching={isFetching} onPage={setPage} onLength={setLength} pagination lengthPaginations
                         size={"sm"} customFilterRenderer={customFiltersMarkup}
                         header title="Event List" onSelected={tableLineClickHandler}
                         columns={ACTIVITY_LIST_TAB_COLUMNS} data={dataList}/>
        </>
    )
}

const userTypeOptions: FormDropdownOptions = [
    {label: "Therapy", value: "Therapy"},
    {label: "Contractor", value: "Contractor"}
]
export const ACTIVITY_LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        header: "Title",
        accessor: "title"
    },
    {
        header: "Description",
        accessor: "description",
        className: "w-[300px] truncate"
    },
    {
        header: "Type",
        accessor: "subtype",
        conditions: {
            'Contractor': 'cyan',
            'Therapy': 'blue'
        }
    },
    {
        header: "Start Time",
        accessor: "start_time"
    },
    {
        header: "End Time",
        accessor: "end_time",
    }
]
export default ActivityListDataTable