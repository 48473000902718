import {useDisclosure, useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import _ from "lodash";
import {useEffect, useState} from "react";
import {NavigateFunction, useNavigate} from "react-router-dom";
import * as Yup from "yup";
import {GROUP} from "../../../consts/methods.consts";
import {GROUP_TAGS} from "../../../consts/tags.consts";
import {usePostMutation} from "../../../services/api.service";
import {ToastService} from "../../../services/toast.service";
import ChakraTable, {ChakraTableColumns} from "../../common/chakra-table/ChakraTable";
import PageHeader, {PageHeaderActions} from "../../common/page-header/PageHeader";
import {SectionCard, SectionCardCreatable} from "../../common/section-card/SectionCard";
import {frappeExceptionFormatter} from "../../../utils/utils";
import {InnerLoading} from "../../index";
import UserSelector from "../../common/user-selector/UserSelector";
import {SimpleGrid} from "@chakra-ui/layout";
import {FormInput} from "../../common/form-controllers/FormInput";
import {FormCheckBox} from "../../common/form-controllers/FormCheckBox";

const NewGroupPage = () => {
    const navigate: NavigateFunction = useNavigate();
    const [create, {isLoading}] = usePostMutation();
    const toast = new ToastService(useToast());
    const memberDisclosure = useDisclosure()

    const [members, setMembers] = useState<any>([])
    const [isError, SetIsError] = useState<boolean>(false)

    const formik = useFormik({
        initialValues: {
            active: true
        },
        validationSchema: Yup.object({
            title: Yup.string().required("Group title required")
        }),
        onSubmit: async (values: any) => {
            try {
                SetIsError(false);

                if (_.isEmpty(members)) {
                    SetIsError(true);
                    throw new Error("Please add members");
                }

                const res = await create({
                    method: GROUP.ADD,
                    body: {
                        ...values,
                        members
                    },
                    invalidatesTags: [GROUP_TAGS.LIST]
                }).unwrap();

                const newId = _.get(res, ['message'])

                if (!newId) {
                    throw new Error("Unable to add group")
                }

                toast.setTitle("Success").setDescription("Group added to the system").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/group/${newId}`, {replace: true});
                }, 750)

            } catch (error: any) {
                toast.setTitle("Error").setDescription(frappeExceptionFormatter(error || {})).showErrorToast();
                console.error(error);
            }
        }
    })

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isLoading
        },
    ]

    const creatable: SectionCardCreatable = {
        text: "Browse",
        onClick: () => {
            memberDisclosure.onOpen();
        }
    }

    const MEMBER_COLUMNS: ChakraTableColumns = [
        {
            header: "User Id",
            accessor: "__id",
        },
        {
            header: "First Name",
            accessor: "first_name",
        },
        {
            header: "Last Name",
            accessor: "last_name",
        },
        {
            header: "Gender",
            accessor: "gender",
        },
        {
            header: "User Type",
            accessor: "__type",
            conditions: {
                'Non Academic Staff': 'purple',
                'Academic Staff': 'messenger',
                'Student': 'pink',
                'Guest': 'red',
                'Guardian': 'whatsapp',
                'Contractor': 'yellow',
                'Plumber': 'linkedin',
                'Therapist': 'cyan',
                'Volunteer': 'blue',
                'Tour': 'green',
                'Support Workers': 'facebook',
                'Bank': 'orange',
                'Analyst': 'facebook',
            }
        },
        {
            header: "Actions",
            accessor: '',
            disabled: false,
            options: [
                {
                    label: "Remove",
                    onClick(item: any) {
                        const filteredData = members.filter((i: any) => i.id != item.id);
                        setMembers(filteredData);
                    },
                }
            ]
        }
    ]

    const onChildrenTableClickHandler = (row: any) => {
        let path = ''

        if (row.__type === 'Contractor') path = 'contractors'
        if (row.__type === 'Plumber') path = 'contractors'
        if (row.__type === 'Mechanical') path = 'contractors'
        if (row.__type === 'Therapist') path = 'Therapists'
        if (row.__type === 'Guardian') path = 'guardians'
        if (row.__type === 'Analyst') path = 'guests'
        if (row.__type === 'Volunteer') path = 'guests'
        if (row.__type === 'Tour') path = 'guests'
        if (row.__type === 'Bank') path = 'guests'
        if (row.__type === 'Support Workers') path = 'guests'
        if (row.__type === 'Gardeners') path = 'guests'
        if (row.__type === 'Non Academic Staff') path = 'staff'
        if (row.__type === 'Academic Staff') path = 'staff'
        if (row.__type === 'Student') path = 'students'
        window.open(`${window.location.origin}/app/${path}/${row.id}`)
    }

    useEffect(() => {
        if (!_.isEmpty(members)) {
            SetIsError(false)
        }
    }, [JSON.stringify(members)])

    return (
        <>
            <PageHeader isLoading={isLoading} enableBackButton actions={actions} title="Create New Group"
                        subtitle="Group"/>
            {
                isLoading ? <InnerLoading/> :
                    <>
                        <UserSelector selectedItems={members} setSelectedItems={setMembers} {...memberDisclosure} />
                        <div className="border rounded p-3">
                            <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 4}} spacing={3}>
                                <FormInput autoComplete="off" isRequired label="Group Name" formik={formik}
                                           name="title"/>
                                <FormCheckBox label="Active" formik={formik} name="active"/>
                            </SimpleGrid>
                        </div>

                        <SectionCard isError={isError} errorMessage="Please add group members" creatable={creatable}
                                     subTitle="Select group members related to the group" title="Members" p={3}>
                            <ChakraTable onSelected={onChildrenTableClickHandler} isLoading={false} size={"sm"}
                                         title="Members" columns={MEMBER_COLUMNS} data={members || []}/>
                        </SectionCard>
                    </>
            }
        </>
    )
}

export default NewGroupPage