import {SimpleGrid} from "@chakra-ui/layout";
import {useFormik} from "formik";
import * as Yup from "yup";
import {InnerLoading, PageHeader} from "../..";
import {FormDropdown, FormDropdownOptions} from "../../common/form-controllers/FormDropdown";
import {FormInput} from "../../common/form-controllers/FormInput";
import {PageHeaderActions} from "../../common/page-header/PageHeader";
import {useGetQuery, usePostMutation} from "../../../services/api.service";
import {ToastService} from "../../../services/toast.service";
import {useToast} from "@chakra-ui/react";
import _ from "lodash";
import {NavigateFunction, useNavigate} from "react-router-dom";
import {STAFF, VALIDATION} from "../../../consts/methods.consts";
import {FILTER_TAGS, STAFF_TAGS} from "../../../consts/tags.consts";
import {useEffect, useState} from "react";
import {PHONE_REG_EXP} from "../../../consts/app.consts";

const STAFF_TYPE: FormDropdownOptions = [
    {
        label: "Staff",
        value: "ACADEMIC"
    },
    {
        label: "Education Support",
        value: "NON_ACADEMIC"
    }
]

const status: FormDropdownOptions = [
    {label: "Active", value: "Active"},
    {label: "Inactive", value: "Inactive"},
    {label: "Left", value: "Left"}
]

const titles: FormDropdownOptions = [
    {label: "Mr", value: "Mr"},
    {label: "Ms", value: "Ms"},
    {label: "Miss", value: "Miss"},
    {label: "Mrs", value: "Mrs"},
    {label: "Mx", value: "Mx"}
]

const NewStaffPage = () => {
    const navigate: NavigateFunction = useNavigate();
    const [create, {isLoading}] = usePostMutation();
    const toast = new ToastService(useToast());
    const [staffIdQ, setStaffIdQ] = useState(null)
    const [staffEmailQ, setStaffEmailQ] = useState(null)
    const [staffMobileQ, setStaffMobileQ] = useState(null)

    const {data: _validationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_STAFF_ID_CREATE,
        body: {
            staff_id: staffIdQ
        },
        providesTags: [STAFF_TAGS.VIEW]
    })

    const {data: _emailValidationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_STAFF_EMAIL_CREATE,
        body: {
            email: staffEmailQ
        },
        providesTags: [STAFF_TAGS.VIEW]
    })

    const {data: _mobileValidationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_STAFF_MOBILE_CREATE,
        body: {
            mobile: staffMobileQ
        },
        providesTags: [STAFF_TAGS.VIEW]
    })

    const formik = useFormik({
        initialValues: {
            status: "Active"
        },
        validationSchema: Yup.object({
            staff_id: Yup.string().required("Staff ID is required"),
            title: Yup.string().required("Title is required"),
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            type: Yup.string().required("Staff type is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            mobile: Yup.string().matches(PHONE_REG_EXP, 'Mobile number is not valid')
                .required('Mobile number is required'),
            phone: Yup.string().matches(PHONE_REG_EXP, 'Phone number is not valid').nullable()
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: STAFF.ADD,
                    body: values,
                    invalidatesTags: [STAFF_TAGS.LIST, FILTER_TAGS.STAFF_SELECTOR]
                }).unwrap();

                const newId = _.get(res, 'message')

                if (!newId) {
                    throw new Error("Unable to register staff")
                }

                toast.setTitle("Success").setDescription("Staff member registered to the system").showSuccessToast();

                setTimeout(() => {
                    navigate(`/app/staff/${newId}`, {replace: true});
                }, 750)

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to register staff member. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    useEffect(() => {
        staffIdQHandler(_.get(formik.values, "staff_id"))
        staffEmailQHandler(_.get(formik.values, "email"))
        staffMobileQHandler(_.get(formik.values, "mobile"))
    }, [_.get(formik.values, "staff_id"), _.get(formik.values, "email"), _.get(formik.values, "mobile")])

    const staffIdQHandler = _.debounce((value: any) => {
        setStaffIdQ(value)
    }, 200)

    const staffEmailQHandler = _.debounce((value: any) => {
        setStaffEmailQ(value)
    }, 200)

    const staffMobileQHandler = _.debounce((value: any) => {
        setStaffMobileQ(value)
    }, 200)

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isLoading
        },
    ]

    const staffIDCustomError = (_validationData?.message > 0) && (
        <p className=" text-sm text-red-500">Staff ID already taken.</p>
    )

    const staffEmailCustomError = (_emailValidationData?.message > 0) && (
        <p className="mt-1 text-sm text-red-500">Email already taken.</p>
    )

    const staffMobileCustomError = (_mobileValidationData?.message > 0) && (
        <p className="mt-1 text-sm text-red-500">Mobile number already taken.</p>
    )

    return (
        <>
            <PageHeader isLoading={isLoading} enableBackButton actions={actions} title="Create New Staff"
                        subtitle="Staff"/>
            {
                isLoading ? <InnerLoading/> :
                    <div className="border rounded p-3">
                        <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 4}} spacing={3}>
                            <FormInput customMessage={staffIDCustomError} isRequired label="Staff Id"
                                       formik={formik} name="staff_id"/>
                            <FormDropdown options={titles} isRequired label="Title" formik={formik} name="title"/>
                            <FormInput isRequired label="First Name" formik={formik} name="first_name"/>
                            <FormInput label="Middle Name" formik={formik} name="middle_name" />
                            <FormInput isRequired label="Last Name" formik={formik} name="last_name"/>
                            <FormDropdown options={STAFF_TYPE} isRequired label="Type" formik={formik} name="type"/>
                            <FormInput isRequired type="email" customMessage={staffEmailCustomError} label="Email"
                                       formik={formik} name="email"/>
                            <FormInput placeholder="0XXXXXXXXX" isRequired type="tel" label="Mobile"
                                       customMessage={staffMobileCustomError} formik={formik}
                                       name="mobile"/>
                            <FormInput placeholder="0XXXXXXXXX" type="tel" label="Phone" formik={formik}
                                       name="phone"/>
                            <FormDropdown options={status} label="Status" formik={formik} name="status"/>
                        </SimpleGrid>
                    </div>
            }
        </>
    )
}

export default NewStaffPage