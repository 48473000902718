import _ from "lodash"
import { ACTIVITY } from "../../../../consts/methods.consts"
import { ACTIVITY_TAGS } from "../../../../consts/tags.consts"
import { useGetQuery } from "../../../../services/api.service"
import ChakraTable, { ChakraTableColumns } from "../../../common/chakra-table/ChakraTable"

interface IParentConsentListTab {
    _id: string | undefined
}


const LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        accessor: "std_name",
        header: "Student Name"
    },
    {
        accessor: "consent",
        header: "Consent",
        conditions: {
            'Accepted': 'green',
            'Declined': 'red'
        }
    },
    {
        accessor: "parent_name",
        header: "Guardian Name"
    }
]

const ParentConsentListTab = ({ _id }: IParentConsentListTab) => {

    const { data, isLoading } = useGetQuery({
        method: ACTIVITY.PARENT_CONSENT_LIST,
        body: { _id },
        providesTags: [ACTIVITY_TAGS.LIST]
    })

    return (
        <>
            <ChakraTable
                size={"sm"}
                header title="Parent Consents"
                isLoading={isLoading}
                columns={LIST_TAB_COLUMNS}
                data={_.get(data, ['message']) ?? []} />
        </>
    )
}

export default ParentConsentListTab