import * as Yup from "yup";
import {useState} from 'react';
import {FormInput} from '../../../common/form-controllers/FormInput';
import {useFormik} from 'formik';
import {ToastService} from '../../../../services/toast.service';
import {useLoginMutation} from '../../../../services/api.service';
import {NavigateFunction, useNavigate} from 'react-router-dom';
import {Box, Button, Icon, StackProps, Text, useDisclosure, useToast} from '@chakra-ui/react';
import {BsEye, BsEyeSlash} from 'react-icons/bs'
import {BiLogIn} from 'react-icons/bi'
import SelectSchool from "./SelectSchool";
import {ForgotPassword} from "./ForgotPassword";

export const SignInForm = (props: StackProps) => {
    const navigator: NavigateFunction = useNavigate()
    const toast = new ToastService(useToast())

    const [isLoading, setIsLoading] = useState<boolean>(false)
    const [login] = useLoginMutation();
    const [multipleSchools, setMultipleSchool] = useState(false)
    const [show, setShow] = useState(false)
    const isResetDisclosure = useDisclosure()
    const handleClick = () => setShow(!show)

    const formik = useFormik({
        initialValues: {
            usr: "",
            pwd: "",
            device: "mobile"
        },
        validationSchema: Yup.object({
            usr: Yup.string().required("Email is required"),
            pwd: Yup.string().required("Password is required")
        }),
        onSubmit: async (values: any) => {
            try {
                setIsLoading(true);
                let data = await login(values).unwrap();
                if (typeof data == 'object' && data.hasOwnProperty("multiple_school")) {
                    setMultipleSchool(data["multiple_school"])
                } else {
                    navigator("/app");
                }

            } catch (error) {
                toast.setTitle("Error").setDescription("Invalid credentials. Try again").showErrorToast()
                console.error(error)
            } finally {
                setIsLoading(false)
            }
        }
    })

    if (multipleSchools) {
        return <SelectSchool/>
    }

    return (
        <Box h={'100vh'} className='flex flex-col justify-center items-center mx-8' {...props}>
            <div className="flex flex-col gap-6 pb-6">
                <div className="flex flex-col">
                    <Text className="text-4xl font-bold text-[#084880]">Welcome to</Text>
                    <Text className="text-6xl font-extrabold text-[#084880]">
                        edPass
                    </Text>
                </div>
            </div>
            {
                isResetDisclosure.isOpen ?
                    <ForgotPassword isResetDisclosure={isResetDisclosure}/> :
                    <div
                        className="animation-form-l3 flex flex-col bg-[#084880] p-8 gap-6 rounded-[2.5rem] mt-8 w-[300px] md:w-[350px]">
                        <div className="flex my-2 gap-2">
                            <Text className="text-3xl text-white font-bold">Sign In</Text>
                            <Icon className="text-3xl !text-white font-bold" as={BiLogIn}/>
                        </div>
                        <form onSubmit={formik.handleSubmit}>
                            <div className="flex flex-col gap-2">
                                <div className="flex flex-col gap-4">
                                    <FormInput rounded="3xl" backgroundColor="white" isRequired formik={formik}
                                               name='usr'
                                               placeholder="Email"/>
                                    <div className="relative">
                                        <Icon className="z-50 absolute right-0 bottom-0 mb-2 mr-4 cursor-pointer"
                                              as={show ? BsEyeSlash : BsEye}
                                              onClick={handleClick}/>
                                        <FormInput rounded="3xl" backgroundColor="white"
                                                   type={show ? 'text' : 'password'}
                                                   isRequired formik={formik} name='pwd'
                                                   placeholder='Password'/>

                                    </div>
                                </div>
                                <Button variant='link' size="sm" maxW="32" className="!text-white mb-2"
                                        onClick={isResetDisclosure.onOpen}
                                >
                                    Forgot password?
                                </Button>
                                <div className="flex flex-col gap-4">
                                    <Button borderRadius="3xl" isLoading={isLoading} type={'submit'} size="sm"
                                            className='!bg-gradient-to-r from-[#0F8DFB] via-[#0B98F8] to-[#07A6F5]
                                    !text-white shadow-lg'>Sign
                                        In</Button>
                                </div>
                            </div>
                        </form>
                    </div>
            }
        </Box>
    )
}
