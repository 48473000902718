import {SimpleGrid} from "@chakra-ui/layout";
import {Badge, Heading, Tab, TabList, TabPanel, TabPanels, Tabs, Text, useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import _ from "lodash";
import {useEffect, useState} from "react";
import {useParams} from "react-router-dom";
import * as Yup from "yup";
import {InnerLoading} from "../../..";
import {GUEST, SCHOOL, VALIDATION} from "../../../../consts/methods.consts";
import {FILTER_TAGS, GUEST_TAGS} from "../../../../consts/tags.consts";
import {useGetQuery, usePostMutation} from "../../../../services/api.service";
import {ToastService} from "../../../../services/toast.service";
import {FormDropdown, FormDropdownOptions} from "../../../common/form-controllers/FormDropdown";
import {FormInput} from "../../../common/form-controllers/FormInput";
import FormSelect from "../../../common/form-controllers/FormSelect";
import PageHeader, {PageHeaderActions} from "../../../common/page-header/PageHeader";
import {useObjChange, useStatusProvider} from "../../../hooks";
import DisclaimerListWithConcent from "../components/DisclaimerListWithConcent";
import {FormCheckBox} from "../../../common/form-controllers/FormCheckBox";
import ChakraTable, {ChakraTableColumns} from "../../../common/chakra-table/ChakraTable";
import ActivityTab from "../../../common/activity-tab/ActivityTab";
import {PHONE_REG_EXP} from "../../../../consts/app.consts";

const gender: FormDropdownOptions = [
    {label: "Male", value: "Male"},
    {label: "Female", value: "Female"}
]

const titles: FormDropdownOptions = [
    {label: "Mr", value: "Mr"},
    {label: "Ms", value: "Ms"},
    {label: "Miss", value: "Miss"},
    {label: "Mrs", value: "Mrs"},
    {label: "Mx", value: "Mx"}
]

export const PARTICIPANTS_COLUMNS: ChakraTableColumns = [
    {
        header: "First Name",
        accessor: "first_name"
    },
    {
        header: "Middle Name",
        accessor: "middle_name",
    },
    {
        header: "Last Name",
        accessor: "last_name",
    },
    {
        header: "Type",
        accessor: "type",
    }
]

const ViewGuestPage = () => {
    const toast = new ToastService(useToast());
    const {id} = useParams()
    const [create, {isLoading: isMutating}] = usePostMutation();
    const [guestEmailQ, setGuestEmailQ] = useState(null)
    const [guestMobileQ, setGuestMobileQ] = useState(null)

    const {data: _emailValidationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_GUEST_EMAIL_UPDATE,
        body: {
            _id: id,
            email: guestEmailQ
        },
        providesTags: [GUEST_TAGS.VIEW]
    })

    const {data: _mobileValidationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_GUEST_MOBILE_UPDATE,
        body: {
            _id: id,
            mobile: guestMobileQ
        },
        providesTags: [GUEST_TAGS.VIEW]
    })

    const {data: _data, isLoading, isFetching} = useGetQuery({
        method: GUEST.GET,
        body: {id},
        providesTags: [GUEST_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {});
    const therapistName: string = `${data?.first_name || ''} ${data?.last_name || ''}`
    const visitorType: string = data?.type
    const dataList = _.get(data, "participants", [])

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            title: Yup.string().required("Title is required"),
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            gender: Yup.string().required("Gender is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            mobile: Yup.string().matches(PHONE_REG_EXP, 'Mobile number is not valid')
                .required('Mobile number is required')
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: GUEST.UPDATE,
                    body: values,
                    invalidatesTags: [GUEST_TAGS.VIEW, GUEST_TAGS.LIST, FILTER_TAGS.EXTERNAL_USER_SELECTOR]
                }).unwrap();

                const newId = _.get(res, 'message')
                if (!newId) {
                    throw new Error("Unable to update Guest")
                }

                toast.setTitle("Success").setDescription("Guest updated").showSuccessToast();
            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to update Guest. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const {isChanged, setInitialObj} = useObjChange(formik.values)
    const statusConfig = useStatusProvider(data);

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [JSON.stringify(data)])

    useEffect(() => {
        staffEmailQHandler(_.get(formik.values, "email"))
        staffMobileQHandler(_.get(formik.values, "mobile"))
    }, [_.get(formik.values, "email"), _.get(formik.values, "mobile")])

    const staffEmailQHandler = _.debounce((value: any) => {
        setGuestEmailQ(value)
    }, 200)

    const staffMobileQHandler = _.debounce((value: any) => {
        setGuestMobileQ(value)
    }, 200)

    const guestEmailCustomError = (_emailValidationData?.message > 0) && (
        <p className="mt-1 text-sm text-red-500">Email already taken.</p>
    )

    const guestMobileCustomError = (_mobileValidationData?.message > 0) && (
        <p className="mt-1 text-sm text-red-500">Mobile number already taken.</p>
    )

    const actions: PageHeaderActions = [
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isMutating
        },
    ]

    if (isLoading || isFetching) {
        return (
            <>
                <PageHeader isLoading={true} enableBackButton actions={actions} title={therapistName}
                            subtitle={"Guest"}/>
                <InnerLoading/>
            </>
        )
    }

    const typeGenerator = () => {
        const type = _.get(formik, ['values', 'type'], '')

        if (type === 'Volunteer') {
            return ['Guest', 'Volunteer']
        }

        if (type === 'Tour') {
            return ['Guest', 'Tour']
        }

        if (type === 'Bank') {
            return ['Guest', 'Bank']
        }

        if (type === 'Analyst') {
            return ['Guest', 'Analyst']
        }

        if (type === 'Support Workers') {
            return ['Guest', 'Support Workers']
        }

        if (type === 'Gardeners') {
            return ['Guest', 'Gardeners']
        }

        return ['Guest', 'Volunteer', 'Tour', 'Bank', 'Analyst', 'Support Workers', 'Gardeners']
    }

    const headerTitleMarkup = (
        <div className="flex flex-col mt-[4px]">
            <div className="flex gap-2">
                <Heading size={"md"} fontWeight="semibold">{therapistName}</Heading>
                <Badge className="mb-[4px] mt-[2px]" variant='solid' colorScheme="yellow">{visitorType}</Badge>
            </div>
            <Text>Guest</Text>
        </div>
    )

    return (
        <>
            <PageHeader {...statusConfig} titleRenderer={headerTitleMarkup} isLoading={isLoading} enableBackButton
                        actions={actions}/>

            <Tabs>
                <TabList>
                    <Tab>General Information</Tab>
                    <Tab>Activities</Tab>
                </TabList>

                <TabPanels>
                    <TabPanel className="!p-0 !pt-3">
                        <div className="animation-form border rounded p-3">
                            <SimpleGrid columns={{base: 1, md: 2, lg: 3, xl: 4}} spacing={3}>
                                <FormDropdown options={titles} isRequired label="Title" formik={formik} name="title"/>
                                <FormInput autoComplete="off" isRequired label="First Name" formik={formik}
                                           name="first_name"/>
                                <FormInput label="Middle Name" formik={formik} name="middle_name" />
                                <FormInput autoComplete="off" isRequired label="Last Name" formik={formik}
                                           name="last_name"/>
                                <FormSelect method={SCHOOL.GET_GUEST_TYPES} label="Guest Type" formik={formik}
                                            name="type"/>
                                <FormDropdown options={gender} isRequired label="Gender" formik={formik} name="gender"/>
                                <FormInput isRequired type="email" customMessage={guestEmailCustomError} label="Email"
                                           formik={formik} name="email"/>
                                <FormInput placeholder="0XXXXXXXXX" customMessage={guestMobileCustomError} isRequired
                                           type="tel" label="Mobile" formik={formik} name="mobile"/>
                                <FormCheckBox label="Active" formik={formik} name="active"/>
                            </SimpleGrid>
                        </div>

                        <div className="mt-3">
                            <ChakraTable header size="sm" title="Participants" columns={PARTICIPANTS_COLUMNS}
                                         data={dataList}/>
                        </div>

                        <DisclaimerListWithConcent doc_name="Guest" type={typeGenerator()}/>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ActivityTab id={id} type={"Guest"} customFilter={true}/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}

export default ViewGuestPage