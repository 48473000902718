import { SimpleGrid } from "@chakra-ui/layout";
import { HStack, useToast } from "@chakra-ui/react";
import { useFormik } from "formik";
import _ from "lodash";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { InnerLoading, InstructionBox, PageHeader } from "../..";
import { DISCLAIMER, SCHOOL } from "../../../consts/methods.consts";
import { useGetQuery, usePostMutation } from "../../../services/api.service";
import { ToastService } from "../../../services/toast.service";
import { FormCheckBox } from "../../common/form-controllers/FormCheckBox";
import { FormInput } from "../../common/form-controllers/FormInput";
import FormSelect from "../../common/form-controllers/FormSelect";
import { PageHeaderActions } from "../../common/page-header/PageHeader";
import { DISCLAIMER_TAGS } from "../../../consts/tags.consts";
import { useEffect } from "react";
import { useObjChange, useStatusProvider } from "../../hooks";
import { FormTextArea } from "../../common/form-controllers/FormTextArea";


const ViewDisclaimerPage = () => {
    const toast = new ToastService(useToast());
    const { id } = useParams()
    const [create, { isLoading: isMutating }] = usePostMutation();
    const { data: _data, isLoading, isFetching } = useGetQuery({
        method: DISCLAIMER.GET,
        body: { id },
        providesTags: [DISCLAIMER_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {})
    const disclaimerTitle = _.get(data, "title")

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            title: Yup.string().required("Policy title is required"),
            user_types: Yup.array().min(1).required("User type is required")
        }),
        onSubmit: async (values: any) => {
            try {
                const res = await create({
                    method: DISCLAIMER.UPDATE,
                    body: values,
                    invalidatesTags: [DISCLAIMER_TAGS.VIEW, DISCLAIMER_TAGS.LIST]
                }).unwrap();

                toast.setTitle("Success").setDescription("Policy data updated").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to update Policy data. Please try again").showErrorToast();
                console.error(error);
            }
        }
    })

    const { isChanged, setInitialObj } = useObjChange(formik.values)
    const isEditable = _.get(formik, ['values', 'is_editable'], true)
    const statusConfig = useStatusProvider(data);

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [JSON.stringify(data)])

    const actions: PageHeaderActions = [
        {
            text: "Save",
            onClick: formik.submitForm,
            isDisabled: !isChanged,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isMutating
        },
    ]

    if (isLoading || isFetching) {
        return (
            <>
                <PageHeader isLoading={true} enableBackButton actions={actions} title={disclaimerTitle}
                    subtitle={"Policy"} />
                <InnerLoading />
            </>
        )
    }

    return (
        <>
            <PageHeader {...statusConfig} enableBackButton actions={actions} title={disclaimerTitle} subtitle="Policy" />

            {!isEditable && (
                <InstructionBox title="Message" type="error">
                    <li>Policy editing is currently disabled due to multiple consents.</li>
                    <li>You can activate or deactivate it as needed.</li>
                </InstructionBox>
            )}

            <div className="border rounded p-3 animation-form">
                <SimpleGrid columns={{ base: 1, md: 2, lg: 3, xl: 4 }} spacing={3}>
                    <FormInput isDisabled={!isEditable} isRequired label="Title" formik={formik} name="title" />
                    <FormInput isDisabled={!isEditable} label="Sub Title" formik={formik} name="sub_title" />
                    <FormInput isDisabled={!isEditable} label="Validity" formik={formik} name="validity" />
                    <FormInput isDisabled={!isEditable} label="Link" formik={formik} name="link" />
                    <FormSelect isDisabled={!isEditable} isMulti method={SCHOOL.GET_EXTERNAL_USER_TYPES} isRequired label="User Types" formik={formik} name="user_types" />
                    <HStack alignItems={"end"} gap={3}>
                        <FormCheckBox isDisabled={!isEditable} label="Is Required" formik={formik} name="required_in_sign_in" />
                        <FormCheckBox label="Active" formik={formik} name="active" />
                    </HStack>
                </SimpleGrid>
            </div>

            <div className="animation-form border rounded p-3 mt-3">
                <SimpleGrid columns={{ base: 1, md: 2, lg: 2, xl: 2 }} spacing={3}>
                    <FormTextArea isDisabled={!isEditable} helperText="This will appear on KIOSK" rows={10} label="Description" formik={formik} name="description" />
                </SimpleGrid>
            </div>
        </>
    )
}

export default ViewDisclaimerPage;