import {Badge, Box, Heading, HStack, Icon, Stack, Text, useBreakpointValue, useColorModeValue} from "@chakra-ui/react"
import {FiArrowDownRight, FiArrowUpRight} from "react-icons/fi"
import {useGetQuery} from "../../../services/api.service"
import _ from "lodash"

interface Props {
    label: string
    delta?: {
        value: string
        isUpwardsTrend: boolean
    },
    className?: string
    method: string,
    providesTags: Array<string>
    textStyle?: string
}

export const StatCard = (props: Props) => {
    const {label, delta, providesTags = [], method, ...boxProps} = props

    const {data} = useGetQuery({
        method,
        body: {},
        providesTags
    })

    const value = _.get(data, 'message', 0);

    return (
        <Box
            className="border border-gray-200 bg-gradient-to-bl from-green-200 to-cyan-200"
            px={{base: '4', md: '4'}}
            py={{base: '4', md: '4'}}
            bg="bg-surface"
            borderRadius="lg"
            boxShadow={useColorModeValue('sm', 'sm-dark')}
            {...boxProps}>
            <Stack>
                <HStack justify="space-between">
                    <Text fontSize="md" fontWeight={"semibold"} color="muted">
                        {label}
                    </Text>
                </HStack>
                <HStack justify="space-between">
                    <Heading className={props.textStyle}
                             size={useBreakpointValue({base: 'sm', md: 'lg'})}>{value}</Heading>
                    {delta && <Badge variant="subtle" colorScheme={delta.isUpwardsTrend ? 'green' : 'red'}>
                        <HStack spacing="1">
                            <Icon as={delta.isUpwardsTrend ? FiArrowUpRight : FiArrowDownRight}/>
                            <Text>{delta.value}</Text>
                        </HStack>
                    </Badge>}
                </HStack>
            </Stack>
        </Box>
    )
}