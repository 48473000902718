import ChakraTable, {ChakraTableColumns} from "../../../common/chakra-table/ChakraTable";
import React from "react";
import _ from "lodash";
import {useGetQuery} from "../../../../services/api.service";
import {GROUP} from "../../../../consts/methods.consts";
import {Link} from "@chakra-ui/react";

interface IProps {
    id: string
}

const profileTypeMapping: any = {
    "Student": "students",
    "Staff": "staff",
    "Guardian": "guardians",
    "Therapist": "therapists",
    "Contractor": "contractors",
    "Guest": "guests"
}

const LIST_TAB_COLUMNS: ChakraTableColumns = [
    {
        header: "Full Name",
        accessor: "full_name",
        customRenderer: (value, item) => {
            return tblNameCellMarkup(value, item)
        }
    },
    {
        header: "Type",
        accessor: "profile_type",
        conditions: {
            'Staff': 'cyan',
            'Student': 'blue',
            'Guest': 'linkedin',
            'Contractor': 'linkedin',
            'Guardian': 'linkedin',
            'Therapist': 'linkedin'
        }
    },
    {
        header: "Sign In Station",
        accessor: "sign_in_station"
    },
    {
        header: "Sign In Time",
        accessor: "sign_in_time"
    },
    {
        accessor: "sign_in",
        header: "Sign In",
        conditions: {
            'No': 'red',
            'Yes': 'green'
        }
    },
    {
        header: "Sign Out Station",
        accessor: "sign_out_station",
    },
    {
        header: "Sign Out Time",
        accessor: "sign_out_time"
    },
    {
        accessor: "sign_out",
        header: "Sign Out",
        conditions: {
            'No': 'red',
            'Yes': 'green'
        }
    },
    {
        header: "Mobile",
        accessor: "mobile"
    },
    {
        header: "Email",
        accessor: "email"
    }
]

const tblNameCellMarkup = (value: any, item: any) => {
    return (
        <Link color="#0073E5" href={`/app/${profileTypeMapping[item?.profile_type]}/${item?.profile}`} isExternal>
            {value}
        </Link>
    )
}

const GroupActivityHistoryDataTable: React.FC<IProps> = ({id}) => {

    const {data, isFetching} = useGetQuery({
        method: GROUP.GET_ACTIVITY,
        body: {
            _id: id,
        },
        providesTags: []
    })

    const dataList = _.get(data, "message", [])

    return (
        <>
            <div className={"animation-form"}>
                <ChakraTable
                    size={"sm"}
                    header title="History"
                    isLoading={isFetching}
                    columns={LIST_TAB_COLUMNS}
                    data={dataList}/>
            </div>
        </>
    )
}

export default GroupActivityHistoryDataTable