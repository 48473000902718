import * as Yup from "yup";
import _ from "lodash";
import moment from "moment";
import {STAFF, VALIDATION} from "../../../consts/methods.consts";
import {useFormik} from "formik";
import {useEffect, useState} from "react";
import ActivityTab from "../../common/activity-tab/ActivityTab";
import {useParams} from "react-router-dom";
import {FormInput} from "../../common/form-controllers/FormInput";
import {ToastService} from "../../../services/toast.service";
import StationSignInModal from "../../common/station-signin-modal/StationSignInModal";
import StationSignOutModal from "../../common/station-signout-modal/StationSignOutModal";
import {PageHeaderActions} from "../../common/page-header/PageHeader";
import {FILTER_TAGS, STAFF_TAGS} from "../../../consts/tags.consts";
import {useGetQuery, usePostMutation} from "../../../services/api.service";
import {useObjChange} from "../../hooks";
import {FormDropdown, FormDropdownOptions} from "../../common/form-controllers/FormDropdown";
import {InnerLoading, PageHeader, ProfileAvatar} from "../..";
import {Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure, useToast} from "@chakra-ui/react";
import ActivityCalendarTab from "../../common/calendar-tab/ActivityCalendarTab";
import ResourceTab from "../../common/resource-tab/ResourceTab";
import {PHONE_REG_EXP} from "../../../consts/app.consts";

moment.locale("en-GB");

const STAFF_TYPE: FormDropdownOptions = [
    {
        label: "Staff",
        value: "ACADEMIC"
    },
    {
        label: "Education Support",
        value: "NON_ACADEMIC"
    }
]

const statusOptions: FormDropdownOptions = [
    {label: "Active", value: "Active"},
    {label: "Inactive", value: "Inactive"},
    {label: "Left", value: "Left"}
]

const colorSchemaMapping: any = {
    'Inactive': 'red',
    'Active': 'green',
    'Left': "orange"
}

const titles: FormDropdownOptions = [
    {label: "Mr", value: "Mr"},
    {label: "Ms", value: "Ms"},
    {label: "Miss", value: "Miss"},
    {label: "Mrs", value: "Mrs"},
    {label: "Mx", value: "Mx"}
]

const ViewStaffPage = () => {
    const {id} = useParams()
    const [create] = usePostMutation();
    const toast = new ToastService(useToast());
    const [staffIdQ, setStaffIdQ] = useState(null)
    const [qrResendIsLoading, setQrResendIsLoading] = useState<boolean>(false)
    const [isSubmitting, setIsSubmitting] = useState<boolean>(false)
    const signInDisclosure = useDisclosure();
    const signOutDisclosure = useDisclosure();
    const [staffEmailQ, setStaffEmailQ] = useState(null)
    const [staffMobileQ, setStaffMobileQ] = useState(null)

    const {data: _data, isLoading, isFetching} = useGetQuery({
        method: STAFF.GET,
        body: {id},
        providesTags: [STAFF_TAGS.VIEW]
    })

    const {data: _validationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_STAFF_ID_UPDATE,
        body: {
            _id: id,
            staff_id: staffIdQ
        },
        providesTags: [STAFF_TAGS.VIEW]
    })

    const {data: _emailValidationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_STAFF_EMAIL_UPDATE,
        body: {
            email: staffEmailQ
        },
        providesTags: [STAFF_TAGS.VIEW]
    })

    const {data: _mobileValidationData} = useGetQuery({
        method: VALIDATION.DUPLICATE_STAFF_MOBILE_UPDATE,
        body: {
            mobile: staffMobileQ
        },
        providesTags: [STAFF_TAGS.VIEW]
    })

    const data = _.get(_data, ['message'], {})
    const staffId = _.get(data, 'staff_id')
    const staffName = `${_.get(data, "first_name")} ${_.get(data, 'last_name')}`
    const profileId = _.get(data, 'profile_id')
    const status = _.get(data, 'status')

    const formik = useFormik({
        initialValues: {},
        validationSchema: Yup.object({
            staff_id: Yup.string().required("Staff ID is required"),
            title: Yup.string().required("Title is required"),
            first_name: Yup.string().required("First name is required"),
            last_name: Yup.string().required("Last name is required"),
            type: Yup.string().required("Staff type is required"),
            email: Yup.string().email("Invalid email address").required("Email is required"),
            mobile: Yup.string().matches(PHONE_REG_EXP, 'Mobile number is not valid')
                .required('Mobile number is required'),
            phone: Yup.string().matches(PHONE_REG_EXP, 'Phone number is not valid').nullable()
        }),
        onSubmit: async (values: any) => {
            try {
                setIsSubmitting(true)
                const res = await create({
                    method: STAFF.UPDATE,
                    body: values,
                    invalidatesTags: [STAFF_TAGS.VIEW, STAFF_TAGS.LIST, FILTER_TAGS.STAFF_SELECTOR]
                }).unwrap();

                toast.setTitle("Success").setDescription("Staff data updated").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to update Staff data. Please try again").showErrorToast();
                console.error(error);
            } finally {
                setIsSubmitting(false)
            }
        }
    })

    const {isChanged, setInitialObj} = useObjChange(formik.values)

    useEffect(() => {
        if (!_.isEmpty(data)) {
            formik.setValues(data)
            setInitialObj(data)
        }
    }, [JSON.stringify(data)])

    useEffect(() => {
        staffIdQHandler(_.get(formik.values, "staff_id"))
        staffEmailQHandler(_.get(formik.values, "email"))
        staffMobileQHandler(_.get(formik.values, "mobile"))
    }, [_.get(formik.values, "staff_id"), _.get(formik.values, "email"), _.get(formik.values, "mobile")])

    const staffIdQHandler = _.debounce((value: any) => {
        setStaffIdQ(value)
    }, 200)
    const staffEmailQHandler = _.debounce((value: any) => {
        setStaffEmailQ(value)
    }, 200)

    const staffMobileQHandler = _.debounce((value: any) => {
        setStaffMobileQ(value)
    }, 200)

    const resendQRHandler = async () => {
        try {
            setQrResendIsLoading(true)
            await create({
                method: STAFF.RESEND_QR,
                body: {
                    profile: id
                },
                invalidatesTags: []
            }).unwrap();

            toast.setTitle("Success").setDescription("The QR code has been successfully resent.").showSuccessToast();
        } catch (error) {
            toast.setTitle("Error").setDescription("Unable to resend QR. Please try again.").showErrorToast();
        } finally {
            setQrResendIsLoading(false)
        }
    }

    const actions: PageHeaderActions = [
        {
            text: "Resend Access QR",
            onClick: resendQRHandler,
            buttonVariant: "outline",
            buttonColorScheme: "cyan",
            isLoading: qrResendIsLoading
        },
        {
            text: "Sign in",
            onClick: () => signInDisclosure.onOpen(),
            buttonVariant: "outline",
            buttonColorScheme: "red"
        },
        {
            text: "Sign out",
            onClick: () => signOutDisclosure.onOpen(),
            buttonVariant: "outline",
            buttonColorScheme: "whatsapp"
        },
        {
            text: "Save",
            isDisabled: !isChanged,
            onClick: formik.submitForm,
            buttonVariant: "solid",
            buttonColorScheme: "teal",
            isLoading: isSubmitting
        }
    ]

    if (isLoading || isFetching) {
        return (
            <>
                <PageHeader isLoading={true} enableBackButton actions={actions} title={staffName}
                            subtitle={staffId}/>
                <InnerLoading/>
            </>
        )
    }

    const staffIDCustomError = (_validationData?.message > 0) && (
        <p className=" text-sm text-red-500">Staff ID already taken.</p>
    )

    const staffEmailCustomError = (_emailValidationData?.message > 0) && (
        <p className="mt-1 text-sm text-red-500">Email already taken.</p>
    )

    const staffMobileCustomError = (_mobileValidationData?.message > 0) && (
        <p className="mt-1 text-sm text-red-500">Mobile number already taken.</p>
    )

    return (
        <>
            <PageHeader status={status} statusColorSchemes={colorSchemaMapping[status]} isLoading={isLoading}
                        enableBackButton actions={actions} title={staffName}
                        subtitle={staffId}/>
            <StationSignInModal id={id} title={"Sign in staff"} type={"staff"} toastDescription="Sign-in Successful"
                                disclosure={signInDisclosure}/>
            <StationSignOutModal id={id} title={"Sign out staff"} type={"staff"} toastDescription="Sign-out Successful"
                                 disclosure={signOutDisclosure}/>
            <Tabs className="mt-3">
                <TabList>
                    <Tab>Info</Tab>
                    <Tab>Activities</Tab>
                    <Tab>Resources</Tab>
                    <Tab>Calendar</Tab>
                </TabList>

                <TabPanels className="animation-form">
                    <TabPanel className="!p-0 !pt-3">
                        <div className="info-container">
                            <div className="profile-wrapper">
                                <ProfileAvatar placeholder="Staff Name" doctype="Internal Profile" docname={profileId}/>
                            </div>
                            <div className="form-wrapper">
                                <FormInput customMessage={staffIDCustomError} isRequired label="Staff Id"
                                           formik={formik} name="staff_id"/>
                                <FormDropdown options={titles} isRequired label="Title" formik={formik} name="title"/>
                                <FormInput isRequired label="First Name" formik={formik} name="first_name"/>
                                <FormInput label="Middle Name" formik={formik} name="middle_name" />
                                <FormInput isRequired label="Last Name" formik={formik} name="last_name"/>
                                <FormDropdown options={STAFF_TYPE} isRequired label="Type" formik={formik} name="type"/>
                                <FormInput isRequired type="email" customMessage={staffEmailCustomError} label="Email"
                                           formik={formik} name="email"/>
                                <FormInput placeholder="0XXXXXXXXX" isRequired type="tel" label="Mobile" formik={formik}
                                           name="mobile" customMessage={staffMobileCustomError}/>
                                <FormInput type="tel" label="Phone" formik={formik} name="phone"/>
                                <FormDropdown options={statusOptions} label="Status" formik={formik} name="status"/>
                            </div>
                        </div>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ActivityTab id={id} type={"Staff"} customFilter={true}/>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ResourceTab id={id} type={"staff"}/>
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <ActivityCalendarTab id={id} type="Staff"/>
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}
export default ViewStaffPage