import {useToast} from "@chakra-ui/react";
import {useFormik} from "formik";
import moment from "moment";
import * as Yup from "yup";
import {GROUP, SCHOOL} from "../../../../consts/methods.consts";
import {ACTIVITY_TAGS, GROUP_TAGS} from "../../../../consts/tags.consts";
import {usePostMutation} from "../../../../services/api.service";
import {ToastService} from "../../../../services/toast.service";
import FormSelect from "../../../common/form-controllers/FormSelect";
import {FormTextArea} from "../../../common/form-controllers/FormTextArea";
import {ChakraModal} from "../../../index";

interface IGroupSingOutModal {
    id: string | undefined,
    disclosure: any
    description?: string
}

const GroupSingOutModal = ({disclosure, id, description}: IGroupSingOutModal) => {
    const toast = new ToastService(useToast())
    const [create, {isLoading}] = usePostMutation();

    const formik = useFormik({
        initialValues: {
            station: ''
        },
        validationSchema: Yup.object({
            station: Yup.string().required("Station is required"),
            description: Yup.string()
        }),
        onSubmit: async (values) => {
            try {
                await create({
                    method: GROUP.SIGN_OUT,
                    body: {
                        ...values,
                        id,
                        sign_out_time: moment().format("YYYY-MM-DD H:mm"),
                    },
                    invalidatesTags: [ACTIVITY_TAGS.LIST, ACTIVITY_TAGS.VIEW, GROUP_TAGS.VIEW, GROUP_TAGS.LIST]
                }).unwrap();
                formik.resetForm()
                toast.setTitle("Success").setDescription("Sign out from the Station").showSuccessToast();

            } catch (error) {
                toast.setTitle("Error").setDescription("Unable to sign out. Please try again").showErrorToast();
                console.error(error);
            } finally {
                disclosure.onClose();
            }
        }
    })

    return (
        <ChakraModal
            primaryAction={formik.submitForm}
            primaryButtonText={"Sign out"}
            modalTitle="Sign out group"
            {...disclosure}
            isLoading={isLoading}
        >
            <FormSelect
                method={SCHOOL.GET_STATION_LIST_FOR_DROPDOWN}
                isRequired label="Station"
                formik={formik}
                name="station"/>
            <FormTextArea formik={formik} name={"description"} label={"Reason"}/>
        </ChakraModal>
    )
}

export default GroupSingOutModal