import { Tab, TabList, TabPanel, TabPanels, Tabs, useDisclosure } from "@chakra-ui/react"
import ExportModal from "../../common/export-modal/ExportModal"
import PageHeader, { PageHeaderActions } from "../../common/page-header/PageHeader"
import { EXPORT_DATA } from "../../../consts/methods.consts"
import VisitLogDataTable from "./components/VIsitLogDataTable";
import StudentVisitLogDataTable from "./components/StudentVisitLogDataTable";

const VisitLogPage = () => {

    const exportDisclosure = useDisclosure()

    const actions: PageHeaderActions = [
        {
            text: "Export as Excel",
            isDisabled: false,
            onClick: exportDisclosure.onOpen,
            buttonColorScheme: "messenger"
        }
    ]

    return (
        <>
            <PageHeader subtitle="View visit log for the school" title={`Visit Log`}
                actions={actions} />
            <ExportModal method={EXPORT_DATA.VISIT_LOG} disclosure={exportDisclosure} />

            <Tabs className="mt-3">
                <TabList>
                    <Tab>All</Tab>
                    <Tab>Student</Tab>
                </TabList>

                <TabPanels className="animation-form">
                    <TabPanel className="!p-0 !pt-3">
                        <VisitLogDataTable />
                    </TabPanel>
                    <TabPanel className="!p-0 !pt-3">
                        <StudentVisitLogDataTable />
                    </TabPanel>
                </TabPanels>
            </Tabs>
        </>
    )
}

export default VisitLogPage